import { UPDATE_CLIENT_CONTACTS } from '../store/actions';

export const APP_SERVICE = {
  MENU: 'api/user-group/menu-list',
  SELECTED_WORKSHOP: 'api/selected/workshop',
};

export const USER_SERVICE = {
  LOGIN: 'api/user/login',
  GET_USER_LIST: 'api/user/list',
  ADD_NEW_USER: 'api/user/add',
  UPDATE_USER: 'api/user/update',
  DELETE_USER: 'api/user/delete',
  GET_USER_RULES: 'api/user/rules',
  REQUEST_RESET_PASSWORD: 'api/user/request-reset-password',
  CHANGE_PASSWORD: 'api/user/change-password',
};

export const USERGROUP_SERVICE = {
  GET_USERGROUP: 'api/user-group/view-all',
  GET_USERGROUP_LIST: 'api/user-group/list',
  GET_SELECTED_USERGROUP: 'api/user-group/view',
  POST_USERGROUP_SUBMIT: 'api/user-group/submit',
  ADD_NEW_USERGROUP: 'api/user-group/add',
  UPDATE_USERGROUP: 'api/user-group/update',
  DELETE_USERGROUP: 'api/user-group/delete',
  GET_USERGROUP_SETTINGS: 'api/user-group/settings',
};

export const DASHBOARD_SERVICE = {
  GET_APEX_CHARTS_DATA: 'api/dashboard/apex-charts/data',
  GET_PIE_CHARTS_DATA: 'api/dashboard/pie-charts/data',
  GET_BAR_CHARTS_DATA: 'api/dashboard/bar-charts/data',
  GET_TOP_CHARTS_DATA: 'api/dashboard/top-charts/data',
  GET_RECENT_ACTIVITY_DATA: 'api/dashboard/recent-activity/data',
};

export const PROFILE_SERVICE = {
  GET_PROFILE: 'api/user-profile/view',
  EDIT_PROFILE: 'api/user-profile/update',
};

export const SUPPLIERS_SERVICE = {
  GET_SUPPLIERS_LIST_ALL: 'api/suppliers/list/all',
  GET_SUPPLIERS_LIST: 'api/suppliers/list',
  GET_SUPPLIERS_DOCUMENT_LIST: 'api/suppliers/documents/list',
  ADD_NEW_SUPPLIERS: 'api/suppliers/add',
  UPDATE_SUPPLIERS: 'api/suppliers/update',
  DELETE_SUPPLIERS: 'api/suppliers/delete',
  DELETE_ADDRESSES: 'api/suppliers/addresses/delete',
  DELETE_CONTACTS: 'api/suppliers/contacts/delete',
  DELETE_NOTES: 'api/suppliers/notes/delete',
  DELETE_DOCUMENTS: 'api/suppliers/documents/delete',
  GET_SUPPLIERS_BI: 'api/suppliers-bi',
  GET_INDUSTRIES_LIST: 'api/suppliers/industries/list',
  GET_INDUSTRIES_FILTER_LIST: 'api/suppliers/industries/filter/list',
};

export const CLIENTS_SERVICE = {
  GET_CLIENTS_LIST_ALL: 'api/clients/list/all',
  GET_CLIENTS_LIST: 'api/clients/list',
  GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER:
    'api/clients/list/supplier-reportmanager',
  ADD_NEW_CLIENTS: 'api/clients/add',
  UPDATE_CLIENTS: 'api/clients/update',
  DELETE_CLIENTS: 'api/clients/delete',
  UPDATE_CLIENT_CONTACTS: 'api/clients/contacts/update',
  GET_CLIENTS_LIST_ALL_DROPDOWN: 'api/clients/list/all/dropdown',
};

export const WORKERS_SERVICE = {
  GET_WORKERS_SERVICE_LIST_ALL: 'api/workers/list/all',
  GET_WORKERS__DEPARTMENT_SERVICE_LIST_ALL: 'api/workers/departments/list/all',
  GET_WORKERS_SERVICE_LIST: 'api/workers/list',
  ADD_NEW_WORKERS_SERVICE: 'api/workers/add',
  UPDATE_WORKERS_SERVICE: 'api/workers/update',
  DELETE_WORKERS_SERVICE: 'api/workers/delete',
  GET_WORKERS_BI: 'api/workers-bi',
  LOG_VIEW_WORKERS: 'api/audit-trail/add',
};

export const REQUISITION_SERVICE = {
  GET_REQUISITION_LIST: 'api/requests/list',
  GET_REQUISITION_LIST_FILTERED: 'api/requests/filtered-list',
  GET_TOTAL: 'api/requests/get-total',
  GET_OPTIONS: 'api/requests/get-options',
  GET_REQUISITION_WORKERS: 'api/requests/get/workers',
  ADD_REQUISITION: 'api/requests/add',
  PROCESS_REQUISITION: 'api/requests/process',
  APPROVE_REQUISITION: 'api/requests/approve',
  REJECT_REQUISITION: 'api/requests/reject',
  COMPLETE_REQUISITION: 'api/requests/complete',
};

export const CLIENT_SERVICE = {
  GET_FIELD_OPTIONS: 'api/field/options',
  GET_CLIENT_LIST: 'api/clients/list/all',
  GET_CLIENT_SUPPLIER_LIST: 'api/client/suppliers',
};

export const JOB_ORDER_SERVICE = {
  POST_ADD_CANDIDATE: 'api/joborder/candidate/add',
  POST_ADD_CANDIDATE_INTERVIEW: 'api/joborder/candidate/interviews/add',
  POST_ADD_CANDIDATE_OFFER: 'api/joborder/candidate/offer/update',
  POST_UPDATE_CANDIDATE_INTERVIEW: 'api/joborder/candidate/interviews/update',
  POST_UPDATE_CANDIDATE_STATUS: 'api/joborder/candidate/update-status',
  POST_ACCEPT_CANDIDATE: 'api/joborder/candidate/accept',
  GET_CANDIDATE_LIST: 'api/joborder/candidate/list',
  GET_CANDIDATE: 'api/joborder/candidate',
  POST_CONVERT_WORKER: 'api/joborder/candidate/convert-worker',
  GET_JOB_ORDER_LIST: 'api/joborder/list',
  UPDATE_JOB_ORDER: 'api/joborder/update',
};

export const OPERATION_SERVICE = {
  GET_OPERATION_CLIENTS: 'api/operations/clients',
  SAVE_OPERATION: 'api/operations/save',
  GET_OPERATION_LIST: 'api/operations/list',
};

export const DOCUMENT_SERVICE = {
  GET_DOCUMENT_LIST_ALL: 'api/documents/list/all',
  GET_DOCUMENT_LIST: 'api/documents/list',
  ADD_NEW_DOCUMENT: 'api/documents/add',
  UPDATE_DOCUMENT: 'api/documents/update',
};

export const CONFIGURATION_SERVICE = {
  SAVE_CONFIGURATION: 'api/configuration/save',
};

export const AUDIT_TRAIL_SERVICE = {
  GET_AUDIT_TRAIL_LIST: 'api/audit-trail/list',
  GET_AUDIT_TRAIL_TYPES: 'api/audit-trail/types',
};

export const REPORTS_SERVICE = {
  GET_REPORTS_TYPES: 'api/reports/types',
};

export const ANNOUNCMENTS_SERVICE = {
  GET_ANNOUNCMENTS_LIST_ALL: 'api/announcements/list/all',
  GET_ANNOUNCMENTS_LIST: 'api/announcements/list',
  ADD_NEW_ANNOUNCMENTS: 'api/announcements/add',
  UPDATE_ANNOUNCMENTS: 'api/announcements/update',
  DELETE_ANNOUNCMENTS: 'api/announcements/delete',
};
