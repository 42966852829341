import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_ANNOUNCEMENT_LIST,
  GET_ANNOUNCEMENT_LIST_SUCCESS,
  GET_ANNOUNCEMENT_LIST_FAILED,
  ADD_NEW_ANNOUNCEMENT,
  ADD_NEW_ANNOUNCEMENT_SUCCESS,
  ADD_NEW_ANNOUNCEMENT_FAILED,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT_FAILED,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAILED,
  GET_ANNOUNCEMENT_LIST_ALL,
} from './types';

import announcementServices from '../../api/services/announcements';

function* fnGetAnnouncementListAll({ payload }) {
  try {
    const data = yield call(announcementServices.api.fnGetAnnouncementListAll, payload);

    yield put({
      type: GET_ANNOUNCEMENT_LIST_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_ANNOUNCEMENT_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* fnGetAnnouncementList(payload) {
  const id = payload.payload.id;

  try {
    const data = yield call(announcementServices.api.fnGetAnnouncementList, id);

    yield put({
      type: GET_ANNOUNCEMENT_LIST_SUCCESS,
      payload: [data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_ANNOUNCEMENT_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}


function* fnAddNewAnnouncement({ payload }) {
  const { formData } = payload;
  try {
    yield call(announcementServices.api.fnAddNewAnnouncement, payload);

    yield put({
      type: ADD_NEW_ANNOUNCEMENT_SUCCESS,
    });
    yield put({
      type: GET_ANNOUNCEMENT_LIST_ALL,
    });
  } catch (error) {
    yield put({
      type: ADD_NEW_ANNOUNCEMENT_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* fnUpdateAnnouncement({ payload }) {
  try {
    const data = yield call(announcementServices.api.fnUpdateAnnouncement, payload);
    if (data.status == 200) {
      yield put({
        type: UPDATE_ANNOUNCEMENT_SUCCESS,
        payload: { message: data?.data.message, status: 'success' },
      });
      yield put({
        type: GET_ANNOUNCEMENT_LIST,
        payload: { id: payload.id },
      });
    } else {
      yield put({
        type: UPDATE_ANNOUNCEMENT_FAILED,
        payload: { message: data?.data.message, status: 'error' },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_ANNOUNCEMENT_FAILED,
      payload: error.payload.data ? error.payload.data.message : '',
    });
  }
}

function* fnDeleteAnnouncement({ payload }) {
  try {
    const { id } = payload;

    yield call(announcementServices.api.fnDeleteAnnouncement, id);
    yield put({
      type: DELETE_ANNOUNCEMENT_SUCCESS,
    });
    yield put({
      type: GET_ANNOUNCEMENT_LIST_ALL,
    });
  } catch (error) {
    yield put({
      type: DELETE_ANNOUNCEMENT_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}


export default function* watcher() {
  yield takeLatest(GET_ANNOUNCEMENT_LIST_ALL, fnGetAnnouncementListAll);
  yield takeLatest(GET_ANNOUNCEMENT_LIST, fnGetAnnouncementList);
  yield takeLatest(ADD_NEW_ANNOUNCEMENT, fnAddNewAnnouncement);
  yield takeLatest(UPDATE_ANNOUNCEMENT, fnUpdateAnnouncement);
  yield takeLatest(DELETE_ANNOUNCEMENT, fnDeleteAnnouncement);
}
