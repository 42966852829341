import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import Layout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import { actionCreator, types } from './store';

import Login from './pages/Authentication/Login';

// Import scss
import './assets/scss/theme.scss';
import './assets/scss/preloader.scss';

const App = ({ authentication, app, ...props }) => {
  const { user_access } = authentication.data?.data || { user_access: null };

  let access = user_access
    ? user_access
        .filter((a) => a.settings[0].is_read === 1)
        .map((item) => {
          if (item.url && item.url.split('/')[1]) {
            return item.url.split('/')[1];
          } else if (!item.url) {
            let subNav = item.children
              .map((item) => {
                return item.url.split('/')[1];
              })
              .join(',');
            return subNav;
          }
        })
        .join(',')
        .split(',')
    : null;

  access && access.push('profile');

  return (
    <React.Fragment>
      {/* <Router> */}
      <Routes>
        {!authentication.authenticated && (
          <Route path="/login" element={<Login />} />
        )}

        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {access &&
          userRoutes
            .filter((item) => {
              return access.includes(item.path.split('/')[1]);
            })
            .map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware path={route.path}>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                }
                key={idx}
                exact={true}
              />
            ))}
        <Route path="*" element={<Navigate to="/pages-404" replace />} />
      </Routes>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication, app, ...state }) => {
  return {
    layout: state.layout,
    authentication,
    app,
  };
};

export default connect(mapStateToProps, { actionCreator })(App);
