export const GET_WORKER_LIST_ALL = "@workers/GET_WORKER_LIST_ALL";
export const GET_WORKER_LIST_ALL_SUCCESS =
  "@workers/GET_WORKER_LIST_ALL_SUCCESS";
export const GET_WORKER_LIST_ALL_FAILED = "@workers/GET_WORKER_LIST_ALL_FAILED";

export const GET_WORKER_LIST = "@workers/GET_WORKER_LIST";
export const GET_WORKER_LIST_SUCCESS = "@workers/GET_WORKER_LIST_SUCCESS";
export const GET_WORKER_LIST_FAILED = "@workers/GET_WORKER_LIST_FAILED";

export const GET_WORKER_DEPARTMENT_LIST_ALL =
  "@workers/GET_WORKER_DEPARTMENT_LIST_ALL";
export const GET_WORKER_DEPARTMENT_LIST_ALL_SUCCESS =
  "@workers/GET_WORKER_DEPARTMENT_LIST_ALL_SUCCESS";
export const GET_WORKER_DEPARTMENT_LIST_ALL_FAILED =
  "@workers/GET_WORKER_DEPARTMENT_LIST_ALL_FAILED";

export const ADD_NEW_WORKER = "@workers/ADD_NEW_WORKER";
export const ADD_NEW_WORKER_SUCCESS = "@workers/ADD_NEW_WORKER_SUCCESS";
export const ADD_NEW_WORKER_FAILED = "@workers/ADD_NEW_WORKER_FAILED";

export const UPDATE_WORKER = "@workers/UPDATE_WORKER";
export const UPDATE_WORKER_SUCCESS = "@workers/UPDATE_WORKER_SUCCESS";
export const UPDATE_WORKER_FAILED = "@workers/UPDATE_WORKER_FAILED";

export const DELETE_WORKER = "@workers/DELETE_WORKER";
export const DELETE_WORKER_SUCCESS = "@workers/DELETE_WORKER_SUCCESS";
export const DELETE_WORKER_FAILED = "@workers/DELETE_WORKER_FAILED";

export const GET_WORKERS_BI = "@workers/GET_WORKERS_BI";
export const GET_WORKERS_BI_SUCCESS = "@workers/GET_WORKERS_BI_SUCCESS";
export const GET_WORKERS_BI_FAILED = "@workers/GET_WORKERS_BI_FAILED";

export const CLEAR_WORKER_SAVE_MESSAGE = "@workers/CLEAR_WORKER_SAVE_MESSAGE";

export const LOG_VIEW = "@workers/LOG_VIEW";
export const LOG_VIEW_SUCCESS = "@workers/LOG_VIEW_SUCCESS";
export const LOG_VIEW_FAILED = "@workers/LOG_VIEW_FAILED";
