import {
  GET_ANNOUNCEMENT_LIST_ALL_SUCCESS,
  GET_ANNOUNCEMENT_LIST_ALL_FAILED,
  GET_ANNOUNCEMENT_LIST_SUCCESS,
  GET_ANNOUNCEMENT_LIST_FAILED,
  ADD_NEW_ANNOUNCEMENT_SUCCESS,
  ADD_NEW_ANNOUNCEMENT_FAILED,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT_FAILED,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAILED,
  CLEAR_ANNOUNCEMENT_SAVE_MESSAGE,
} from './types';

const INIT_STATE = {
  loading: true,
  data: [],
  actionReturnData: [],
  error: false,
  errorMessage: null,
  isSubmitted: null,
};

const announcements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENT_LIST_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_ANNOUNCEMENT_LIST_ALL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_ANNOUNCEMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_ANNOUNCEMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
        errorMessage: action.payload,
      };

    case ADD_NEW_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case ADD_NEW_ANNOUNCEMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case UPDATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        actionReturnData: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case UPDATE_ANNOUNCEMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case DELETE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case DELETE_ANNOUNCEMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case CLEAR_ANNOUNCEMENT_SAVE_MESSAGE:
      return {
        ...state,
        actionReturnData: [],
      };

 

    default:
      return state;
  }
};

export default announcements;
