export const GET_ANNOUNCEMENT_LIST_ALL = '@announcements/GET_ANNOUNCEMENT_LIST_ALL';
export const GET_ANNOUNCEMENT_LIST_ALL_SUCCESS =
  '@announcements/GET_ANNOUNCEMENT_LIST_ALL_SUCCESS';
export const GET_ANNOUNCEMENT_LIST_ALL_FAILED =
  '@announcements/GET_ANNOUNCEMENT_LIST_ALL_FAILED';

export const GET_ANNOUNCEMENT_LIST = '@announcements/GET_ANNOUNCEMENT_LIST';
export const GET_ANNOUNCEMENT_LIST_SUCCESS = '@announcements/GET_ANNOUNCEMENT_LIST_SUCCESS';
export const GET_ANNOUNCEMENT_LIST_FAILED = '@announcements/GET_ANNOUNCEMENT_LIST_FAILED';

export const ADD_NEW_ANNOUNCEMENT = '@announcements/ADD_NEW_ANNOUNCEMENT';
export const ADD_NEW_ANNOUNCEMENT_SUCCESS = '@announcements/ADD_NEW_ANNOUNCEMENT_SUCCESS';
export const ADD_NEW_ANNOUNCEMENT_FAILED = '@announcements/ADD_NEW_ANNOUNCEMENT_FAILED';

export const UPDATE_ANNOUNCEMENT = '@announcements/UPDATE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT_SUCCESS = '@announcements/UPDATE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_ANNOUNCEMENT_FAILED = '@announcements/UPDATE_ANNOUNCEMENT_FAILED';

export const DELETE_ANNOUNCEMENT = '@announcements/DELETE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT_SUCCESS = '@announcements/DELETE_ANNOUNCEMENT_SUCCESS';
export const DELETE_ANNOUNCEMENT_FAILED = '@announcements/DELETE_ANNOUNCEMENT_FAILED';


export const CLEAR_ANNOUNCEMENT_SAVE_MESSAGE =
  '@announcements/CLEAR_ANNOUNCEMENT_SAVE_MESSAGE';