export const GET_AUDIT_TRAIL = "@audit-trail/GET_AUDIT_TRAIL_LIST";
export const GET_AUDIT_TRAIL_SUCCESS = "@audit-trail/GET_AUDIT_TRAIL_SUCCESS";
export const GET_AUDIT_TRAIL_FAILED = "@audit-trail/GET_AUDIT_TRAIL_FAILED";

export const ADD_NEW_AUDIT_TRAIL = "@audit-trail/ADD_NEW_AUDIT_TRAIL";
export const ADD_NEW_AUDIT_TRAIL_SUCCESS =
  "@audit-trail/ADD_NEW_AUDIT_TRAIL_SUCCESS";
export const ADD_NEW_AUDIT_TRAIL_FAILED =
  "@audit-trail/ADD_NEW_AUDIT_TRAIL_FAILED";

  export const GET_AUDIT_TRAIL_TYPES = "@audit-trail/GET_AUDIT_TRAIL_TYPES";
export const GET_AUDIT_TRAIL_TYPES_SUCCESS =
  "@audit-trail/GET_AUDIT_TRAIL_TYPES_SUCCESS";
export const GET_AUDIT_TRAIL_TYPES_FAILED =
  "@audit-trail/GET_AUDIT_TRAIL_TYPES_FAILED";
