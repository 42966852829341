import {
  GET_WORKER_LIST_ALL_SUCCESS,
  GET_WORKER_LIST_ALL_FAILED,
  GET_WORKER_LIST_SUCCESS,
  GET_WORKER_LIST_FAILED,
  GET_WORKER_DEPARTMENT_LIST_ALL,
  GET_WORKER_DEPARTMENT_LIST_ALL_SUCCESS,
  GET_WORKER_DEPARTMENT_LIST_ALL_FAILED,
  ADD_NEW_WORKER_SUCCESS,
  ADD_NEW_WORKER_FAILED,
  UPDATE_WORKER_SUCCESS,
  UPDATE_WORKER_FAILED,
  DELETE_WORKER_SUCCESS,
  DELETE_WORKER_FAILED,
  GET_WORKERS_BI_SUCCESS,
  GET_WORKERS_BI_FAILED,
  LOG_VIEW_SUCCESS,
  LOG_VIEW_FAILED,
  CLEAR_WORKER_SAVE_MESSAGE,
} from './types';

const INIT_STATE = {
  loading: true,
  data: [],
  listData: [],
  departmentData: [],
  workersBIData: [],
  actionReturnData: [],
  error: false,
  errorMessage: null,
  isSubmitted: null,
};

const Workers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WORKER_LIST_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        listData: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_WORKER_LIST_ALL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_WORKER_DEPARTMENT_LIST_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentData: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_WORKER_DEPARTMENT_LIST_ALL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_WORKER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_WORKER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case ADD_NEW_WORKER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
        actionReturnData: action.payload,
      };

    case ADD_NEW_WORKER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case UPDATE_WORKER_SUCCESS:
      return {
        ...state,
        actionReturnData: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case UPDATE_WORKER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case DELETE_WORKER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case DELETE_WORKER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case GET_WORKERS_BI_SUCCESS:
      return {
        ...state,
        loading: false,
        workersBIData: action.payload.data,
        error: false,
        errorMessage: null,
      };

    case GET_WORKERS_BI_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case LOG_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case LOG_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case CLEAR_WORKER_SAVE_MESSAGE:
      return {
        ...state,
        actionReturnData: [],
      };
    default:
      return state;
  }
};

export default Workers;
