import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAILED,
  ADD_NEW_CLIENT,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAILED,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
  UPDATE_CLIENT_CONTACTS,
  UPDATE_CLIENT_CONTACTS_SUCCESS,
  UPDATE_CLIENT_CONTACTS_FAILED,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILED,
  GET_CLIENT_LIST_ALL,
  GET_CLIENT_LIST_ALL_SUCCESS,
  GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_SUCCESS,
  GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER,
  GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_FAILED,
  GET_CLIENT_LIST_ALL_DROPDOWN_SUCCESS,
  GET_CLIENT_LIST_ALL_DROPDOWN_FAILED,
  GET_CLIENT_LIST_ALL_DROPDOWN,
} from './types';

import clientServices from '../../api/services/clients';

function* fnGetClientListAll() {
  try {
    const data = yield call(clientServices.api.fnGetClientListAll);

    yield put({
      type: GET_CLIENT_LIST_ALL_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_CLIENT_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* fnGetClientList(payload) {
  const id = payload.payload.id;

  try {
    const data = yield call(clientServices.api.fnGetClientList, id);
    yield put({
      type: GET_CLIENT_LIST_SUCCESS,
      payload: data.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_CLIENT_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* fnGetClientListSuppliersReportingManager() {
  try {
    const data = yield call(
      clientServices.api.fnGetClientListSuppliersReportingManager,
    );

    yield put({
      type: GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* fnAddNewClient({ payload }) {
  try {
    yield call(clientServices.api.fnAddNewClient, payload);

    yield put({
      type: ADD_NEW_CLIENT_SUCCESS,
      //payload: { message: response.data.message, status: "success" },
    });

    // yield put({
    //   type: GET_CLIENT_LIST,
    // });
  } catch (error) {
    yield put({
      type: ADD_NEW_CLIENT_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* fnUpdateClient({ payload }) {
  try {
    const data = yield call(clientServices.api.fnUpdateClient, payload);
    if (data.status == 200) {
      yield put({
        type: UPDATE_CLIENT_SUCCESS,
        payload: { message: data?.data.message, status: 'success' },
      });
      yield put({
        type: GET_CLIENT_LIST,
        payload: { id: payload.id },
      });
    } else {
      yield put({
        type: UPDATE_CLIENT_FAILED,
        payload: { message: data?.data.message, status: 'error' },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_CLIENT_FAILED,
      payload: error.payload.data ? error.payload.data.message : '',
    });
  }
}

function* fnUpdateClientContacts({ payload }) {
  try {
    const data = yield call(clientServices.api.fnUpdateClientContacts, payload);
    if (data.status == 200) {
      yield put({
        type: UPDATE_CLIENT_CONTACTS_SUCCESS,
        payload: { message: data?.data.message, status: 'success' },
      });
    } else {
      yield put({
        type: UPDATE_CLIENT_CONTACTS_FAILED,
        payload: error.response.data ? error.response.data.message : '',
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_CLIENT_CONTACTS_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* fnDeleteClient({ payload }) {
  try {
    const { id } = payload;

    yield call(clientServices.api.fnDeleteClient, id);
    yield put({
      type: DELETE_CLIENT_SUCCESS,
    });
    yield put({
      type: GET_CLIENT_LIST,
    });
  } catch (error) {
    yield put({
      type: DELETE_CLIENT_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* fnGetClientListAllDropdown() {
  try {
    const data = yield call(clientServices.api.fnGetClientListAllDropdown);

    yield put({
      type: GET_CLIENT_LIST_ALL_DROPDOWN_SUCCESS,
      payload: [data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_CLIENT_LIST_ALL_DROPDOWN_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_CLIENT_LIST_ALL, fnGetClientListAll);
  yield takeLatest(GET_CLIENT_LIST, fnGetClientList);
  yield takeLatest(
    GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER,
    fnGetClientListSuppliersReportingManager,
  );
  yield takeLatest(ADD_NEW_CLIENT, fnAddNewClient);
  yield takeLatest(UPDATE_CLIENT, fnUpdateClient);
  yield takeLatest(DELETE_CLIENT, fnDeleteClient);
  yield takeLatest(UPDATE_CLIENT_CONTACTS, fnUpdateClientContacts);
  yield takeLatest(GET_CLIENT_LIST_ALL_DROPDOWN, fnGetClientListAllDropdown);
}
