const ID = (cell) => {
  return cell.value ? cell.value : '';
};

const Message = (cell) => {
  return cell.value ? cell.value : '';
};

const Clients = (cell) => {
  return cell.value ? cell.value : '';
};

const DateAdded = (cell) => {
  return cell.value ? cell.value : '';
};


const Status = (cell) => {
  return cell.value === 1 ? 'Active' : 'Inactive';
};

const Date = (cell) => {
  return cell.value ? cell.value : '';
};


export {
  ID,
  Message,
  Clients,
  DateAdded,
  Status,
  Date,
};
