import { call, put, takeLatest } from 'redux-saga/effects';
import { saveAs } from 'file-saver'; // Ensure this is imported

import {
  GET_REPORTS_TYPES,
  GET_REPORTS_TYPES_SUCCESS,
  GET_REPORTS_TYPES_FAILED,
} from './types';

import reportsService from '../../api/services/reports';

function* fnGetReportsTypes({ payload }) {
  try {
    const data = yield call(reportsService.api.fnGetReportsTypes, payload);
    if (data) {
      const types = payload.types || 'Default_Report';
      const filename = `${types}_list`.replace(/[^a-zA-Z0-9_]/g, '_'); // Sanitize filename

      const buffer = data.data.buffer.data;
      const arraybuffer = Uint8Array.from(buffer).buffer;
      const blob = new Blob([arraybuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, filename);

      yield put({
        type: GET_REPORTS_TYPES_SUCCESS,
        payload: data.data.result,
      });
    }
  } catch (error) {
    yield put({
      type: GET_REPORTS_TYPES_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_REPORTS_TYPES, fnGetReportsTypes);
}
