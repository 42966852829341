import {
  takeEvery,
  takeLatest,
  fork,
  put,
  all,
  call,
} from 'redux-saga/effects';

// Login Redux States
import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from './types';

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from '../../../helpers/fakebackend_helper';

import authenticationServices from '../../../api/services/authentication';

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetPassword({ payload: { email } }) {
  try {
    const response = yield call(authenticationServices.api.fnForgetPassword, {
      email,
    });
    if (response) {
      yield put({
        type: types.FORGET_PASSWORD_SUCCESS,
        payload: { data: "An email with the reset link has been sent to you. Please check your inbox."},
      });
    }
  } catch (error) {
    yield put({
      type: types.FORGET_PASSWORD_ERROR,
      payload: { error: error.response?.data.message },
    });
  }
}

function* resetPassword({ payload: { password, headers } }) {
  try {
    const response = yield call(authenticationServices.api.fnResetPassword, {
      password,
      headers,
    });
    if (response) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        payload: {
          data: 'Your password has been changed. You will be redirected to the login page shortly...',
        },
      });
    }
  } catch (error) {
    // let errorMessage = '';
    // if (error.message === 'Network Error') {
    //   errorMessage = 'Server connection failed.';
    // } else {
    //   errorMessage = error.response?.data.message;
    // }
    yield put({
      type: RESET_PASSWORD_ERROR,
      payload: error.response.data.message,
    });
  }
}

export function* watchUserPasswordForget() {
  yield takeLatest(FORGET_PASSWORD, forgetPassword)
  yield takeLatest(RESET_PASSWORD, resetPassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
