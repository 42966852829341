import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Label, Input } from 'reactstrap';
import { actionCreator } from '../../../store';

//SimpleBar
import SimpleBar from 'simplebar-react';

const TopChart = ({ dashboard }) => {
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0">Top Suppliers </h4>
          </CardHeader>
          <CardBody className="px-0">
            <SimpleBar className="px-3" style={{ maxHeight: '352px' }}>
              {dashboard.topCharts.data?.supplierList &&
                dashboard.topCharts.data.supplierList.map((item, index) => (
                  <p>
                    <Label key={index}>{item.supplier_name}</Label>
                  </p>
                ))}
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  dashboard,
});

export default connect(mapStateToProps, { actionCreator })(TopChart);
