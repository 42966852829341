import {
  GET_USERGROUP_SUCCESS,
  GET_USERGROUP_FAILED,
  GET_SELECTED_USERGROUP_SUCCESS,
  GET_SELECTED_USERGROUP_FAILED,
  GET_USERGROUP_LIST_SUCCESS,
  GET_USERGROUP_LIST_FAILED,
  POST_USERGROUP_SUBMIT_SUCCESS,
  POST_USERGROUP_SUBMIT_FAILED,
  CLEAR_USERGROUP,
  ADD_NEW_USERGROUP_SUCCESS,
  ADD_NEW_USERGROUP_FAILED,
  UPDATE_USERGROUP_SUCCESS,
  UPDATE_USERGROUP_FAILED,
  DELETE_USERGROUP_SUCCESS,
  DELETE_USERGROUP_FAILED,
  CLEAR_USERGROUP_ERROR,
  GET_USERGROUP_SETTINGS_SUCCESS,
  GET_USERGROUP_SETTINGS_FAILED,
} from "./types";

const INITIAL_STATE = {
  loading: true,
  data: [],
  selectedUserData: [],
  settings: [],
  error: null,
  errorMessage: null,
  list: { data: [] },
  status: null,
  isUserGroupSubmitted: null,
};

const usergroup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
        errorMessage: null,
      };

    case GET_USERGROUP_FAILED:
      return {
        ...state,
        error: action.payload.result,
        errorMessage: action.payload.message,
        data: [],
      };

    case GET_USERGROUP_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
          status: null,
        },
      };

    case GET_USERGROUP_LIST_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        list: {
          ...state.list,
          error: true,
          errorMessage: action.payload,
          status: action.status,
        },
      };

    case GET_SELECTED_USERGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        errorMessage: null,
        selectedUserData: action.payload,
      };

    case GET_SELECTED_USERGROUP_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.message,
        selectedUserData: [],
        isUserGroupSubmitted: false,
      };

    case POST_USERGROUP_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        submitting: false,
        isUserGroupSubmitted: true,
        error: null,
        errorMessage: null,
        isUserGroupSubmitted: true,
      };

    case POST_USERGROUP_SUBMIT_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.message,
        isUserGroupSubmitted: false,
      };

    case ADD_NEW_USERGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isUserGroupSubmitted: true,
        message: action.payload.message,
        isUserGroupSubmitted: true,
      };

    case ADD_NEW_USERGROUP_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isUserGroupSubmitted: false,
      };

    case UPDATE_USERGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isUserGroupSubmitted: true,
        message: action.payload.message,
      };

    case UPDATE_USERGROUP_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isUserGroupSubmitted: false,
      };

    case DELETE_USERGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isUserGroupSubmitted: true,
        message: action.payload.message,
      };

    case DELETE_USERGROUP_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isUserGroupSubmitted: false,
      };

      case GET_USERGROUP_SETTINGS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          errorMessage: null,
          settings: action.payload,
        };
  
      case GET_USERGROUP_SETTINGS_FAILED:
        return {
          ...state,
          error: true,
          errorMessage: action.payload.message,
          settings: [],
          isUserGroupSubmitted: false,
        };

    case CLEAR_USERGROUP:
      return INITIAL_STATE;

    case CLEAR_USERGROUP_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        status: null,
      };

    default:
      return state;
  }
};

export default usergroup;
