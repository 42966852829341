const multiSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightblue" : provided.backgroundColor,
    color: state.isSelected ? "black" : provided.color,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(81, 86, 190)",
    color: "white",
    borderRadius: 7,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
  }),
};

export { multiSelectStyle };
