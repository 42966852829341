import { all, fork } from 'redux-saga/effects';

// public
import AccountSaga from './auth/register/saga';
import authenticationSaga from './authentication/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import LayoutSaga from './layout/saga';

// import dashboard
import appSaga from './app/saga';
import dashBoardSaga from './dashboard/saga';
import userSaga from './user/saga';
import usergroupSaga from './user-group/saga';
import supplierSaga from './suppliers/saga';
import clientSaga from './clients/saga';
import workerSaga from './workers/saga';
import requisitionSaga from './requisition/saga';
import clientFieldsSaga from './client-fields/saga';
import operationsSaga from './operations/saga';
import jobOrderSaga from './job-order/saga';
import documentSaga from './document/saga';
import configurationSaga from './configuration/saga';
import auditTrailSaga from './audit-trail/saga';
import reportsSaga from './reports/saga';
import announcementsSaga from './announcements/saga';

export default function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(AccountSaga),
    fork(authenticationSaga),
    fork(clientFieldsSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashBoardSaga),
    fork(userSaga),
    fork(usergroupSaga),
    fork(supplierSaga),
    fork(clientSaga),
    fork(workerSaga),
    fork(requisitionSaga),
    fork(operationsSaga),
    fork(jobOrderSaga),
    fork(documentSaga),
    fork(configurationSaga),
    fork(auditTrailSaga),
    fork(reportsSaga),
    fork(announcementsSaga),
  ]);
}
