import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Collapse,
  Label,
  CardHeader,
  Card,
  CardTitle,
  CardBody,
} from 'reactstrap';

const InterviewSchedules = ({
  candidateData,
  isNoShow,
  isInterviewAccepted,
  openIndex,
  toggleCollapse,
  updateInterviewScheduleHandler,
  isClientHiringManager,
  isSupplierOps,
}) => {
  const renderProposeTimeView = (item, status_id) => {
    let isAlreadyAccepted = false;

    for (let schedule of item.schedules) {
      if (schedule.accepted_by_candidate) {
        isAlreadyAccepted = true;
      }
    }

    return item.schedules.map((schedule, sched_index) => {
      return (
        <p
          key={sched_index}
          style={{
            color: schedule.accepted_by_candidate ? '#5156BE' : '#313533bf',
          }}
        >
          {moment(schedule.interview_date).format('MMM. D, YYYY')} -{' '}
          {schedule.formatted_start_time} {schedule.duration}hr Duration{' '}
          {schedule.accepted_by_candidate && (
            <i className="bx bx-check" style={{ fontSize: 18 }}></i>
          )}
          {isSupplierOps &&
            !item.is_rejected_schedule &&
            !item.is_rescheduled &&
            !isAlreadyAccepted && (
              <Link
                to="#"
                style={{
                  marginLeft: 20,
                  fontWeight: 700,
                  textDecoration: 'underline !important',
                }}
                onClick={() =>
                  updateInterviewScheduleHandler(
                    'accept by candidate',
                    schedule.id,
                  )
                }
              >
                Accept by Candidate
              </Link>
            )}
          {!item.is_rejected_schedule &&
            !item.is_rescheduled &&
            schedule.accepted_by_candidate &&
            !schedule.mark_no_show &&
            ![6, 7, 8, 11].includes(status_id) && (
              // if offer status don't show mark no show
              <button
                type="button"
                className="btn btn-lg"
                style={{
                  color: '#FD625E',
                  border: '1px solid #FD625E',
                  backgroundColor: '#FFFFFF',
                  marginLeft: 20,
                  fontWeight: 700,
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() =>
                  updateInterviewScheduleHandler('mark no show', schedule.id)
                }
              >
                Mark as No Show
              </button>
            )}
          {!item.is_rejected_schedule &&
            !item.is_rescheduled &&
            schedule.mark_no_show && (
              <span
                style={{
                  color: '#FD625E',
                  fontWeight: 700,
                  marginLeft: 20,
                }}
              >
                No Show
              </span>
            )}
        </p>
      );
    });
  };

  return (
    <React.Fragment>
      {candidateData.length > 0 &&
        candidateData[0].interview_schedules !== undefined && (
          <Row>
            <Col xs={12}>
              <Label>Job Title</Label>
              <p
                style={{
                  color: '#495057',
                }}
              >
                {candidateData[0].joborder_details.job_title}
              </p>
            </Col>
          </Row>
        )}
      {/* Offer Details */}
      {candidateData.length > 0 &&
        [6, 7, 8, 11].includes(candidateData[0].status_id) && (
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      Job Offer Details
                    </p>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={5} md={6} lg={4}>
                      <Label>Pay Rate</Label>
                      <div
                        className="mt-3"
                        style={{
                          width: 220,
                          height: 60,
                          borderRadius: 4,
                          border: 'solid 1px #5156BE',
                          backgroundClip: '#FFFFFF',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: 5,
                        }}
                      >
                        <h3
                          style={{
                            color: '#5156BE',
                            fontWeight: 700,
                          }}
                        >
                          {Number(candidateData[0].pay_rate).toLocaleString()}
                        </h3>
                      </div>
                    </Col>
                    <Col xs={5} md={6} lg={4}>
                      <Label>Reporting Location</Label>
                      <p>{candidateData[0].reporting_location}</p>
                      <Label>Reporting Date</Label>
                      <p>
                        {moment(candidateData[0].reporting_date).format(
                          'MMMM D, YYYY',
                        )}{' '}
                        at {candidateData[0].reporting_time}
                      </p>
                      <Label>Person to Report To</Label>
                      <p>{candidateData[0].person_to_report_to}</p>
                      <Label>Contact Details</Label>
                      <p>{candidateData[0].contact_details}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      <Row
        style={{
          marginBottom: 100,
        }}
      >
        <Col xs={12}>
          <div className="accordion" id="accordion">
            {candidateData.length > 0 &&
              candidateData[0].interview_schedules !== undefined &&
              candidateData[0].interview_schedules.map((item, index) => {
                const schedulesCount =
                  candidateData[0].interview_schedules.length;

                const number =
                  schedulesCount > 1 ? schedulesCount - index : schedulesCount;

                return (
                  <div key={index} className="accordion-item">
                    <h2 className="accordion-header" id={`heading-${index}`}>
                      <button
                        className={classnames('accordion-button', 'fw-medium', {
                          collapsed: openIndex !== index,
                        })}
                        type="button"
                        onClick={() => toggleCollapse(index)}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: '#FFFFFF',
                        }}
                      >
                        {number} - Interview Details |{' '}
                        <span
                          style={{
                            marginLeft: 20,
                            fontWeight: 400,
                          }}
                        >
                          {item.subject}
                        </span>
                        {item.is_rescheduled && (
                          <span
                            style={{
                              color: '#FD625E',
                              marginLeft: 20,
                            }}
                          >
                            Rescheduled
                          </span>
                        )}
                      </button>
                    </h2>

                    <Collapse
                      isOpen={openIndex === index}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <div className="text-muted">
                          {isSupplierOps &&
                            !item.is_rescheduled &&
                            !item.is_rejected_schedule &&
                            !isInterviewAccepted && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-lg"
                                  style={{
                                    color: '#FFA000',
                                    border: '1px solid #FFBF53',
                                    backgroundColor: '#FFFFFF',
                                    fontWeight: 700,
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                  }}
                                  onClick={() =>
                                    updateInterviewScheduleHandler(
                                      'reject schedule',
                                      item.id,
                                    )
                                  }
                                >
                                  Reject Schedule
                                </button>
                                <br />
                                <br />
                              </>
                            )}
                          {((isClientHiringManager &&
                            item.is_rejected_schedule &&
                            !item.is_rescheduled) ||
                            isNoShow) && (
                            <>
                              <button
                                type="button"
                                className="btn btn-lg"
                                style={{
                                  color: '#FFA000',
                                  border: '1px solid #FFBF53',
                                  backgroundColor: '#FFFFFF',
                                  fontWeight: 700,
                                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                }}
                                onClick={() =>
                                  updateInterviewScheduleHandler(
                                    'reschedule',
                                    item.id,
                                  )
                                }
                              >
                                Reschedule for Interview
                              </button>
                              <br />
                              <br />
                            </>
                          )}
                          <strong className="text-dark">Subject</strong>{' '}
                          <p>{item.subject}</p>
                          <strong className="text-dark">
                            Proposed Interview Time
                          </strong>{' '}
                          {renderProposeTimeView(
                            item,
                            candidateData[0].status_id,
                          )}
                          <strong className="text-dark">Interviewers</strong>{' '}
                          <p>
                            {item.interviewer
                              .map(
                                (interviewee) =>
                                  interviewee.client_contact_name,
                              )
                              .join(', ')}
                          </p>
                          <strong className="text-dark">Other Details</strong>{' '}
                          <p>{item.details}</p>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InterviewSchedules;
