import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Row, Col, Input } from 'reactstrap';
import { actionCreator, types } from '../../store';
import TableContainer from '../../components/Common/TableContainer';
import { RowData } from './column';
import { ToastContainer, toast } from 'react-toastify';
import Flatpickr from 'react-flatpickr';

const AuditTrail = ({ audittrail, ...props }) => {
  const [dateRange, setDateRange] = React.useState([new Date(), new Date()]);
  const [filter, setFilter] = React.useState({
    filterByType: '',
    filterByDate: moment().format('DD/MM/YYYY'),
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <RowData {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <RowData {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Entity Name',
        accessor: 'entity_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <RowData {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Label',
        accessor: 'label',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <RowData {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'From',
        accessor: 'from',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <RowData {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'To',
        accessor: 'to',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <RowData {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Date Added',
        accessor: 'date_added',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <RowData {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Added by',
        accessor: 'username',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <RowData {...cellProps} />{' '}
            </>
          );
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchAuditTrail();
  }, [filter]);

  React.useEffect(() => {
    fetchAuditTrailType();
  }, []);

  const fetchAuditTrailType = async () => {
    await props.actionCreator({
      type: types.GET_AUDIT_TRAIL_TYPES,
    });
  };

  const fetchAuditTrail = async () => {
    await props.actionCreator({
      type: types.GET_AUDIT_TRAIL,
      payload: {
        daterange: filter.filterByDate,
        type: filter.filterByType,
      },
    });
  };

  const resetFilter = () => {
    setFilter({
      filterByType: '',
      filterByDate: moment().format('DD/MM/YYYY'),
    });
    setDateRange(moment().format('DD/MM/YYYY'));
  };

  const handleFilter = (filter, event) => {
    if (filter === 'type') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByType: event.target.value,
        };

        return newFilter;
      });
    }

    if (filter === 'daterange') {
      setDateRange(event);
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByDate: event,
        };
        return newFilter;
      });
    }
  };

  //meta title
  document.title = 'Audit Trail';
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <div className="mb-3">
              <Input
                type="select"
                className="form-select"
                multiple={false}
                onChange={(e) => handleFilter('type', e)}
                value={filter.filterByType}
              >
                <option value={''}>Filter by Type</option>
                {audittrail.dataTypes.map((item, index) => (
                  <option key={index} value={item.type}>
                    {item.type}
                  </option>
                ))}
              </Input>
            </div>
          </Col>
          <Col lg={4}>
            <div className="mb-3">
              <Flatpickr
                options={{
                  mode: 'range',
                  dateFormat: 'd/m/Y',
                  defaultDate: dateRange,
                  onChange: (selectedDates, dateStr, instance) => {
                    handleFilter('daterange', dateStr);
                  },
                }}
                value={dateRange}
                className="form-control"
                style={{ height: '38px' }}
              />
            </div>
          </Col>
          {(filter.filterByType || filter.filterByDate) && (
            <Col lg={2}>
              <div className="mb-3">
                <button
                  type="button"
                  className="btn"
                  onClick={resetFilter}
                  style={{
                    color: '#4549a2',
                    textDecoration: 'underline',
                    fontWeight: 700,
                  }}
                >
                  Reset Filter
                </button>
              </div>
            </Col>
          )}
        </Row>
        <Row className="align-items-center">
          <Col xl={12}>
            <TableContainer
              columns={columns}
              data={audittrail.data}
              isGlobalFilter={true}
              customPageSize={10}
              className="table align-middle datatable dt-responsive table-check nowrap"
            />
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({ audittrail }) => ({
  audittrail,
});

export default connect(mapStateToProps, { actionCreator })(AuditTrail);
