import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  API_ERROR,
  PASSWORD_EXPIRED,
} from './types';

import authenticationServices from '../../api/services/authentication';

function* loginUser({ payload: { user, history, onFailure } }) {
  try {
    const response = yield call(authenticationServices.api.fnLogin, {
      username: user.username,
      password: user.password,
    });

    if (response.data.message === 'password_expired') {
      // Password expired, dispatch action and redirect
      yield put({
        type: PASSWORD_EXPIRED,
        payload: response.data,
      });

      // Call onFailure to handle redirect in the component
      if (onFailure) {
        onFailure(response.data);
      }
    } else {
      yield put({
        type: LOGIN_SUCCESS,
        payload: { token: response.data.token, data: response.data },
      });
      history('/dashboard'); // Navigate to dashboard on successful login
    }
  } catch (error) {
    yield put({
      type: LOGIN_FAILED,
      payload: error.response.data ? error.response.data.message : '',
    });
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield put({
      type: LOGOUT_USER_SUCCESS,
    });

    //history("/logout");
  } catch (error) {
    yield put({
      type: API_ERROR,
      payload: { error },
    });
  }
}

function* authenticationSaga() {
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(LOGOUT_USER, logoutUser);
}

export default authenticationSaga;
