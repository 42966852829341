import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ID, Message, Clients, DateAdded, Status, Date } from './column';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';

const AnnouncementsList = ({ app, announcements, ...props }) => {
  document.title = 'Announcements List | Tapfin';
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedAnnouncement, setSelectedAnnouncement] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const announcementsData = cellProps.row.original;
          return (
            <Link
              to={`/announcements/edit/${announcementsData.id}`}
              style={{
                fontWeight: 700,
                fontFamily: 'IBM Plex Sans',
              }}
            >
              <ID {...cellProps} />
            </Link>
          );
        },
      },
      {
        Header: 'Message',
        accessor: 'message',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const announcementsData = cellProps.row.original;
          return (
            <Link
              to={`/announcements/edit/${announcementsData.id}`}
              style={{
                fontWeight: 700,
                fontFamily: 'IBM Plex Sans',
              }}
            >
              <Message {...cellProps} />
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          let statusText = '';
          let badgeClass = '';

          if (cellProps.value === 1) {
            statusText = 'Active';
            badgeClass = 'text-success bg-success-subtle';
          } else if (cellProps.value === 2) {
            statusText = 'Inactive';
            badgeClass = 'text-danger bg-danger-subtle';
          }

          return (
            <>
              <Link to="#" className={`badge font-size-11 m-1 ${badgeClass}`}>
                {statusText}
              </Link>
            </>
          );
        },
      },
      {
        Header: 'Date Modified',
        accessor: 'date_modified',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Date {...cellProps} />{' '}
            </>
          );
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_ANNOUNCEMENT_LIST_ALL,
    });
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete announcements modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (announcements) => {
    setSelectedAnnouncement(announcements);
    setDeleteModal(true);
  };

  const handleDeleteAnnouncement = () => {
    props.actionCreator({
      type: types.DELETE_ANNOUNCEMENT,
      payload: {
        id: selectedAnnouncement.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAnnouncement}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Announcements"
          />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Announcements List{' '}
                      <span className="text-muted fw-normal ms-2">
                        ({announcements.data?.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to={`/announcements/add`}
                        className="btn btn-primary"
                      >
                        <i className="bx bx-plus me-1"></i> Add New
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={announcements.data}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ app, announcements }) => ({
  app,
  announcements,
});

export default connect(mapStateToProps, { actionCreator })(AnnouncementsList);
