import React from 'react';
import { Link } from 'react-router-dom';
import { size, map } from 'lodash';

const Name = (cell) => {
  return cell.value ? cell.value : '';
};

const Status = (cell) => {
  return cell.value === 1 ? 'Active' : 'Inactive';
};

const Date = (cell) => {
  return cell.value ? cell.value : '';
};

const Id = (cell) => {
  return cell.value ? cell.value : '';
};

export { Status, Name, Date, Id };
