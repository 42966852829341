import { GET_REPORTS_TYPES_SUCCESS, GET_REPORTS_TYPES_FAILED } from './types';

const INIT_STATE = {
  loading: true,
  data: [],
  error: false,
  errorMessage: null,
};

const reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_REPORTS_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default reports;
