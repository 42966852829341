import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { actionCreator, types } from "../../store";
import { USER_GROUPS } from "../../constants/app";
import {
  Announcements,
  ApexChart,
  BarChart,
  PieChart,
  TopChart,
  RecentActivity,
} from "./components";
import Breadcrumb from "../../components/Common/Breadcrumb";
import "./customStyles.css";

const Dashboard = ({ authentication, dashboard, ...props }) => {
  const currentYear = moment().year();
  const [filter, setFilter] = React.useState({
    filterByYear: currentYear,
  });
  const { user_group_id, user_rules } = authentication.data.data;

  React.useEffect(() => {
    fetchApexChartsData();
    fetchPieChartsData();
    fetchTopChartsData();
    fetchRecentRequestsActivityData();
  }, []);

  React.useEffect(() => {
    fetchBarChartsData();
  }, [filter.filterByYear]);

  const fetchApexChartsData = async () => {
    await props.actionCreator({
      type: types.GET_APEX_CHARTS_DATA,
      payload: {
        client_id: null,
      },
    });
  };

  const fetchPieChartsData = async () => {
    await props.actionCreator({
      type: types.GET_PIE_CHARTS_DATA,
      payload: {
        client_id: null,
      },
    });
  };

  const fetchBarChartsData = async () => {
    let clientId = null;
    if (user_rules != 'all' && user_group_id === USER_GROUPS.CLIENT_HIRING_MANAGER) {
      clientId = parseInt(
        user_rules.filter((r) => r.type === 'client')[0].source_id,
      );
    }
    await props.actionCreator({
      type: types.GET_BAR_CHARTS_DATA,
      payload: {
        client_id: clientId,
        year: filter.filterByYear,
      },
    });
  };

  const fetchTopChartsData = async () => {
    await props.actionCreator({
      type: types.GET_TOP_CHARTS_DATA,
      payload: {
        client_id: null,
      },
    });
  };

  const fetchRecentRequestsActivityData = async () => {
    let clientId = null;
    if (user_rules != 'all' && user_group_id === USER_GROUPS.CLIENT_HIRING_MANAGER) {
      clientId = parseInt(
        user_rules.filter((r) => r.type === 'client')[0].source_id,
      );
    }

    await props.actionCreator({
      type: types.GET_RECENT_ACTIVITY_DATA,
      payload: {
        client_id: clientId,
      },
    });
  };
  //meta title
  document.title = "Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Dashboard" />
          <Row>
            <ApexChart user_group_id={user_group_id} />
          </Row>
          <Row>
            {(user_group_id === USER_GROUPS.PMO_SUPPORT ||
              user_group_id === USER_GROUPS.SUPER_ADMIN) && <PieChart />}
            <Col>
              <Row>
                {(user_group_id === USER_GROUPS.PMO_SUPPORT || 
                  user_group_id === USER_GROUPS.CLIENT_HIRING_MANAGER ||
                  user_group_id === USER_GROUPS.SUPER_ADMIN) && (
                  <RecentActivity />
                )}
                {(user_group_id === USER_GROUPS.PMO_SUPPORT ||
                  user_group_id === USER_GROUPS.CLIENT_HIRING_MANAGER ||
                  user_group_id === USER_GROUPS.CLIENT_HR_PROCUREMENT ||
                  user_group_id === USER_GROUPS.SUPER_ADMIN) && (
                  <Announcements />
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            {(user_group_id === USER_GROUPS.PMO_SUPPORT ||
              user_group_id === USER_GROUPS.SUPER_ADMIN) && (
              <TopChart user_group_id={user_group_id} />
            )}
            {(user_group_id === USER_GROUPS.PMO_SUPPORT ||
              user_group_id === USER_GROUPS.SUPER_ADMIN ||
              user_group_id === USER_GROUPS.CLIENT_HIRING_MANAGER ||
              user_group_id === USER_GROUPS.SUPPLIER_OPS) && (
              <BarChart filter={filter} setFilter={setFilter} />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication, dashboard }) => ({
  authentication,
  dashboard,
});

export default connect(mapStateToProps, { actionCreator })(Dashboard);
