import { emsApi } from '..';
import { SUPPLIERS_SERVICE } from '../constants';

class Suppliers {
  async fnGetSupplierListAll(payload) {
    return await emsApi.post(SUPPLIERS_SERVICE.GET_SUPPLIERS_LIST_ALL, payload);
  }

  async fnGetSupplierList(values) {
    return await emsApi.get(
      `${SUPPLIERS_SERVICE.GET_SUPPLIERS_LIST}/${values}`,
    );
  }

  async fnGetSupplierDocumentList(values) {
    return await emsApi.get(
      `${SUPPLIERS_SERVICE.GET_SUPPLIERS_DOCUMENT_LIST}/${values}`,
    );
  }

  async fnAddNewSupplier(values) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return await emsApi.post(SUPPLIERS_SERVICE.ADD_NEW_SUPPLIERS, values, {
      headers: headers,
    });
    //return await emsApi.post(SUPPLIERS_SERVICE.ADD_NEW_SUPPLIERS, payload);
  }

  async fnUpdateSupplier(values) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return await emsApi.patch(
      `${SUPPLIERS_SERVICE.UPDATE_SUPPLIERS}/${values.id}`,
      values,
      {
        headers: headers,
      },
    );
  }

  async fnDeleteSupplier(id) {
    return await emsApi.delete(`${SUPPLIERS_SERVICE.DELETE_SUPPLIERS}/${id}`);
  }

  async fnDeleteAddress(id) {
    return await emsApi.delete(`${SUPPLIERS_SERVICE.DELETE_ADDRESSES}/${id}`);
  }

  async fnDeleteContact(id) {
    return await emsApi.delete(`${SUPPLIERS_SERVICE.DELETE_CONTACTS}/${id}`);
  }

  async fnDeleteNote(id) {
    return await emsApi.delete(`${SUPPLIERS_SERVICE.DELETE_NOTES}/${id}`);
  }

  async fnDeleteDocument(id) {
    return await emsApi.delete(`${SUPPLIERS_SERVICE.DELETE_DOCUMENTS}/${id}`);
  }

  async fnGetSuppliersBI(payload) {
    return await emsApi.post(SUPPLIERS_SERVICE.GET_SUPPLIERS_BI, payload);
  }

  async fnGetIndustryList() {
    return await emsApi.get(`${SUPPLIERS_SERVICE.GET_INDUSTRIES_LIST}`);
  }

  async fnGetIndustryFilterList() {
    return await emsApi.get(`${SUPPLIERS_SERVICE.GET_INDUSTRIES_FILTER_LIST}`);
  }
}

Suppliers.api = new Suppliers();
export default Suppliers;
