import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import withRouter from '../../components/Common/withRouter';

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';

import { Name, Status, Date, Id } from './column';
import InputCheckbox from '../../components/Common/Form/InputCheckbox';
import addLogo from '../../assets/images/add.png';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
import ErrorModal from '../../components/Common/ErrorModal';

const UserGroups = ({ usergroup, authentication, app, ...props }) => {
  document.title = 'User Groups | EMS';
  const [selectedUserGroup, setSelectedUserGroup] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [dynamicOptionsValue, setDynamicOptionsValue] = React.useState([]);
  //delete user modal
  const [deleteModal, setDeleteModal] = React.useState(false);
  //error user modal
  const [errorModal, setErrorModal] = React.useState(false);
  let list = [];
  const [initialSettings, setInitialSettings] = useState([]);

  const handleNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_USERGROUP });
    toast(usergroup.message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-success text-white',
    });
  };

  const handleErrorNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_USERGROUP_ERROR });
    toast(usergroup.errorMessage, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-danger text-white',
    });
  };

  const userGroupForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (selectedUserGroup && selectedUserGroup.name) || '',
      description: (selectedUserGroup && selectedUserGroup.description) || '',
      status: (selectedUserGroup && selectedUserGroup.status) || '',
      settings: initialSettings,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      description: Yup.string().required('Description is required'),
      status: Yup.string().required('Please Select Status'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUserGroup = {
          id: selectedUserGroup.id,
          name: values.name,
          description: values.description,
          status: parseInt(values.status),
          settings: values.settings,
        };
        // update user
        props.actionCreator({
          type: types.UPDATE_USERGROUP,
          payload: updateUserGroup,
        });
        setIsEdit(false);
      } else {
        const newUserGroup = {
          name: values['name'],
          description: values['description'],
          status: parseInt(values['status']),
          settings: values.settings,
        };
        // save new user
        props.actionCreator({
          type: types.ADD_NEW_USERGROUP,
          payload: newUserGroup,
        });
      }
      toggle();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'User Group Name',
        accessor: 'name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                className="text-primary"
                to="#"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickEdit(userData);
                }}
                style={{
                  fontWeight: 700,
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                {cellProps.value}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        filterable: false,
        disableFilters: true,
        width: '20%',
        Cell: (cellProps) => {
          return (
            <>
              <Date {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        filterable: false,
        disableFilters: true,
        width: '20%',
        Cell: (cellProps) => {
          return (
            <>
              <Link
                to="#"
                className={`badge font-size-11 m-1 ${
                  cellProps.value === 1
                    ? 'text-success bg-success-subtle'
                    : 'text-danger bg-danger-subtle'
                }`}
              >
                {cellProps.value === 1 ? 'Active' : 'Inactive'}
              </Link>
            </>
          );
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_USERGROUP_LIST,
    });
    props.actionCreator({
      type: types.GET_MENU,
    });
    setIsEdit(false);
  }, []);

  React.useEffect(() => {
    if (usergroup?.settings.result) {
      const updatedArray = usergroup?.settings.result.map((item) => {
        return {
          name: item.name,
          fields: item.settings
            ? { ...item.settings }
            : {
                is_read: 0,
                is_write: 0,
                is_delete: 0,
                admin_menu_id: item.id,
              },
        };
      });
      setInitialSettings(updatedArray);
    }
  }, [usergroup]);

  React.useEffect(() => {
    let menu = _.sortBy(app.data, ['sort']);
    let data = [];
    for (let x of menu) {
      data[x.name.replace(/\s/g, '').toLowerCase()] =
        selectedUserGroup === undefined
          ? false
          : selectedUserGroup[x.name.replace(/\s/g, '').toLowerCase()];
      if (x.children) {
        for (let xChild of x.children) {
          data[xChild.name.replace(/\s/g, '').toLowerCase()] =
            selectedUserGroup === undefined ||
            selectedUserGroup[xChild.name.replace(/\s/g, '').toLowerCase()] ===
              undefined
              ? false
              : selectedUserGroup[xChild.name.replace(/\s/g, '').toLowerCase()];
        }
      }
    }
    setDynamicOptionsValue(data);
  }, [app, selectedUserGroup]);

  React.useEffect(() => {
    usergroup.isUserGroupSubmitted && handleNotification();
    usergroup.error &&
      usergroup.errorMessage &&
      usergroup.status != 500 &&
      usergroup.status != 403 &&
      handleErrorNotification();
  }, [usergroup]);

  const toggle = () => {
    setModal(!modal);
  };

  const getAccessId = (collection, selected) => {
    _.each(collection, function (model) {
      if (model.name && selected[navNameToLowerCase(model.name)] === true) {
        list.push(model.id);
      }
      if (model.children && model.children.length > 0) {
        getAccessId(model.children, selected);
      }
    });
    return list;
  };

  const handleUserClickEdit = (arg) => {
    props.actionCreator({
      type: types.GET_USERGROUP_SETTINGS,
      payload: {
        id: arg.id,
      },
    });

    setSelectedUserGroup({
      id: arg.id,
      name: arg.name,
      description: arg.description,
      status: parseInt(arg.status),
    });
    setIsEdit(true);
    toggle();
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = (user) => {
    setSelectedUserGroup(user);
    setDeleteModal(true);
  };

  const handleDeleteUserGroup = () => {
    props.actionCreator({
      type: types.DELETE_USERGROUP,
      payload: {
        id: selectedUserGroup.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    if (app.data) {
      const updatedArray = app.data.map((item) => {
        return {
          name: item.name,
          fields: {
            is_read: 0,
            is_write: 0,
            is_delete: 0,
            admin_menu_id: item.id,
          },
        };
      });
      setInitialSettings(updatedArray);
    }

    const menuList = _.cloneDeep(app.data);
    const userCheckedItems = {};
    menuList.forEach((item, index) => {
      if (item.parent === 0 && item.url) {
        userCheckedItems[navNameToLowerCase(item.name)] = false;
      } else if (!item.url && item.parent === 0) {
        userCheckedItems[navNameToLowerCase(item.name)] = false;
        menuList[index]['children'].forEach((subItem, subIndex) => {
          userCheckedItems[navNameToLowerCase(subItem.name)] = false;
        });
      }
    });

    setSelectedUserGroup({
      id: '',
      name: '',
      status: '',
      ...userCheckedItems,
    });
    setIsEdit(false);
    toggle();
  };

  const navNameToLowerCase = (string) => {
    return string.replace(/\s/g, '').toLowerCase();
  };

  //meta title
  document.title = 'User Groups | EMS';

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUserGroup}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ErrorModal
        component={usergroup}
        statusCode={usergroup.list.status}
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={usergroup.errorMessage}
        setErrorModal={setErrorModal}
      />
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader style={{ padding: '15px 15px 7px' }}>
              <Row>
                <Col md={7}>
                  <div className="">
                    <h5 className="card-title">User Group</h5>
                    <p className="card-title-desc">
                      This section organizes users into groups for easier
                      management. Components here could include Group Creation,
                      Group Permissions and Group Assignment.
                    </p>
                  </div>
                </Col>

                <Col md={5}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
                    <div>
                      <Link to="#" onClick={handleUserClicks}>
                        <img
                          src={addLogo}
                          alt="Add"
                          style={{ height: '40px' }}
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-4">
              <Row>
                <Col xl="12">
                  <Row>
                    <Col xl="12">
                      <TableContainer
                        columns={columns}
                        data={usergroup?.list?.data}
                        isGlobalFilter={true}
                        customPageSize={10}
                        className="table align-middle datatable dt-responsive table-check nowrap"
                      />
                      <Modal
                        isOpen={modal}
                        toggle={toggle}
                        style={{ maxWidth: '850px', width: '80%' }}
                      >
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? 'Edit User Group' : 'New User Group'}
                        </ModalHeader>
                        <ModalBody>
                          <FormikProvider value={userGroupForm}>
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                userGroupForm.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Card>
                                    <CardHeader
                                      style={{ padding: '15px 15px 7px' }}
                                    >
                                      <Row>
                                        <Col md={12}>
                                          <div className="">
                                            <h5 className="card-title">
                                              Information
                                            </h5>
                                          </div>
                                        </Col>
                                      </Row>
                                    </CardHeader>
                                    <CardBody className="p-4">
                                      <Row>
                                        <Col xs={4}>
                                          <Label className="form-label">
                                            Name
                                          </Label>
                                          <Input
                                            name="name"
                                            label="name"
                                            type="text"
                                            onChange={
                                              userGroupForm.handleChange
                                            }
                                            onBlur={userGroupForm.handleBlur}
                                            value={
                                              userGroupForm.values.name || ''
                                            }
                                            invalid={
                                              userGroupForm.touched.name &&
                                              userGroupForm.errors.name
                                                ? true
                                                : false
                                            }
                                          />
                                          {userGroupForm.touched.name &&
                                          userGroupForm.errors.name ? (
                                            <FormFeedback type="invalid">
                                              {userGroupForm.errors.name}
                                            </FormFeedback>
                                          ) : null}
                                        </Col>
                                        <Col xs={5}>
                                          <Label className="form-label">
                                            Description
                                          </Label>
                                          <Input
                                            name="description"
                                            type="text"
                                            onChange={
                                              userGroupForm.handleChange
                                            }
                                            onBlur={userGroupForm.handleBlur}
                                            value={
                                              userGroupForm.values
                                                .description || ''
                                            }
                                            invalid={
                                              userGroupForm.touched
                                                .description &&
                                              userGroupForm.errors.description
                                                ? true
                                                : false
                                            }
                                            autoComplete="description"
                                          />
                                          {userGroupForm.touched.description &&
                                          userGroupForm.errors.description ? (
                                            <FormFeedback type="invalid">
                                              {userGroupForm.errors.description}
                                            </FormFeedback>
                                          ) : null}
                                        </Col>
                                        <Col xs={3}>
                                          <Label className="form-label">
                                            Status
                                          </Label>
                                          <Input
                                            type="select"
                                            name="status"
                                            className="form-select"
                                            multiple={false}
                                            onChange={
                                              userGroupForm.handleChange
                                            }
                                            onBlur={userGroupForm.handleBlur}
                                            value={
                                              userGroupForm.values.status || '0'
                                            }
                                            invalid={
                                              userGroupForm.touched.status &&
                                              userGroupForm.errors.status
                                                ? true
                                                : false
                                            }
                                          >
                                            <option value={''}>
                                              Select Status
                                            </option>
                                            <option value={1}>Active</option>
                                            <option value={2}>Inactive</option>
                                          </Input>
                                          {userGroupForm.touched.status &&
                                          userGroupForm.errors.status ? (
                                            <FormFeedback type="invalid">
                                              {userGroupForm.errors.status}
                                            </FormFeedback>
                                          ) : null}
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={12}>
                                  <Card>
                                    <CardHeader
                                      style={{ padding: '15px 15px 7px' }}
                                    >
                                      <Row>
                                        <Col md={12}>
                                          <div className="">
                                            <h5 className="card-title">
                                              Menus
                                            </h5>
                                          </div>
                                        </Col>
                                      </Row>
                                    </CardHeader>
                                    <CardBody className="p-4">
                                      <Row>
                                        <Col xs={3}></Col>
                                        <Col xs={1}>Read</Col>
                                        <Col xs={1}>Write</Col>
                                        <Col xs={1}>Delete</Col>
                                      </Row>

                                      {userGroupForm.values.settings.map(
                                        (page, pageIndex) => (
                                          // <div key={page.url}>
                                          <Row key={pageIndex}>
                                            <Col xs={3}>{page.name}</Col>

                                            <Col
                                              xs={1}
                                              className="form-check form-switch form-switch-md mb-3"
                                            >
                                              <Input
                                                type="checkbox"
                                                name={`settings[${pageIndex}].fields.is_read`}
                                                checked={
                                                  userGroupForm.values.settings[
                                                    pageIndex
                                                  ].fields.is_read === 1
                                                }
                                                onChange={() => {
                                                  userGroupForm.handleChange({
                                                    target: {
                                                      name: `settings[${pageIndex}].fields.is_read`,
                                                      value:
                                                        userGroupForm.values
                                                          .settings[pageIndex]
                                                          .fields.is_read === 1
                                                          ? 0
                                                          : 1,
                                                    },
                                                  });
                                                }}
                                                className="form-check-input"
                                                id="formCheckRight2"
                                              />
                                            </Col>
                                            <Col
                                              xs={1}
                                              className="form-check form-switch form-switch-md mb-3"
                                            >
                                              <Input
                                                type="checkbox"
                                                name={`settings[${pageIndex}].fields.is_write`}
                                                checked={
                                                  userGroupForm.values.settings[
                                                    pageIndex
                                                  ].fields.is_write === 1
                                                }
                                                onChange={() => {
                                                  userGroupForm.handleChange({
                                                    target: {
                                                      name: `settings[${pageIndex}].fields.is_write`,
                                                      value:
                                                        userGroupForm.values
                                                          .settings[pageIndex]
                                                          .fields.is_write === 1
                                                          ? 0
                                                          : 1,
                                                    },
                                                  });
                                                }}
                                                className="form-check-input"
                                                id="formCheckRight2"
                                              />
                                            </Col>
                                            <Col
                                              xs={1}
                                              className="form-check form-switch form-switch-md mb-3"
                                            >
                                              <Input
                                                type="checkbox"
                                                name={`settings[${pageIndex}].fields.is_delete`}
                                                checked={
                                                  userGroupForm.values.settings[
                                                    pageIndex
                                                  ].fields.is_delete === 1
                                                }
                                                onChange={() => {
                                                  userGroupForm.handleChange({
                                                    target: {
                                                      name: `settings[${pageIndex}].fields.is_delete`,
                                                      value:
                                                        userGroupForm.values
                                                          .settings[pageIndex]
                                                          .fields.is_delete ===
                                                        1
                                                          ? 0
                                                          : 1,
                                                    },
                                                  });
                                                }}
                                                className="form-check-input"
                                                id="formCheckRight2"
                                              />
                                            </Col>
                                          </Row>
                                        ),
                                      )}
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-primary save-user"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </FormikProvider>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ usergroup, authentication, app }) => ({
  usergroup,
  authentication,
  app,
});

export default withRouter(
  connect(mapStateToProps, { actionCreator })(UserGroups),
);
