import { emsApi } from '../../api';
import { USER_SERVICE } from '../../api/constants';

class Authentication {
  async fnLogin(values) {
    const payload = {
      username: values.username,
      password: values.password,
    };

    return await emsApi.post(USER_SERVICE.LOGIN, payload);
  }

  async fnForgetPassword (values) {
    const payload = {
      email: values.email,
    };
    return await rfidApi.post(USER_SERVICE.REQUEST_RESET_PASSWORD, payload);
  };

  async fnResetPassword({ password, headers }) {
    const payload = {
      password,
    };
    return await emsApi.post(USER_SERVICE.CHANGE_PASSWORD, payload, {
      headers,
    });
  }
}

Authentication.api = new Authentication();
export default Authentication;
