import React, { useState } from 'react';
import { connect } from 'react-redux';

import { RESET_PASSWORD } from '../../store/auth/forgetpwd/types';
import { CLEAR_AUTH } from '../../store/authentication/types';

import { actionCreator } from '../../store';

import {
  Row,
  Col,
  Alert,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
import stEngrLogo from '../../assets/images/ste-logo.png';
import logo from '../../assets/images/pt-logo.png';

const Expired = ({
  authentication,
  forgetPassword,
  location,
  params,
  ...props
}) => {
  //meta title
  document.title = 'Reset Password | RFID';

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [token, setToken] = useState('');
  const [errorMessage, SetErrorMessage] = useState('Your password has expired');

  const passwordRules =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/;
  let navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .matches(passwordRules, {
          message:
            'Must contain 10 Characters, one Uppercase, one Lowercase, one Number and one Special Character',
        })
        .matches(/^\S*$/, 'White Spaces are not allowed')
        .required('Please Enter Your Password'),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password'),
    }),

    onSubmit: (values) => {
      // Dispatch action with token in the header
      props.actionCreator({
        type: RESET_PASSWORD,
        payload: {
          password: values.password,
          headers: {
            Authorization: token, // Attach token in the Authorization header
          },
        },
      });

      props.actionCreator({
        type: CLEAR_AUTH,
      });
    },
  });

  React.useEffect(() => {
    if (forgetPassword.resetSuccessMsg) {
      SetErrorMessage(forgetPassword.resetSuccessMsg);
      navigate('/login');
    } else if (forgetPassword.errorMessage)
      SetErrorMessage(forgetPassword.errorMessage);
    else SetErrorMessage('Password has expired.');
  }, [forgetPassword]);

  React.useEffect(() => {
    // Extract token from URL
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3 mx-auto">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/login" className="d-block auth-logo">
                        <img src={logo} alt="" height="60" />{' '}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reset Password</h5>
                      </div>
                      <div
                        className="alert alert-danger text-center my-4"
                        role="alert"
                      >
                        {errorMessage}
                      </div>

                      <Form
                        className="custom-form mt-4"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3 input-group">
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            type={passwordShow ? 'text' : 'password'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setPasswordShow(!passwordShow)}
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                            id="password-addon"
                          >
                            <i
                              className={`mdi ${!passwordShow ? 'mdi-eye-off-outline' : 'mdi-eye-outline'}`}
                            ></i>
                          </button>
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 input-group">
                          <Input
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Confirm Password"
                            type={confirmPasswordShow ? 'text' : 'password'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ''}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() =>
                              setConfirmPasswordShow(!confirmPasswordShow)
                            }
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                            id="confirmPassword-addon"
                          >
                            <i
                              className={`mdi ${!confirmPasswordShow ? 'mdi-eye-off-outline' : 'mdi-eye-outline'}`}
                            ></i>
                          </button>
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} ManpowerGroup Singapore.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication, forgetPassword }) => ({
  authentication,
  forgetPassword,
});

export default withRouter(connect(mapStateToProps, { actionCreator })(Expired));
