const ID = (cell) => {
  return cell.value ? cell.value : '';
};

const SupplierName = (cell) => {
  return cell.value ? cell.value : '';
};

const UENNo = (cell) => {
  return cell.value ? cell.value : '';
};

const Client = (cell) => {
  return cell.value ? cell.value : '';
};

const Department = (cell) => {
  return cell.value ? cell.value : '';
};

const Manager = (cell) => {
  return cell.value ? cell.value : '';
};

const Workers = (cell) => {
  return cell.value ? cell.value : '';
};

const Score = (cell) => {
  return cell.value ? cell.value : '';
};

const Name = (cell) => {
  return cell.value ? cell.value : '';
};

const Status = (cell) => {
  return cell.value === 1 ? 'Active' : 'Inactive';
};

const Date = (cell) => {
  return cell.value ? cell.value : '';
};

const OrigFile = (cell) => {
  return cell.value ? cell.value : '';
};

const Remarks = (cell) => {
  return cell.value ? cell.value : '';
};

const File = (cell) => {
  return cell.value ? cell.value : '';
};

const PMORemarks = (cell) => {
  return cell.value ? cell.value : '';
};

export {
  ID,
  SupplierName,
  UENNo,
  Client,
  Department,
  Manager,
  Workers,
  Score,
  Status,
  Date,
  Name,
  OrigFile,
  Remarks,
  File,
  PMORemarks,
};
