import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Col,
  Card,
  CardBody,
} from 'reactstrap';

import { actionCreator } from '../../../store';

const items = [
  {
    id: 1,
    icon: 'email-newsletter',
    title: 'Announcement #1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus scelerisque nisl lectus, congue accumsan lorem convallis quis. Nunc felis nulla, viverra id viverra ut, ullamcorper et mauris. Mauris imperdiet lacus dolor, non tincidunt justo ultricies sit amet. ',
  },
  {
    id: 2,
    icon: 'newspaper',
    title: 'Announcement #2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus scelerisque nisl lectus, congue accumsan lorem convallis quis. Nunc felis nulla, viverra id viverra ut, ullamcorper et mauris. Mauris imperdiet lacus dolor, non tincidunt justo ultricies sit amet. ',
  },
  {
    id: 3,
    icon: 'newspaper-plus',
    title: 'Announcement #3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus scelerisque nisl lectus, congue accumsan lorem convallis quis. Nunc felis nulla, viverra id viverra ut, ullamcorper et mauris. Mauris imperdiet lacus dolor, non tincidunt justo ultricies sit amet. ',
  },
];

const Announcements = ({ dashboard }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div className="text-center p-2">
          <i className={'mdi mdi-' + item.icon + ' widget-box-1-icon'}></i>
          <h4 className="mt-3 lh-base fw-normal text-white">
            <b>{item['title']}</b>
          </h4>
          <p className="text-white-50 font-size-13"> {item['description']} </p>
          <Link className="btn btn-light btn-sm">
            View details <i className="mdi mdi-arrow-right ms-1"></i>
          </Link>
        </div>
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card className="bg-primary text-white shadow-primary card-h-100">
          <CardBody>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className="pb-4"
            >
              {slides}
            </Carousel>
            <CarouselIndicators
              tag="button"
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
              className="carousel-indicators-rounded m-0"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  dashboard,
});

export default connect(mapStateToProps, { actionCreator })(Announcements);
