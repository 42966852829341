import { combineReducers } from 'redux';

// Front
import layout from './layout/reducer';

// Authentication
import app from './app/reducer';
import authentication from './authentication/reducer';
import account from './auth/register/reducer';
import forgetPassword from './auth/forgetpwd/reducer';
import profile from './auth/profile/reducer';
import dashboard from './dashboard/reducer';
import user from './user/reducer';
import usergroup from './user-group/reducer';
import suppliers from './suppliers/reducer';
import clients from './clients/reducer';
import workers from './workers/reducer';
import requisition from './requisition/reducer';
import clientfields from './client-fields/reducer';
import operations from './operations/reducer';
import joborder from './job-order/reducer';
import document from './document/reducer';
import configuration from './configuration/reducer';
import audittrail from './audit-trail/reducer';
import reports from './reports/reducer';
import announcements from './announcements/reducer';

const rootReducer = combineReducers({
  app,
  layout,
  authentication,
  account,
  clientfields,
  forgetPassword,
  profile,
  dashboard,
  user,
  usergroup,
  suppliers,
  clients,
  workers,
  requisition,
  operations,
  joborder,
  document,
  configuration,
  audittrail,
  reports,
  announcements,
});

export default rootReducer;
