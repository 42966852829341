import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actionCreator, types } from '../../store';

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import DeleteModal from '../../components/Common/DeleteModal';
import ErrorModal from '../../components/Common/ErrorModal';
import { Username, Email, RowData } from './column';
import TableContainer from '../../components/Common/TableContainer';
import addLogo from '../../assets/images/add.png';

const Users = ({
  user,
  usergroup,
  authentication,
  clients,
  suppliers,
  clientfields,
  ...props
}) => {
  const [passwordShow, setPasswordShow] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const passwordRules =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/;
  const [dynamicOptionsValue, setDynamicOptionsValue] = React.useState({});
  const [dynamicOptionsValue2, setDynamicOptionsValue2] = React.useState({});
  const [errorModal, setErrorModal] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState();
  const [userRules, setUserRules] = React.useState([]);

  const [rulesSelectedClient, setRulesSelectedClient] = React.useState({});
  const [rulesSelectedSupplier, setRulesSelectedSupplier] = React.useState({});
  const [rulesSelectedDepartment, setRulesSelectedDepartment] = React.useState(
    {},
  );

  React.useEffect(() => {
    const userGroupId = authentication?.data?.data?.user_group_id;

    if (isEdit) {
      setDynamicOptionsValue({
        name: (selectedUser && selectedUser.name) || '',
        username: (selectedUser && selectedUser.username) || '',
        email: (selectedUser && selectedUser.email) || '',
        // Conditionally include password based on user_group_id
        password:
          userGroupId !== 1
            ? (selectedUser && selectedUser.password) || ''
            : '',
        user_group_id: (selectedUser && selectedUser.user_group_id) || '',
        is_active: (selectedUser && selectedUser.is_active) || '',
      });

      setDynamicOptionsValue2({
        name: Yup.string().required('Name is required'),
        username: Yup.string().required('Username is required'),
        email: Yup.string().email().required('Email is required'),
        // Conditionally include password validation based on user_group_id
        password:
          userGroupId === 1
            ? Yup.string()
                .matches(passwordRules, {
                  message:
                    'Must contain 10 Characters, one Uppercase, one Lowercase, one Number and one Special Character',
                })
                .matches(/^\S*$/, 'White Spaces are not allowed')
            : Yup.string(),
        user_group_id: Yup.string().required('Please Select User Group'),
        is_active: Yup.string().required('Please Select Status'),
      });
    } else {
      setDynamicOptionsValue({
        username: (selectedUser && selectedUser.username) || '',
        email: (selectedUser && selectedUser.email) || '',
        // Conditionally include password based on user_group_id
        password:
          userGroupId === 1
            ? (selectedUser && selectedUser.password) || ''
            : '',
        user_group_id: (selectedUser && selectedUser.user_group_id) || '',
        is_active: (selectedUser && selectedUser.is_active) || '',
      });

      setDynamicOptionsValue2({
        username: Yup.string().required('Username is required'),
        email: Yup.string().email().required('Email is required'),
        // Conditionally include password validation based on user_group_id
        password:
          userGroupId === 1
            ? Yup.string()
                .matches(passwordRules, {
                  message:
                    'Must contain 10 Characters, one Uppercase, one Lowercase, one Number and one Special Character',
                })
                .matches(/^\S*$/, 'White Spaces are not allowed')
                .required('Password is required')
            : Yup.string(),
        user_group_id: Yup.string().required('Please Select User Group'),
        is_active: Yup.string().required('Please Select Status'),
      });
    }
  }, [isEdit, selectedUser, authentication]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: dynamicOptionsValue,
    validationSchema: Yup.object(dynamicOptionsValue2),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: selectedUser.id,
          name: values.name,
          username: values.username,
          email: values.email,
          password: values.password,
          user_group_id: parseInt(values.user_group_id),
          is_active: parseInt(values.is_active),
          rules: userRules,
        };
        // update user
        props.actionCreator({
          type: types.UPDATE_USER,
          payload: updateUser,
        });
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newUser = {
          name: values['name'],
          username: values['username'],
          email: values['email'],
          password: values['password'],
          user_group_id: parseInt(values['user_group_id']),
          is_active: parseInt(values['is_active']),
          rules: userRules,
        };

        // save new user
        props.actionCreator({
          type: types.ADD_NEW_USER,
          payload: newUser,
        });
        validation.resetForm();
      }
      toggle();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'User ID',
        accessor: 'id',
        width: '20%',
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                className="text-primary"
                to="#"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
                style={{
                  fontWeight: 700,
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                {cellProps.value}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Username',
        accessor: 'username',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Link
                className="text-primary"
                to="#"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
                style={{
                  fontWeight: 700,
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                <Username {...cellProps} />{' '}
              </Link>
            </>
          );
        },
      },
      {
        Header: 'User Group',
        accessor: 'group_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <RowData {...cellProps} />;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: 'Clients',
        accessor: 'clients',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <RowData {...cellProps} />;
        },
      },
      {
        Header: 'Suppliers',
        accessor: 'suppliers',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <RowData {...cellProps} />;
        },
      },
      {
        Header: 'Departments',
        accessor: 'departments',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <RowData {...cellProps} />;
        },
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        filterable: false,
        disableFilters: true,
        width: '20%',
        Cell: (cellProps) => {
          return (
            <>
              <Link
                to="#"
                className={`badge font-size-11 m-1 ${
                  cellProps.value === 1
                    ? 'text-success bg-success-subtle'
                    : 'text-danger bg-danger-subtle'
                }`}
              >
                {cellProps.value === 1 ? 'Active' : 'Inactive'}
              </Link>
            </>
          );
        },
      },
    ],
    [],
  );

  //NOTIFICATION
  const handleNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_USER });
    toast(user.message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-success text-white',
    });
  };

  const handleErrorNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_USER_ERROR });
    toast(user.errorMessage, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-danger text-white',
    });
  };

  React.useEffect(() => {
    user.isUserSubmitted && handleNotification();

    user.error &&
      user.errorMessage &&
      user.status != 500 &&
      user.status != 403 &&
      handleErrorNotification();

    if (user.rules) {
      setUserRules(user.rules);
    }
  }, [user]);

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_USER_LIST,
    });
    props.actionCreator({
      type: types.GET_USERGROUP_LIST,
    });
    props.actionCreator({
      type: types.GET_SUPPLIER_LIST_ALL,
    });

    setIsEdit(false);

    return () => {
      setSelectedUser({});
      setIsEdit(false);
      setUserRules([]);
      setSelectedType();
      setRulesSelectedClient();
      setRulesSelectedSupplier();
      setRulesSelectedDepartment();
    };
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    setUserRules([]);
    setSelectedType();
    setRulesSelectedClient();
    setRulesSelectedSupplier();
    setRulesSelectedDepartment();

    props.actionCreator({
      type: types.GET_USER_RULES,
      payload: {
        id: arg.id,
      },
    });

    const user = arg;

    setSelectedUser({
      id: user.id,
      name: user.name,
      username: user.username,
      email: user.email,
      user_group_id: user.user_group_id,
      is_active: user.is_active,
    });

    setIsEdit(true);

    toggle();
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete user modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (user) => {
    setSelectedUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    props.actionCreator({
      type: types.DELETE_USER,
      payload: {
        id: selectedUser.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setSelectedUser({});
    setIsEdit(false);
    setUserRules([]);
    setSelectedType();
    setRulesSelectedClient();
    setRulesSelectedSupplier();
    setRulesSelectedDepartment();

    toggle();
  };

  const addHandler = () => {
    if (selectedType == 'client' && !rulesSelectedClient) {
      toast('Please select a Client', {
        position: 'top-right',
        hideProgressBar: true,
        className: 'bg-danger text-white',
      });
      return false;
    } else if (selectedType == 'supplier' && !rulesSelectedSupplier) {
      toast('Please select a Supplier', {
        position: 'top-right',
        hideProgressBar: true,
        className: 'bg-danger text-white',
      });
      return false;
    } else if (selectedType == 'department' && !rulesSelectedDepartment) {
      toast('Please select a Client and Department', {
        position: 'top-right',
        hideProgressBar: true,
        className: 'bg-danger text-white',
      });
      return false;
    }
    let sourceValue = '';
    let sourceId = '';
    let sourceClientDeptValue = '';
    let sourceClientDeptId = '';
    if (selectedType == 'client' && rulesSelectedClient) {
      sourceValue = rulesSelectedClient.label;
      sourceId = rulesSelectedClient.id;
    } else if (selectedType == 'supplier' && rulesSelectedSupplier) {
      sourceValue = rulesSelectedSupplier.label;
      sourceId = rulesSelectedSupplier.id;
    } else if (selectedType == 'department' && rulesSelectedDepartment) {
      sourceClientDeptValue = rulesSelectedClient.label;
      sourceClientDeptId = rulesSelectedClient.id;
      sourceValue = rulesSelectedDepartment.label;
      sourceId = rulesSelectedDepartment.id;
    }

    if (userRules.some((item) => parseInt(item.source_id) === parseInt(sourceId))) {
      toast('Please select a different Source Name', {
        position: 'top-right',
        hideProgressBar: true,
        className: 'bg-danger text-white',
      });
      return false;
    }

    setUserRules((prevRules) => {
      let newRules = [...prevRules];
      const newID = newRules.length + 1;

      if (selectedType == 'department') {
        if (!userRules.some((item) => item.source === sourceClientDeptValue)) {
          newRules.push({
            id: '',
            type: 'client',
            source: sourceClientDeptValue,
            source_id: sourceClientDeptId,
          });
        }

        newRules.push({
          id: '',
          type: 'department',
          source: sourceValue,
          source_id: sourceId,
          client_name: sourceClientDeptValue,
          client_id: sourceClientDeptId,
        });
      } else {
        newRules.push({
          id: '',
          type: selectedType,
          source: sourceValue,
          source_id: sourceId,
        });
      }
      return newRules;
    });
  };

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ErrorModal
        component={user}
        statusCode={user.status}
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={user.errorMessage}
        setErrorModal={setErrorModal}
      />
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader style={{ padding: '15px 15px 7px' }}>
              <Row>
                <Col md={7}>
                  <div className="">
                    <h5 className="card-title">Users</h5>
                    <p className="card-title-desc">
                      This section focuses on user management. It includes User
                      Profiles, Permissions and Activity Logs.
                    </p>
                  </div>
                </Col>

                <Col md={5}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
                    <div>
                      <Link
                        to="#"
                        //onClick={handleDocumentClick}
                        onClick={handleUserClicks}
                      >
                        <img
                          src={addLogo}
                          alt="Add"
                          style={{ height: '40px' }}
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-4">
              <Row>
                <Col xl="12">
                  <Row>
                    <Col xl="12">
                      <TableContainer
                        columns={columns}
                        data={user.data}
                        isGlobalFilter={true}
                        customPageSize={10}
                        className="table align-middle datatable dt-responsive table-check nowrap"
                      />

                      <Modal
                        isOpen={modal}
                        toggle={toggle}
                        style={{ maxWidth: '850px', width: '80%' }}
                      >
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? 'Edit User' : 'New User'}
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <Col xs={12}>
                                <Card>
                                  <CardHeader
                                    style={{ padding: '15px 15px 7px' }}
                                  >
                                    <Row>
                                      <Col md={12}>
                                        <div className="">
                                          <h5 className="card-title">
                                            Basic Information
                                          </h5>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardHeader>
                                  <CardBody className="p-4">
                                    <Row>
                                      <Col xs={3}>
                                        <Label className="form-label">
                                          Name
                                        </Label>
                                        <Input
                                          name="name"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.name || ''}
                                          invalid={
                                            validation.touched.name &&
                                            validation.errors.name
                                              ? true
                                              : false
                                          }
                                          autoComplete="name"
                                        />
                                        {validation.touched.name &&
                                        validation.errors.name ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.name}
                                          </FormFeedback>
                                        ) : null}
                                      </Col>
                                      <Col xs={3}>
                                        <Label className="form-label">
                                          Username
                                        </Label>
                                        <Input
                                          name="username"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.username || ''
                                          }
                                          invalid={
                                            validation.touched.username &&
                                            validation.errors.username
                                              ? true
                                              : false
                                          }
                                          autoComplete="username"
                                        />
                                        {validation.touched.username &&
                                        validation.errors.username ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.username}
                                          </FormFeedback>
                                        ) : null}
                                      </Col>
                                      <Col xs={3}>
                                        <Label className="form-label">
                                          Email
                                        </Label>
                                        <Input
                                          name="email"
                                          label="Email"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.email || ''}
                                          invalid={
                                            validation.touched.email &&
                                            validation.errors.email
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.email &&
                                        validation.errors.email ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.email}
                                          </FormFeedback>
                                        ) : null}
                                      </Col>
                                      <Col xs={3}>
                                        <Label className="form-label">
                                          User Group
                                        </Label>
                                        <Input
                                          type="select"
                                          name="user_group_id"
                                          className="form-select"
                                          multiple={false}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.user_group_id ||
                                            ''
                                          }
                                          invalid={
                                            validation.touched.user_group_id &&
                                            validation.errors.user_group_id
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value={''}>
                                            Select Current User Group
                                          </option>
                                          {usergroup.list.data
                                            .filter(
                                              (uglist) => uglist.status === 1,
                                            )
                                            .map((item, index) => (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                        </Input>
                                        {validation.touched.user_group_id &&
                                        validation.errors.user_group_id ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.user_group_id}
                                          </FormFeedback>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row className="pt-4">
                                      {authentication?.data?.data
                                        ?.user_group_id === 1 && (
                                        <Col xs={3}>
                                          <div>
                                            <Label className="form-label">
                                              Password
                                            </Label>
                                            <div className="mb-3 input-group">
                                              <Input
                                                name="password"
                                                label="Password"
                                                type={
                                                  passwordShow
                                                    ? 'text'
                                                    : 'password'
                                                }
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.password ||
                                                  ''
                                                }
                                                invalid={
                                                  validation.touched.password &&
                                                  validation.errors.password
                                                    ? true
                                                    : false
                                                }
                                                autoComplete="new-password"
                                              />
                                              <button
                                                onClick={() =>
                                                  setPasswordShow(!passwordShow)
                                                }
                                                className="btn btn-light shadow-none ms-0"
                                                type="button"
                                                id="password-addon"
                                              >
                                                <i
                                                  className={`mdi ${
                                                    !passwordShow
                                                      ? 'mdi-eye-off-outline'
                                                      : 'mdi-eye-outline'
                                                  }`}
                                                ></i>
                                              </button>
                                              {validation.touched.password &&
                                              validation.errors.password ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.password}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </div>
                                        </Col>
                                      )}
                                      <Col xs={3}>
                                        <Label className="form-label">
                                          Status
                                        </Label>
                                        <Input
                                          type="select"
                                          name="is_active"
                                          className="form-select"
                                          multiple={false}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.is_active || '0'
                                          }
                                          invalid={
                                            validation.touched.is_active &&
                                            validation.errors.is_active
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value={''}>
                                            Select Status
                                          </option>
                                          <option value={1}>Active</option>
                                          <option value={2}>Inactive</option>
                                        </Input>
                                        {validation.touched.is_active &&
                                        validation.errors.is_active ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.is_active}
                                          </FormFeedback>
                                        ) : null}
                                      </Col>
                                      <Col xs={3}></Col>
                                      <Col xs={3}></Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>

                            {/*  */}

                            <Row>
                              <Col xs={12}>
                                <Card>
                                  <CardHeader
                                    style={{ padding: '15px 15px 7px' }}
                                  >
                                    <Row>
                                      <Col md={12}>
                                        <div className="">
                                          <h5 className="card-title">Rules</h5>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardHeader>
                                  <CardBody className="p-4">
                                    <Row>
                                      <Col xs={3}>
                                        <Label className="form-label">
                                          User Rules
                                        </Label>
                                        <Input
                                          type="select"
                                          name="rules-type"
                                          className="form-select"
                                          multiple={false}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setSelectedType(value);
                                            setRulesSelectedClient();
                                            setRulesSelectedSupplier();
                                            setRulesSelectedDepartment();
                                          }}
                                        >
                                          <option value={''}>
                                            Select Type
                                          </option>
                                          <option value="client">Client</option>
                                          <option value="supplier">
                                            Supplier
                                          </option>
                                          <option value="department">
                                            Department
                                          </option>
                                        </Input>
                                      </Col>
                                      {selectedType == 'client' && (
                                        <Col xs={3}>
                                          <Label className="form-label">
                                            Client
                                          </Label>
                                          <Input
                                            type="select"
                                            name="rules-client"
                                            className="form-select"
                                            multiple={false}
                                            onChange={async (e) => {
                                              const value = e.target.value;

                                              setRulesSelectedClient({
                                                label:
                                                  e.target.options[
                                                    e.target.selectedIndex
                                                  ].text,
                                                id: value,
                                              });
                                            }}
                                          >
                                            <option value={''}>
                                              Select Client
                                            </option>
                                            {clients.data
                                              // .filter((list) => list.status === 1)
                                              .map((item, index) => (
                                                <option
                                                  key={index}
                                                  value={item.client_id}
                                                >
                                                  {item.client_name}
                                                </option>
                                              ))}
                                          </Input>
                                        </Col>
                                      )}
                                      {selectedType == 'supplier' && (
                                        <Col xs={3}>
                                          <Label className="form-label">
                                            Supplier
                                          </Label>
                                          <Input
                                            type="select"
                                            name="rules-supplier"
                                            className="form-select"
                                            multiple={false}
                                            onChange={async (e) => {
                                              const value = e.target.value;
                                              setRulesSelectedSupplier({
                                                label:
                                                  e.target.options[
                                                    e.target.selectedIndex
                                                  ].text,
                                                id: value,
                                              });
                                            }}
                                          >
                                            <option value={''}>
                                              Select Supplier
                                            </option>
                                            {suppliers.data
                                              // .filter((list) => list.status === 1)
                                              .map((item, index) => (
                                                <option
                                                  key={index}
                                                  value={item.id}
                                                >
                                                  {item.supplier_name}
                                                </option>
                                              ))}
                                          </Input>
                                        </Col>
                                      )}

                                      {selectedType == 'department' && (
                                        <>
                                          <Col xs={3}>
                                            <Label className="form-label">
                                              Client
                                            </Label>
                                            <Input
                                              type="select"
                                              name="rules-client"
                                              className="form-select"
                                              multiple={false}
                                              onChange={async (e) => {
                                                const value = e.target.value;
                                                await props.actionCreator({
                                                  type: types.GET_DEPARTMENT_OPTIONS,
                                                  payload: {
                                                    type: 'department',
                                                    client_id: value,
                                                  },
                                                });

                                                setRulesSelectedClient({
                                                  label:
                                                    e.target.options[
                                                      e.target.selectedIndex
                                                    ].text,
                                                  id: value,
                                                });
                                                setRulesSelectedDepartment();
                                              }}
                                            >
                                              <option value={''}>
                                                Select Client
                                              </option>
                                              {clients.data
                                                // .filter((list) => list.status === 1)
                                                .map((item, index) => (
                                                  <option
                                                    key={index}
                                                    value={item.client_id}
                                                  >
                                                    {item.client_name}
                                                  </option>
                                                ))}
                                            </Input>
                                          </Col>

                                          <Col xs={3}>
                                            <Label className="form-label">
                                              Department
                                            </Label>
                                            <Input
                                              type="select"
                                              name="rules-department"
                                              className="form-select"
                                              multiple={false}
                                              value={
                                                rulesSelectedDepartment?.id ||
                                                ''
                                              }
                                              onChange={async (e) => {
                                                const value = e.target.value;
                                                setRulesSelectedDepartment({
                                                  label:
                                                    e.target.options[
                                                      e.target.selectedIndex
                                                    ].text,
                                                  id: value,
                                                });
                                              }}
                                            >
                                              <option value={''}>
                                                Select Department
                                              </option>
                                              {clientfields.department.data
                                                // .filter((list) => list.status === 1)
                                                .map((item, index) => (
                                                  <option
                                                    key={index}
                                                    value={item.id}
                                                  >
                                                    {item.label}
                                                  </option>
                                                ))}
                                            </Input>
                                          </Col>
                                        </>
                                      )}

                                      <Col
                                        xs={3}
                                        className="d-flex align-items-end"
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-info"
                                          onClick={addHandler}
                                          disabled={!selectedType}
                                        >
                                          Add Rule
                                        </button>
                                      </Col>
                                      <Col xs={3}></Col>
                                    </Row>
                                    <Row className="pt-4">
                                      <Col xs={7}>
                                        <Card>
                                          <CardHeader
                                            style={{ padding: '15px 15px 7px' }}
                                          >
                                            <Row>
                                              <Col md={6}>
                                                <div className="">
                                                  <h5 className="card-title">
                                                    Rules Applied
                                                  </h5>
                                                </div>
                                              </Col>

                                              <Col md={6}>
                                                <div className="d-flex flex-wrap align-items-center justify-content-end gap-2"></div>
                                              </Col>
                                            </Row>
                                          </CardHeader>
                                          <CardBody className="p-4">
                                            <Row>
                                              <Col xs={12}>
                                                {userRules.length == 0 ? (
                                                  <Row>
                                                    <Col xs={6}>
                                                      <Label className="form-label">
                                                        No records found.
                                                      </Label>
                                                    </Col>
                                                  </Row>
                                                ) : (
                                                  <Row>
                                                    <Col xs={4}>
                                                      <Label className="form-label">
                                                        Type
                                                      </Label>
                                                    </Col>
                                                    <Col xs={4}>
                                                      <Label className="form-label">
                                                        Source Name
                                                      </Label>
                                                    </Col>
                                                    <Col xs={4}></Col>
                                                  </Row>
                                                )}

                                                {userRules &&
                                                  userRules.map(
                                                    (field, index1) => (
                                                      <Row
                                                        key={index1}
                                                        className="mb-2"
                                                      >
                                                        <Col xs={4}>
                                                          <p className="">
                                                            {field.type}
                                                          </p>
                                                        </Col>
                                                        <Col xs={4}>
                                                          {field.type ==
                                                            'department' && (
                                                            <p className="">
                                                              {field.source} (<small>
                                                              {
                                                                field.client_name
                                                              }
                                                              </small>)
                                                            </p>
                                                          )}
                                                          {field.type !=
                                                            'department' && (
                                                            <p className="">
                                                              {field.source}
                                                            </p>
                                                          )}
                                                        </Col>
                                                        <Col
                                                          xs={4}
                                                          className="text-center"
                                                        >
                                                          <Link
                                                            className="text-danger"
                                                            to="#"
                                                            onClick={() => {
                                                              const newFields =
                                                                [...userRules];
                                                              newFields.splice(
                                                                index1,
                                                                1,
                                                              );
                                                              setUserRules(
                                                                newFields,
                                                              );
                                                            }}
                                                          >
                                                            <i
                                                              className="mdi mdi-delete font-size-24"
                                                              id="deletetooltip"
                                                            ></i>
                                                          </Link>
                                                        </Col>
                                                      </Row>
                                                    ),
                                                  )}
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            {/*  */}
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-primary save-user"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({
  user,
  usergroup,
  authentication,
  clients,
  suppliers,
  clientfields,
}) => ({
  user,
  usergroup,
  authentication,
  clients,
  suppliers,
  clientfields,
});
export default connect(mapStateToProps, { actionCreator })(Users);
