import { emsApi } from '..';
import { ANNOUNCMENTS_SERVICE } from '../constants';

class Announcements {
  async fnGetAnnouncementListAll(payload) {
    return await emsApi.post(ANNOUNCMENTS_SERVICE.GET_ANNOUNCMENTS_LIST_ALL, payload);
  }

  async fnGetAnnouncementList(values) {
    return await emsApi.get(
      `${ANNOUNCMENTS_SERVICE.GET_ANNOUNCMENTS_LIST}/${values}`,
    );
  }

  async fnAddNewAnnouncement(values) {
    return await emsApi.post(ANNOUNCMENTS_SERVICE.ADD_NEW_ANNOUNCMENTS, values);
  }

  async fnUpdateAnnouncement(values) {
    return await emsApi.patch(
      `${ANNOUNCMENTS_SERVICE.UPDATE_ANNOUNCMENTS}/${values.id}`,values);
  }

  async fnDeleteAnnouncement(id) {
    return await emsApi.delete(`${ANNOUNCMENTS_SERVICE.DELETE_ANNOUNCMENTS}/${id}`);
  }

}

Announcements.api = new Announcements();
export default Announcements;
