import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import Dropzone from 'react-dropzone';
import { useParams, useNavigate } from 'react-router-dom';
import addLogo from '../../assets/images/add.png';
import Select from 'react-select';
import { multiSelectStyle } from '../../components/Common/MultiSelectStyles';

import { ToastContainer, toast } from 'react-toastify';

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Collapse,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ID, OrigFile, Remarks, File } from './column';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';

import classnames from 'classnames';
import { DELETE_DOCUMENT } from '../../store/actions';

const SuppilersList = ({
  app,
  suppliers,
  route,
  document,
  animatedComponents,
  ...props
}) => {
  document.title = 'Suppliers List | Tapfin';
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [selectedSuppiler, setSelectedSupplier] = React.useState({});
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  //Forms
  const [addresses, setAddresses] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);

  //Dropzone
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);

  //documents
  const [documentsCol, setDocumentsCol] = React.useState([]);

  //Tabs
  const [headerPillsTab, setHeaderPillsTab] = useState('1');

  const [dynamicOptionsValue, setDynamicOptionsValue] = React.useState({});
  const [dynamicOptionsValue2, setDynamicOptionsValue2] = React.useState({});

  const [documentDynamicOptionsValue, setDocumentDynamicOptionsValue] =
    React.useState([]);
  const [documentDynamicOptionsValue2, setDocumentDynamicOptionsValue2] =
    React.useState([]);
  const [documentListDynamicOptionsValue, setDocumentListDynamicOptionsValue] =
    React.useState([]);

  //Get ID and Check Add/Edit
  const { id } = useParams();

  const breadcrumbItem = isEdit && id ? 'Manage Supplier' : 'Add New Supplier';

  // Check and remove any empty objects from the arrays
  const cleanArray = (arr) =>
    arr.filter((item) => Object.values(item).some((value) => value !== ''));

  const cleanObject = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== ''),
    );
  };

  //validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: dynamicOptionsValue,
    validationSchema: Yup.object(dynamicOptionsValue2),
    onSubmit: (values) => {
      if (isEdit) {
        values.id = id;
        // update suppliers
        props.actionCreator({
          type: types.UPDATE_SUPPLIER,
          payload: values,
        });
      } else {
        // add new suppliers
        props.actionCreator({
          type: types.ADD_NEW_SUPPLIER,
          payload: values,
        });
        navigate('/suppliers');
      }
    },
  });

  // validation document
  const validationDocument = useFormik({
    enableReinitialize: true,
    initialValues: documentDynamicOptionsValue,
    validationSchema: Yup.object(documentDynamicOptionsValue2),
    onSubmit: (values) => {
      if (selectedFiles.length === 0) {
        setShowFileError(true);
        return true;
      }
      documentsCol.push({
        document_name: values.document_name,
        owner_remarks: values.owner_remarks,
        pmo_remarks: '',
        file: selectedFiles,
      });
      validationDocumentList.setFieldValue(`documents`, documentsCol);
      validationDocument.resetForm();
      setSelectedFiles([]);
      setModal(false);
    },
  });

  // validation document
  const validationDocumentList = useFormik({
    enableReinitialize: true,
    initialValues: documentListDynamicOptionsValue,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const formData = new FormData();

      values.documents.forEach((value, index) => {
        if (value?.file?.name) {
          formData.append(`files_${index}`, value.file, value.file.name);
        }
      });

      formData.append('id', id);
      formData.append('type', 'supplier');
      formData.append('documents', JSON.stringify(values.documents));

      // update document
      props.actionCreator({
        type: types.UPDATE_DOCUMENT,
        payload: formData,
        module: 'supplier',
      });

      //validationDocumentList.resetForm();
    },
  });

  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  const addMoreAddressHandler = () => {
    const finalAddress = validation.values.addresses;
    finalAddress.push({
      id: '',
      building_name: '',
      unit: '',
      postal_code: '',
      address: '',
      city: '',
      country: '',
    });
    setAddresses(finalAddress);
  };

  const removeAddressHandler = (index) => {
    if (addresses && addresses.length > 0) {
      setAddresses((addresses) => addresses.filter((_, i) => i !== index));
      if (
        validation.values?.addresses &&
        validation.values.addresses.length > 0
      ) {
        validation.setFieldValue(
          `addresses`,
          validation.values.addresses.filter((_, i) => i !== index),
        );
      }
    }
  };

  const addMoreContactHandler = () => {
    const finalContact = validation.values.contacts;
    finalContact.push({
      id: '',
      name: '',
      department: '',
      position: '',
      email: '',
      mobile: '',
      notes: '',
    });
    setContacts(finalContact);
  };

  const removeSelectedContactHandler = (index) => {
    if (contacts && contacts.length > 0) {
      setContacts((contacts) => contacts.filter((_, i) => i !== index));
      if (
        validation.values?.contacts &&
        validation.values.contacts.length > 0
      ) {
        validation.setFieldValue(
          `contacts`,
          validation.values.contacts.filter((_, i) => i !== index),
        );
      }
    }
  };

  const addMoreNotesHandler = () => {
    const finalNotes = validation.values.notes;
    finalNotes.push({
      note: '',
    });
    setNotes(finalNotes);
  };

  const removeSelectedNotesHandler = (index) => {
    if (notes && notes.length > 0) {
      setNotes((notes) => notes.filter((_, i) => i !== index));
      if (validation.values?.notes && validation.values.notes.length > 0) {
        validation.setFieldValue(
          `notes`,
          validation.values.notes.filter((_, i) => i !== index),
        );
      }
    }
  };

  const removeSelectedDocumentHandler = (index) => {
    if (documentsCol && documentsCol.length > 0) {
      setDocumentsCol((document) => document.filter((_, i) => i !== index));
      if (
        validationDocumentList.values?.documents &&
        validationDocumentList.values.documents.length > 0
      ) {
        validationDocumentList.setFieldValue(
          `documents`,
          validationDocumentList.values.documents.filter((_, i) => i !== index),
        );
      }
    }
  };

  const handleIndustriesClick = (selected) => {
    let selectedData = [];
    for (let row of selected) {
      selectedData.push({
        label: row.value,
        value: row.value,
      });
    }

    setSelectedIndustries(selectedData);
  };

  const handleNotification = async (message) => {
    toast(message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-success text-white',
    });

    clearSupplierSaveMessage();
    clearDocumentSaveMessage();
  };

  const clearDocumentSaveMessage = async () => {
    await props.actionCreator({
      type: types.CLEAR_DOCUMENT_SAVE_MESSAGE,
    });
  };

  const handleErrorNotification = async (message) => {
    toast(message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-danger text-white',
    });
  };

  const clearSupplierSaveMessage = async () => {
    await props.actionCreator({
      type: types.CLEAR_SUPPLIER_SAVE_MESSAGE,
    });
  };

  React.useEffect(() => {
    suppliers.isSubmitted &&
      suppliers.actionReturnData?.message &&
      handleNotification(suppliers.actionReturnData.message);
    suppliers.error &&
      suppliers.isSubmitted === false &&
      suppliers.errorMessage &&
      handleErrorNotification(suppliers.errorMessage);
  }, [suppliers.actionReturnData.message]);

  //runs after useEffect []
  React.useEffect(() => {
    if (isEdit && suppliers?.data) {
      const supplierData = suppliers.data[0]?.supplier;
      const addressData = suppliers.data[0]?.addresses || [];
      const contactData = suppliers.data[0]?.contacts || [];
      const noteData = suppliers.data[0]?.notes || [];

      console.log('supplierDatasupplierData', supplierData);

      setDynamicOptionsValue({
        supplier_name: supplierData?.supplier_name || '',
        uen_no: supplierData?.uen_no || '',
        industries:
          supplierData !== undefined && supplierData?.industries !== undefined
            ? JSON.parse(supplierData.industries)
            : [],
        addresses: addressData,
        contacts: contactData,
        notes: noteData,
      });

      // Update the state for addresses, contacts, and notes
      setAddresses(addressData);
      setContacts(contactData);
      setNotes(noteData);

      const selectedDocuments = {
        documents: document.data,
      };

      setDocumentsCol(document.data);
      setDocumentListDynamicOptionsValue({
        documents: selectedDocuments.documents,
      });

      let selectedDefaultIndustries = [];
      if (
        supplierData !== undefined &&
        supplierData?.industries !== undefined &&
        supplierData?.industries != 'null'
      ) {
        for (let row of supplierData?.industries
          ? JSON.parse(supplierData.industries)
          : []) {
          selectedDefaultIndustries.push({
            label: row.industry,
            value: row.industry,
          });
        }
      }

      setSelectedIndustries(selectedDefaultIndustries);
    }
  }, [suppliers, isEdit, document]);

  React.useEffect(() => {
    document.isSubmitted &&
      document.actionReturnData?.message &&
      handleNotification(document.actionReturnData.message);
    document.error &&
      document.isSubmitted === false &&
      document.errorMessage &&
      handleErrorNotification(document.errorMessage);

    if (isEdit) {
      setDocumentsCol(document.data);
      setDocumentListDynamicOptionsValue({ documents: document.data });
    }
  }, [document.actionReturnData.message]);

  React.useEffect(() => {
    const edit = location.pathname.includes('/edit');
    setIsEdit(edit);
    if (edit) {
      props.actionCreator({
        type: types.GET_SUPPLIER_LIST,
        payload: { id: id },
      });

      props.actionCreator({
        type: types.GET_DOCUMENT_LIST,
        payload: {
          type: 'supplier',
          source_id: id,
        },
      });
    }

    props.actionCreator({
      type: types.GET_INDUSTRY_LIST,
    });

    setDynamicOptionsValue({
      supplier_name: '',
      uen_no: '',
      industries: [],
      addresses: [],
      contacts: [],
      notes: [],
    });
    setDynamicOptionsValue2({
      supplier_name: Yup.string().required('Supplier Name is required'),
      uen_no: Yup.string().required('UEN No. is required'),
      // industries: Yup.array()
      //   .min(1, 'Select at least one industries')
      //   .required('Industries is required'),
      addresses: Yup.array(
        Yup.object({
          building_name: Yup.string().required('Enter Building Name'),
          unit: Yup.string().required('Enter Unit'),
          postal_code: Yup.string().required('Enter Postal Code'),
          address: Yup.string().required('Enter Address'),
          city: Yup.string().required('Enter City'),
          country: Yup.string().required('Enter Country'),
        }),
      ),
      contacts: Yup.array(
        Yup.object({
          name: Yup.string().required('Name is required'),
          department: Yup.string().required('Department is required'),
          position: Yup.string().required('Position is required'),
          email: Yup.string().required('Email is required'),
          mobile: Yup.string().required('Mobile is required'),
          notes: Yup.string().required('Notes is required'),
        }),
      ),
      notes: Yup.array(
        Yup.object({
          note: Yup.string().required('Enter Notes'),
        }),
      ),
    });

    setDocumentDynamicOptionsValue({
      document_name: '',
      file: '',
    });
    setDocumentDynamicOptionsValue2({
      document_name: Yup.string().required('Enter Document Name'),
      file: Yup.mixed().required('Select a File'),
    });

    setDocumentListDynamicOptionsValue({
      documents: {
        document_name: '',
        owner_remarks: '',
        pmo_remarks: '',
        file: '',
      },
    });
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSupplierDocumentClick = (arg) => {
    const suppliers = arg;
    setSelectedSupplier({
      id: suppliers.id,
      supplier_name: suppliers.supplier_name,
      uen_no: suppliers.uen_no,
      industries: suppliers.industries,
    });
    // setIsEdit(true);
    toggle();
  };

  const handleDocumentClick = () => {
    validationDocument.resetForm();
    toggle();
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const getSupplierList = () => {
    props.actionCreator({
      type: types.GET_SUPPLIER_LIST,
      payload: { id: id },
    });
  };

  //delete suppliers modal
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteType, setDeleteType] = React.useState({});

  const handleDelete = () => {
    const { type, index } = deleteType;
    const itemsMap = {
      address: addresses,
      contact: contacts,
      note: notes,
      document: documents,
    };
    const setItemsMap = {
      address: setAddresses,
      contact: setContacts,
      note: setNotes,
      document: setDocuments,
    };
    const typeMap = {
      address: types.DELETE_ADDRESS,
      contact: types.DELETE_CONTACT,
      note: types.DELETE_NOTE,
      document: types.DELETE_DOCUMENT,
    };

    const items = itemsMap[type] || [];
    const setItems = setItemsMap[type];

    if (isEdit && items[index]?.id) {
      props.actionCreator({
        type: typeMap[type],
        payload: { id: items[index].id },
      });

      getSupplierList();
    } else if ((items.length > 1 && type != 'document') || type == 'document') {
      const updatedItems = items.filter((_, i) => i !== index);
      setItems(updatedItems);

      const validationValues = validation.values[type + 's'];
      if (Array.isArray(validationValues)) {
        validation.setFieldValue(
          type + 's',
          validationValues.filter((_, i) => i !== index),
        );
      }
    }

    setDeleteModal(false);
  };

  const formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  };

  const handleSaveDocument = () => {
    validation.handleSubmit(); // This triggers the formik onSubmit
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Suppliers"
            breadcrumbItem={breadcrumbItem}
            titleLink="/suppliers"
          />{' '}
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <div className="flex-shrink-0">
                  <ul
                    className="nav justify-content-end nav-pills card-header-pills"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: headerPillsTab === '1',
                        })}
                        onClick={() => {
                          toggleHeaderPills('1');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Supplier Information
                        </span>
                      </NavLink>
                    </NavItem>
                    {isEdit && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerPillsTab === '2',
                          })}
                          onClick={() => {
                            toggleHeaderPills('2');
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Documents</span>
                        </NavLink>
                      </NavItem>
                    )}
                  </ul>
                </div>
              </div>

              <div className="card-body">
                <TabContent className="text-muted" activeTab={headerPillsTab}>
                  <TabPane tabId="1">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <h4 className="card-title">Supplier</h4>
                              <p className="card-title-desc">
                                Basic information of the supplier.
                              </p>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Supplier Name
                                    </Label>
                                    <Input
                                      name="supplier_name"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.supplier_name || ''
                                      }
                                      invalid={
                                        validation.touched.supplier_name &&
                                        validation.errors.supplier_name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.supplier_name &&
                                    validation.errors.supplier_name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.supplier_name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      UEN No.
                                    </Label>
                                    <Input
                                      name="uen_no"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.uen_no || ''}
                                      invalid={
                                        validation.touched.uen_no &&
                                        validation.errors.uen_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.uen_no &&
                                    validation.errors.uen_no ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.uen_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Industries
                                    </Label>
                                    <Select
                                      name="industries"
                                      value={selectedIndustries} // Ensure value is an array
                                      isMulti={true}
                                      options={
                                        suppliers?.industryData[0]?.map(
                                          (data) => ({
                                            value: data.value,
                                            label: data.label,
                                          }),
                                        ) || []
                                      } // Ensure options is an array
                                      classNamePrefix="select2-selection"
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                      styles={multiSelectStyle}
                                      onChange={(selectedOptions) => {
                                        // Ensure selectedOptions is not null
                                        const formattedOptions = (
                                          selectedOptions || []
                                        ).map((option) => ({
                                          industry: option.value, // Change this to match your intended format
                                        }));

                                        handleIndustriesClick(selectedOptions); // Pass selectedOptions directly
                                        validation.setFieldValue(
                                          'industries',
                                          formattedOptions,
                                        ); // Formik integration with JSON format
                                      }}
                                      onBlur={validation.handleChange}
                                    />

                                    {/* {validation.touched.industries &&
                                    validation.errors.industries ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.industries}
                                      </FormFeedback>
                                    ) : null} */}
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">Address</h5>
                                    <p className="card-title-desc">
                                      Register the address of supplier.
                                    </p>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreAddressHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Building Name
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={1}>
                                  <div className="mb-3">
                                    <Label className="form-label">Unit</Label>
                                  </div>
                                </Col>
                                <Col xs={1}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Postal Code
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Address
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">City</Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Country
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              {addresses && addresses.length > 0 ? (
                                addresses.map((address, addressIndex) => (
                                  <Row key={`${addressIndex}${address.id}`}>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.building_name`}
                                          label="building_name"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values?.addresses?.[
                                              addressIndex
                                            ]?.building_name || ''
                                          }
                                          invalid={
                                            validation.touched?.addresses?.[
                                              addressIndex
                                            ] &&
                                            validation.errors?.addresses?.[
                                              addressIndex
                                            ]?.building_name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.errors?.addresses?.[
                                          addressIndex
                                        ]?.building_name && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.addresses[
                                                addressIndex
                                              ].building_name
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.unit`}
                                          label="unit"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values?.addresses?.[
                                              addressIndex
                                            ]?.unit || ''
                                          }
                                          invalid={
                                            validation.touched?.addresses?.[
                                              addressIndex
                                            ] &&
                                            validation.errors?.addresses?.[
                                              addressIndex
                                            ]?.unit
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.errors?.addresses?.[
                                          addressIndex
                                        ]?.unit && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.addresses[
                                                addressIndex
                                              ].unit
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.postal_code`}
                                          label="postal_code"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values?.addresses?.[
                                              addressIndex
                                            ]?.postal_code || ''
                                          }
                                          invalid={
                                            validation.touched?.addresses?.[
                                              addressIndex
                                            ] &&
                                            validation.errors?.addresses?.[
                                              addressIndex
                                            ]?.postal_code
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.errors?.addresses?.[
                                          addressIndex
                                        ]?.postal_code && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.addresses[
                                                addressIndex
                                              ].postal_code
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={3}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.address`}
                                          label="address"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values?.addresses?.[
                                              addressIndex
                                            ]?.address || ''
                                          }
                                          invalid={
                                            validation.touched?.addresses?.[
                                              addressIndex
                                            ] &&
                                            validation.errors?.addresses?.[
                                              addressIndex
                                            ]?.address
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.errors?.addresses?.[
                                          addressIndex
                                        ]?.address && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.addresses[
                                                addressIndex
                                              ].address
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.city`}
                                          label="city"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values?.addresses?.[
                                              addressIndex
                                            ]?.city || ''
                                          }
                                          invalid={
                                            validation.touched?.addresses?.[
                                              addressIndex
                                            ] &&
                                            validation.errors?.addresses?.[
                                              addressIndex
                                            ]?.city
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.errors?.addresses?.[
                                          addressIndex
                                        ]?.city && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.addresses[
                                                addressIndex
                                              ].city
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.country`}
                                          label="country"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values?.addresses?.[
                                              addressIndex
                                            ]?.country || ''
                                          }
                                          invalid={
                                            validation.touched?.addresses?.[
                                              addressIndex
                                            ] &&
                                            validation.errors?.addresses?.[
                                              addressIndex
                                            ]?.country
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.errors?.addresses?.[
                                          addressIndex
                                        ]?.country && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.addresses[
                                                addressIndex
                                              ].country
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="text-center">
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() =>
                                            removeAddressHandler(addressIndex)
                                          }
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">
                                      Supplier Contacts
                                    </h5>
                                    <p className="card-title-desc">
                                      Manage all contacts.
                                    </p>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreContactHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">Name</Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Department
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={1}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Position
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Mobile Number
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">Notes</Label>
                                  </div>
                                </Col>
                              </Row>
                              {contacts && contacts.length > 0 ? (
                                contacts.map((contact, contactIndex) => (
                                  <Row key={`${contactIndex}${contact.id}`}>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`contacts.${contactIndex}.name`}
                                          label="name"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation?.values?.contacts?.[
                                              contactIndex
                                            ]?.name || ''
                                          }
                                          invalid={
                                            validation?.touched?.contacts?.[
                                              contactIndex
                                            ] &&
                                            validation?.errors?.contacts?.[
                                              contactIndex
                                            ]?.name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation?.errors?.contacts?.[
                                          contactIndex
                                        ]?.name && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.contacts[
                                                contactIndex
                                              ].name
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`contacts.${contactIndex}.department`}
                                          label="department"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation?.values?.contacts?.[
                                              contactIndex
                                            ]?.department || ''
                                          }
                                          invalid={
                                            validation?.touched?.contacts?.[
                                              contactIndex
                                            ] &&
                                            validation?.errors?.contacts?.[
                                              contactIndex
                                            ]?.department
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation?.errors?.contacts?.[
                                          contactIndex
                                        ]?.department && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.contacts[
                                                contactIndex
                                              ].department
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="mb-3">
                                        <Input
                                          name={`contacts.${contactIndex}.position`}
                                          label="position"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation?.values?.contacts?.[
                                              contactIndex
                                            ]?.position || ''
                                          }
                                          invalid={
                                            validation?.touched?.contacts?.[
                                              contactIndex
                                            ] &&
                                            validation?.errors?.contacts?.[
                                              contactIndex
                                            ]?.position
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation?.errors?.contacts?.[
                                          contactIndex
                                        ]?.position && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.contacts[
                                                contactIndex
                                              ].position
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`contacts.${contactIndex}.email`}
                                          label="email"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation?.values?.contacts?.[
                                              contactIndex
                                            ]?.email || ''
                                          }
                                          invalid={
                                            validation?.touched?.contacts?.[
                                              contactIndex
                                            ] &&
                                            validation?.errors?.contacts?.[
                                              contactIndex
                                            ]?.email
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation?.errors?.contacts?.[
                                          contactIndex
                                        ]?.email && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.contacts[
                                                contactIndex
                                              ].email
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`contacts.${contactIndex}.mobile`}
                                          label="mobile"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation?.values?.contacts?.[
                                              contactIndex
                                            ]?.mobile || ''
                                          }
                                          invalid={
                                            validation?.touched?.contacts?.[
                                              contactIndex
                                            ] &&
                                            validation?.errors?.contacts?.[
                                              contactIndex
                                            ]?.mobile
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation?.errors?.contacts?.[
                                          contactIndex
                                        ]?.mobile && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.contacts[
                                                contactIndex
                                              ].mobile
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`contacts.${contactIndex}.notes`}
                                          label="notes"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation?.values?.contacts?.[
                                              contactIndex
                                            ]?.notes || ''
                                          }
                                          invalid={
                                            validation?.touched?.contacts?.[
                                              contactIndex
                                            ] &&
                                            validation?.errors?.contacts?.[
                                              contactIndex
                                            ]?.notes
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation?.errors?.contacts?.[
                                          contactIndex
                                        ]?.notes && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.contacts[
                                                contactIndex
                                              ].notes
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="text-center">
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() =>
                                            removeSelectedContactHandler(
                                              contactIndex,
                                            )
                                          }
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">Notes</h5>
                                    <p className="card-title-desc">
                                      Enter all notes related to supplier.
                                    </p>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreNotesHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              {notes && notes.length > 0 ? (
                                notes.map((note, noteIndex) => (
                                  <Row key={`${noteIndex}${note.id}`}>
                                    <Col xs={11}>
                                      <div className="mb-3">
                                        <Input
                                          name={`notes.${noteIndex}.note`}
                                          className={`form-control ${
                                            validation?.touched?.notes?.[
                                              noteIndex
                                            ]?.note &&
                                            validation?.errors?.notes?.[
                                              noteIndex
                                            ]?.note
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          label="note"
                                          rows="2"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation?.values?.notes?.[
                                              noteIndex
                                            ]?.note || ''
                                          }
                                        />
                                        {validation?.errors?.notes?.[noteIndex]
                                          ?.note && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.notes[noteIndex]
                                                .note
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>

                                    <Col xs={1}>
                                      <div className="text-center">
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() =>
                                            removeSelectedNotesHandler(
                                              noteIndex,
                                            )
                                          }
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-info text-white"
                            style={{
                              backgroundColor: '#5156BE',
                              width: 175,
                            }}
                          >
                            Save
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>
                  <TabPane tabId="2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationDocumentList.handleSubmit();
                        return false;
                      }}
                    >
                      <Col xs={12}>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <h5 className="card-title">
                                    Upload Document
                                  </h5>
                                  <p className="card-title-desc">
                                    Import any relevant documents related to the
                                    supplier.
                                  </p>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                  <div>
                                    <Link
                                      to="#"
                                      onClick={handleSupplierDocumentClick}
                                    >
                                      <img src={addLogo} alt="Add" />
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="p-4">
                            <Row>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Document Name
                                  </Label>
                                </div>
                              </Col>
                              <Col xs={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Remarks by Uploader
                                  </Label>
                                </div>
                              </Col>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">File</Label>
                                </div>
                              </Col>
                              <Col xs={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    PMO Comments
                                  </Label>
                                </div>
                              </Col>
                            </Row>

                            {documentsCol && documentsCol.length > 0 ? (
                              documentsCol.map((document, documentIndex) => (
                                <Row key={`${documentIndex}${document.id}`}>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      {validationDocumentList.values
                                        ?.documents?.[documentIndex]
                                        ?.document_name || ''}
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div className="mb-3">
                                      {validationDocumentList.values
                                        ?.documents?.[documentIndex]
                                        ?.owner_remarks || ''}
                                    </div>
                                  </Col>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      <Card
                                        style={{
                                          marginBottom: 0,
                                          width: '50%',
                                          border: 0,
                                        }}
                                      >
                                        <div className="p-0">
                                          <Row className="align-items-center">
                                            <Col>
                                              {validationDocumentList?.values
                                                ?.documents?.[documentIndex]
                                                ?.file?.path && (
                                                <Link
                                                  to={URL.createObjectURL(
                                                    validationDocumentList
                                                      ?.values?.documents?.[
                                                      documentIndex
                                                    ]?.file,
                                                  )}
                                                  className="text-primary justify-content-start"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <i
                                                    className="mdi mdi-file-document-outline font-size-18"
                                                    id="edittooltip"
                                                  ></i>
                                                </Link>
                                              )}
                                              {!validationDocumentList?.values
                                                ?.documents?.[documentIndex]
                                                ?.file?.path && (
                                                <Link
                                                  to={`${baseUrl}${validationDocumentList?.values?.documents?.[documentIndex]?.new_file}`}
                                                  className="text-primary justify-content-start"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <i
                                                    className="mdi mdi-file-document-outline font-size-18"
                                                    id="edittooltip"
                                                  ></i>
                                                </Link>
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div className="mb-3">
                                      <Input
                                        name={`documents.${documentIndex}.pmo_remarks`}
                                        className={`form-control ${
                                          validationDocumentList.touched
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks &&
                                          validationDocumentList.errors
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        label="PMO Remarks"
                                        rows="2"
                                        onChange={
                                          validationDocumentList.handleChange
                                        }
                                        onBlur={
                                          validationDocumentList.handleBlur
                                        }
                                        value={
                                          validationDocumentList.values
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks || ''
                                        }
                                      />
                                      {validationDocumentList.errors
                                        ?.documents?.[documentIndex]
                                        ?.pmo_remarks && (
                                        <FormFeedback type="invalid">
                                          {
                                            validationDocumentList.errors
                                              ?.documents?.[documentIndex]
                                              .pmo_remarks
                                          }
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div className="text-center">
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() =>
                                          removeSelectedDocumentHandler(
                                            documentIndex,
                                          )
                                        }
                                      >
                                        <i
                                          className="mdi mdi-delete font-size-18"
                                          id="deletetooltip"
                                        ></i>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              ))
                            ) : (
                              <Row>
                                <Col>
                                  <p className="text-center">
                                    No records found
                                  </p>
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary save-user"
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              Save
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Upload Document
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validationDocument.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">Document Name</Label>
                      <Input
                        name="document_name"
                        type="text"
                        onChange={validationDocument.handleChange}
                        onBlur={validationDocument.handleBlur}
                        value={validationDocument.values.document_name || ''}
                        invalid={
                          validationDocument.touched.document_name &&
                          validationDocument.errors.document_name
                            ? true
                            : false
                        }
                      />
                      {validationDocument.touched.document_name &&
                      validationDocument.errors.document_name ? (
                        <FormFeedback type="invalid">
                          {validationDocument.errors.document_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">Remarks</Label>
                      <Input
                        name="owner_remarks"
                        type="text"
                        onChange={validationDocument.handleChange}
                        onBlur={validationDocument.handleBlur}
                        value={validationDocument.values.owner_remarks || ''}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Dropzone
                      onDrop={(acceptedFiles, fileRejections) => {
                        setSelectedFiles(acceptedFiles?.[0] || '');
                        setErrorMessages(
                          fileRejections.map(
                            ({ file, errors }) =>
                              `${file.name}: ${errors.map((e) => e.message).join(', ')} `,
                          ),
                        );
                        validationDocument.setFieldValue(
                          'file',
                          acceptedFiles?.[0] || '',
                        );
                      }}
                      className="error"
                      accept={{
                        'application/pdf': ['.pdf'],
                      }}
                      multiple={false} // Disable multiple file selection
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bx-cloud-upload" />
                            </div>
                            <h4>Drop or click to attach a pdf file.</h4>
                            {errorMessages.length > 0 && (
                              <div
                                className="error-messages"
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                {errorMessages.map((message, index) => (
                                  <p key={index} className="text-danger">
                                    {message}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {validationDocument.errors.file && (
                      <p className="text-danger">
                        {validationDocument.errors.file}
                      </p>
                    )}
                  </Col>
                </Row>
                {selectedFiles?.name && (
                  <Row>
                    <Col>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <Card
                          style={{
                            marginLeft: 5,
                            marginRight: 5,
                            width: '50%',
                          }}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col
                                className="col-auto text-primary"
                                style={{
                                  marginBottom: 10,
                                }}
                              >
                                <i
                                  className="mdi mdi-file-document-outline font-size-30"
                                  id="edittooltip"
                                ></i>
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {selectedFiles.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{selectedFiles.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12}>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = ({ app, suppliers, document }) => ({
  app,
  suppliers,
  document,
});

export default connect(mapStateToProps, { actionCreator })(SuppilersList);
