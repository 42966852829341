import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_WORKER_LIST,
  GET_WORKER_LIST_SUCCESS,
  GET_WORKER_LIST_FAILED,
  ADD_NEW_WORKER,
  ADD_NEW_WORKER_SUCCESS,
  ADD_NEW_WORKER_FAILED,
  UPDATE_WORKER,
  UPDATE_WORKER_SUCCESS,
  UPDATE_WORKER_FAILED,
  DELETE_WORKER,
  DELETE_WORKER_SUCCESS,
  DELETE_WORKER_FAILED,
  GET_WORKER_DEPARTMENT_LIST_ALL,
  GET_WORKER_DEPARTMENT_LIST_ALL_SUCCESS,
  GET_WORKER_DEPARTMENT_LIST_ALL_FAILED,
  GET_WORKERS_BI,
  GET_WORKERS_BI_SUCCESS,
  GET_WORKERS_BI_FAILED,
  GET_WORKER_LIST_ALL,
  GET_WORKER_LIST_ALL_SUCCESS,
  GET_WORKER_LIST_ALL_FAILED,
  LOG_VIEW,
  LOG_VIEW_SUCCESS,
  LOG_VIEW_FAILED,
} from "./types";

import workersServices from "../../api/services/workers";

function* fnGetWorkerListAll() {
  try {
    const data = yield call(workersServices.api.fnGetWorkerListAll);

    yield put({
      type: GET_WORKER_LIST_ALL_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_WORKER_LIST_ALL_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetWorkerList(payload) {
  const id = payload.payload.id;
  try {
    const data = yield call(workersServices.api.fnGetWorkerList, id);

    yield put({
      type: LOG_VIEW,
      payload: { 
        type: 'worker',
        source_id: id,
        label: "view worker",
        to: `view ${data.data.result.name || ""}`
      },
    });

    yield put({
      type: GET_WORKER_LIST_SUCCESS,
      payload: data.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_WORKER_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetWorkerDepartmentListAll() {
  try {
    const data = yield call(workersServices.api.fnGetWorkerDepartmentListAll);
    yield put({
      type: GET_WORKER_DEPARTMENT_LIST_ALL_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_WORKER_DEPARTMENT_LIST_ALL_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnAddNewWorker({ payload }) {
  try {
    const result = yield call(workersServices.api.fnAddNewWorker, payload);

    yield put({
      type: ADD_NEW_WORKER_SUCCESS,
      payload: { message: result.data.message },
    });
    // yield put({
    //   type: GET_WORKER_LIST,
    // });
  } catch (error) {
    yield put({
      type: ADD_NEW_WORKER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnUpdateWorker({ payload }) {
  try {
    const data = yield call(workersServices.api.fnUpdateWorker, payload);
    if (data.status == 200) {
      yield put({
        type: UPDATE_WORKER_SUCCESS,
        payload: { message: data?.data.message, status: "success" },
      });
      yield put({
        type: GET_WORKER_LIST,
        payload: { id: payload.id },
      });
    } else {
      yield put({
        type: UPDATE_WORKER_FAILED,
        payload: { message: data?.data.message, status: "error" },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_WORKER_FAILED,
      payload: error.payload.data ? error.payload.data.message : "",
    });
  }
}

function* fnDeleteWorker({ payload }) {
  try {
    const { id } = payload;

    yield call(workersServices.api.fnDeleteWorker, id);
    yield put({
      type: DELETE_WORKER_SUCCESS,
    });
    yield put({
      type: GET_WORKER_LIST,
    });
  } catch (error) {
    yield put({
      type: DELETE_WORKER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetWorkersBI({ payload }) {
  try {
    const response = yield call(workersServices.api.fnGetWorkersBI, payload);
    yield put({
      type: GET_WORKERS_BI_SUCCESS,
      payload: { data: response.data.result },
    });
  } catch (error) {
    yield put({
      type: GET_WORKERS_BI_FAILED,
      payload: error.response?.data ? error.response.data.message : error,
    });
  }
}

function* fnLogViewWorker({ payload }) {
  try {
    const result = yield call(workersServices.api.fnLogViewWorker, payload);

    yield put({
      type: LOG_VIEW_SUCCESS,
      payload: { message: result.data.message },
    });
    
  } catch (error) {
    yield put({
      type: LOG_VIEW_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_WORKER_LIST_ALL, fnGetWorkerListAll);
  yield takeLatest(
    GET_WORKER_DEPARTMENT_LIST_ALL,
    fnGetWorkerDepartmentListAll
  );
  yield takeLatest(GET_WORKER_LIST, fnGetWorkerList);
  yield takeLatest(ADD_NEW_WORKER, fnAddNewWorker);
  yield takeLatest(UPDATE_WORKER, fnUpdateWorker);
  yield takeLatest(DELETE_WORKER, fnDeleteWorker);
  yield takeLatest(GET_WORKERS_BI, fnGetWorkersBI);
  yield takeLatest(LOG_VIEW, fnLogViewWorker);
}
