import {
  GET_REQUEST_LIST,
  GET_REQUEST_LIST_SUCCESS,
  GET_REQUEST_LIST_FAILED,
  GET_REQUEST_LIST_FILTERED,
  GET_REQUEST_LIST_FILTERED_SUCCESS,
  GET_REQUEST_LIST_FILTERED_FAILED,
  GET_TOTAL,
  GET_TOTAL_SUCCESS,
  GET_TOTAL_FAILED,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILED,
  APPROVE_REQUISITION,
  APPROVE_REQUISITION_SUCCESS,
  APPROVE_REQUISITION_FAILED,
  PROCESS_REQUISITION,
  PROCESS_REQUISITION_SUCCESS,
  PROCESS_REQUISITION_FAILED,
  COMPLETE_REQUISITION,
  COMPLETE_REQUISITION_SUCCESS,
  COMPLETE_REQUISITION_FAILED,
  REJECT_REQUISITION,
  REJECT_REQUISITION_SUCCESS,
  REJECT_REQUISITION_FAILED,
  ADD_REQUISITION,
  ADD_REQUISITION_SUCCESS,
  ADD_REQUISITION_FAILED,
  GET_REQUISITION_WORKER,
  GET_REQUISITION_WORKER_SUCCESS,
  GET_REQUISITION_WORKER_FAILED,
  CLEAR_MESSAGES,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  totalData: [],
  optionsData: [],
  error: false,
  errorMessage: null,
  messages: null,
  dataStatus: null,
  isSubmitted: null,
  workersAndUrgencyData: [],
};

const requisition = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REQUEST_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_REQUEST_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
        errorMessage: action.payload,
      };

    case ADD_REQUISITION:
      return {
        ...state,
        loading: true,
      };

    case ADD_REQUISITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload,
      };

    case ADD_REQUISITION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case GET_REQUEST_LIST_FILTERED:
      return {
        ...state,
        loading: true,
      };

    case GET_REQUEST_LIST_FILTERED_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
        messages: null,
      };

    case GET_REQUEST_LIST_FILTERED_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
        errorMessage: action.payload,
      };

    case GET_TOTAL:
      return {
        ...state,
        loading: true,
      };

    case GET_TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        totalData: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_TOTAL_FAILED:
      return {
        ...state,
        loading: false,
        totalData: [],
        error: true,
        errorMessage: action.payload,
      };

    case GET_OPTIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        optionsData: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_OPTIONS_FAILED:
      return {
        ...state,
        loading: false,
        optionsData: [],
        error: true,
        errorMessage: action.payload,
      };

    case APPROVE_REQUISITION:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        messages: null,
        dataStatus: null,
        isSubmitted: false,
      };

    case APPROVE_REQUISITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.message,
        dataStatus: action.payload,
        isSubmitted: true,
      };

    case APPROVE_REQUISITION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case PROCESS_REQUISITION:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        messages: null,
        dataStatus: null,
        isSubmitted: false,
      };

    case PROCESS_REQUISITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.message,
        dataStatus: action.payload,
        isSubmitted: true,
      };

    case PROCESS_REQUISITION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case COMPLETE_REQUISITION:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        messages: null,
        dataStatus: null,
        isSubmitted: false,
      };

    case COMPLETE_REQUISITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.message,
        dataStatus: action.payload,
        isSubmitted: true,
      };

    case COMPLETE_REQUISITION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case REJECT_REQUISITION:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        messages: null,
        dataStatus: null,
        isSubmitted: false,
      };

    case REJECT_REQUISITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.message,
        dataStatus: action.payload,
        isSubmitted: true,
      };

    case REJECT_REQUISITION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case GET_REQUISITION_WORKER:
      return {
        ...state,
        loading: true,
      };

    case GET_REQUISITION_WORKER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        workersAndUrgencyData: action.payload.data,
        messages: null,
      };

    case GET_REQUISITION_WORKER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: null,
        isSubmitted: false,
      };

    default:
      return state;
  }
};

export default requisition;
