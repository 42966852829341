import React, { useState, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import Dropzone from 'react-dropzone';
import { useParams, useNavigate } from 'react-router-dom';
import addLogo from '../../assets/images/add.png';
import Select from 'react-select';
import { multiSelectStyle } from '../../components/Common/MultiSelectStyles';

import { ToastContainer, toast } from 'react-toastify';

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Collapse,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ID, OrigFile, Remarks, File } from './column';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';

import classnames from 'classnames';
import { DELETE_DOCUMENT } from '../../store/actions';

const AnnouncementsList = ({
  app,
  announcements,
  clients,
  route,
  document,
  animatedComponents,
  ...props
}) => {
  document.title = 'Announcements List | MPS';
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [selectedSuppiler, setSelectedSupplier] = React.useState({});
  const [selectedClients, setSelectedClients] = useState([]);

  //Editor
  const editorRef = useRef();
  const [editor, setEditor] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const [data, setData] = useState('');

  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  //Forms
  const [addresses, setAddresses] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);

  //Dropzone
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);

  //documents
  const [documentsCol, setDocumentsCol] = React.useState([]);

  //Tabs
  const [headerPillsTab, setHeaderPillsTab] = useState('1');

  const [dynamicOptionsValue, setDynamicOptionsValue] = React.useState({});
  const [dynamicOptionsValue2, setDynamicOptionsValue2] = React.useState({});

  //Get ID and Check Add/Edit
  const { id } = useParams();

  const breadcrumbItem =
    isEdit && id ? 'Manage Announcement' : 'Add New Announcement';

  // Check and remove any empty objects from the arrays
  const cleanArray = (arr) =>
    arr.filter((item) => Object.values(item).some((value) => value !== ''));

  const cleanObject = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== ''),
    );
  };

  //validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: dynamicOptionsValue,
    validationSchema: Yup.object(dynamicOptionsValue2),
    onSubmit: (values) => {
      if (isEdit) {
        values.id = id;
        // update announcements

        props.actionCreator({
          type: types.UPDATE_ANNOUNCEMENT,
          payload: values,
        });
      } else {
        // add new announcements
        props.actionCreator({
          type: types.ADD_NEW_ANNOUNCEMENT,
          payload: values,
        });
        navigate('/announcements');
      }
    },
  });

  const handleClientClick = (selected) => {
    let selectedData = [];
    for (let row of selected) {
      selectedData.push({
        label: row.value,
        value: row.value,
      });
    }

    setSelectedClients(selectedData);
  };

  const handleNotification = async (message) => {
    toast(message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-success text-white',
    });

    clearSupplierSaveMessage();
    clearDocumentSaveMessage();
  };

  const clearDocumentSaveMessage = async () => {
    await props.actionCreator({
      type: types.CLEAR_DOCUMENT_SAVE_MESSAGE,
    });
  };

  const handleErrorNotification = async (message) => {
    toast(message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-danger text-white',
    });
  };

  const clearSupplierSaveMessage = async () => {
    await props.actionCreator({
      type: types.CLEAR_SUPPLIER_SAVE_MESSAGE,
    });
  };
  React.useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditor(true);
  }, []);

  React.useEffect(() => {
    announcements.isSubmitted &&
      announcements.actionReturnData?.message &&
      handleNotification(announcements.actionReturnData.message);
    announcements.error &&
      announcements.isSubmitted === false &&
      announcements.errorMessage &&
      handleErrorNotification(announcements.errorMessage);
  }, [announcements.actionReturnData.message]);

  //runs after useEffect []
  React.useEffect(() => {
    if (isEdit && announcements?.data && announcements.data[0]?.recordset?.length > 0) {
  
      const data = announcements.data[0]?.recordset[0];
  
      // Check if data is valid before accessing properties
      setDynamicOptionsValue({
        message: data?.message || '',
        status: data?.status || '',
      });
  
      let selectedDefaultAnnouncement = [];
  
      if (data?.clients === "all") {
        selectedDefaultAnnouncement = []; // Set to empty array if "all"
      } else {
        try {
          const clientsArray = data?.clients ? JSON.parse(data.clients) : [];
          for (let row of clientsArray) {
            selectedDefaultAnnouncement.push({
              label: row,
              value: row,
            });
          }
        } catch (error) {
          console.error("Error parsing clients:", error);
        }
      }
  
      setSelectedClients(selectedDefaultAnnouncement);
    }
  }, [announcements, isEdit, document]);
  

  React.useEffect(() => {
    const edit = location.pathname.includes('/edit');
    setIsEdit(edit);
    if (edit) {
      props.actionCreator({
        type: types.GET_ANNOUNCEMENT_LIST,
        payload: { id: id },
      });
    }

    props.actionCreator({
      type: types.GET_CLIENT_LIST_ALL_DROPDOWN,
    });

    setDynamicOptionsValue({
      clients: [],
      status: '',
      message: '',
    });
    setDynamicOptionsValue2({
      // supplier_name: Yup.string().required('Supplier Name is required'),
      // uen_no: Yup.string().required('UEN No. is required'),
      // clients: Yup.array()
      //   .min(1, 'Select at least one clients')
      //   .required('Industries is required'),
    });
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSupplierDocumentClick = (arg) => {
    const announcements = arg;
    setSelectedSupplier({
      id: announcements.id,
      supplier_name: announcements.supplier_name,
      uen_no: announcements.uen_no,
      clients: announcements.clients,
    });
    // setIsEdit(true);
    toggle();
  };

  const handleDocumentClick = () => {
    validationDocument.resetForm();
    toggle();
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const getSupplierList = () => {
    props.actionCreator({
      type: types.GET_SUPPLIER_LIST,
      payload: { id: id },
    });
  };

  //delete announcements modal
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteType, setDeleteType] = React.useState({});

  const handleDelete = () => {
    const { type, index } = deleteType;
    const itemsMap = {
      address: addresses,
      contact: contacts,
      note: notes,
      document: documents,
    };
    const setItemsMap = {
      address: setAddresses,
      contact: setContacts,
      note: setNotes,
      document: setDocuments,
    };
    const typeMap = {
      address: types.DELETE_ADDRESS,
      contact: types.DELETE_CONTACT,
      note: types.DELETE_NOTE,
      document: types.DELETE_DOCUMENT,
    };

    const items = itemsMap[type] || [];
    const setItems = setItemsMap[type];

    if (isEdit && items[index]?.id) {
      props.actionCreator({
        type: typeMap[type],
        payload: { id: items[index].id },
      });

      getSupplierList();
    } else if ((items.length > 1 && type != 'document') || type == 'document') {
      const updatedItems = items.filter((_, i) => i !== index);
      setItems(updatedItems);

      const validationValues = validation.values[type + 's'];
      if (Array.isArray(validationValues)) {
        validation.setFieldValue(
          type + 's',
          validationValues.filter((_, i) => i !== index),
        );
      }
    }

    setDeleteModal(false);
  };

  const formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  };

  const handleSaveDocument = () => {
    validation.handleSubmit(); // This triggers the formik onSubmit
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Announcements"
            breadcrumbItem={breadcrumbItem}
            titleLink="/announcements"
          />{' '}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody className="p-4">
                    <Row className="mb-3">
                      <Col lg={6}>
                        <div className="mb-2">
                          <Label className="form-label">Clients</Label>
                          <Select
                            name="clients"
                            value={selectedClients} // Ensure value is an array
                            isMulti={true}
                            options={
                              clients?.dropdownData[0]?.map((data) => ({
                                value: data.label,
                                label: data.label,
                              })) || []
                            } // Ensure options is an array
                            classNamePrefix="select2-selection"
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            styles={multiSelectStyle}
                            onChange={(selectedOptions) => {
                              // Extract only the values (e.g., 'Client A') from selectedOptions
                              const selectedValues = (
                                selectedOptions || []
                              ).map((option) => option.value);

                              handleClientClick(selectedOptions); // Pass selectedOptions directly if needed elsewhere
                              validation.setFieldValue(
                                'clients',
                                selectedValues,
                              ); // Store only values in Formik
                            }}
                            onBlur={validation.handleChange}
                          />

                          {/* Display validation error */}
                          {validation.touched.clients &&
                          validation.errors.clients ? (
                            <FormFeedback type="invalid">
                              {validation.errors.clients}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <label>
                        Select a client that you want to display the
                        announcement.
                      </label>
                    </Row>

                    <Row className="mb-3">
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label className="form-label">Status</Label>
                          <Input
                            type="select"
                            name="status"
                            className="form-select"
                            multiple={false}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || '0'}
                            invalid={
                              validation.touched.status &&
                              validation.errors.status
                                ? true
                                : false
                            }
                          >
                            <option value={''}>Select Status</option>
                            <option value={1}>Active</option>
                            <option value={2}>Inactive</option>
                          </Input>
                          {validation.touched.status &&
                          validation.errors.status ? (
                            <FormFeedback type="invalid">
                              {validation.errors.status}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <Label className="form-label">Message</Label>
                        {editor ? (
                          <CKEditor
                            name="message"
                            editor={ClassicEditor}
                            data={validation.values.message || ''}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              // Extract plain text from the HTML content
                              const plainText = data.replace(/<[^>]*>/g, ''); // Removes HTML tags
                              validation.setFieldValue('message', plainText); // Store plain text for validation
                            }}
                            invalid={
                              validation.touched.message &&
                              validation.errors.message
                                ? true
                                : false
                            }
                          />
                        ) : (
                          <p>ckeditor5</p>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-info text-white"
                  style={{
                    backgroundColor: '#5156BE',
                    width: 175,
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = ({ app, announcements, clients, document }) => ({
  app,
  announcements,
  clients,
  document,
});

export default connect(mapStateToProps, { actionCreator })(AnnouncementsList);
