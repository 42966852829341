import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import Select from 'react-select';
import { multiSelectStyle } from './styles';
import { Link } from 'react-router-dom';
import { actionCreator, types } from '../../../store';
import * as Yup from 'yup';
import makeAnimated from 'react-select/animated';
import { useFormik } from 'formik';
import Dropzone from 'react-dropzone';

const JobOrderEdit = ({
  jobOrderDetails,
  joborder,
  clientfields,
  suppliers,
  ...props
}) => {
  const { department, costCenter, clientSuppliers } = clientfields;
  const editorRef = React.useRef();
  const [selectedFiles, setselectedFiles] = React.useState([]);
  const [jobDescription, setJobDescription] = React.useState('');
  const [attachmentModal, setAttachmentModal] = React.useState(null);
  const [editor, setEditor] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [jobOrderSuppliers, setJobOrderSuppliers] = React.useState([]);
  const [filterClientSuppliers, setFilterClientSuppliers] = React.useState([]);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const [industryOptions, setIndustryOptions] = React.useState([]);
  const animatedComponents = makeAnimated();
  const [filters, setFilters] = React.useState({
    industry: [],
  });

  React.useEffect(() => {
    setFilterClientSuppliers(suppliers.data || []);
    if (suppliers && suppliers.industryData.length > 0) {
      setIndustryOptions(
        suppliers?.industryData[0].map((status) => ({
          label: status.label,
          value: status.value,
        })),
      );
    }
  }, [suppliers]);

  React.useEffect(() => {
    if (jobOrderDetails) {
      jobOrderDetails.job_description &&
        setJobDescription(jobOrderDetails.job_description);
      fetchDepartment();
      fetchCostCenter();
      fetchClientSuppliers();
      suppliersHandler();
    }
  }, [jobOrderDetails]);

  React.useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditor(true);
    props.actionCreator({
      type: types.GET_INDUSTRY_FILTER_LIST,
    });
  }, []);

  React.useEffect(() => {
    let payload = {};
    if (filters.industry.length > 0) {
      payload.filter_by_industry = filters.industry;
    }
    props.actionCreator({
      type: types.GET_SUPPLIER_LIST_ALL,
      payload,
    });
  }, [filters]);

  const handleSelectClick = (selectedOptions) => {
    const selectedIndustries = selectedOptions.map((option) => option.value);
    handleFilterIndustrySelect(selectedIndustries);
  };

  const handleFilterIndustrySelect = (industry) => {
    setFilters({ ...filters, industry });
  };

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: '',
      department: (jobOrderDetails && jobOrderDetails.department) || '',
      costCenter: (jobOrderDetails && jobOrderDetails.cost_center) || '',
      positionNumber: (jobOrderDetails && jobOrderDetails.no_positions) || '',
      salaryBudget:
        (jobOrderDetails && jobOrderDetails.client_salary_budget) || '',
      jobTitle: (jobOrderDetails && jobOrderDetails.job_title) || '',
      supplier_list: jobOrderSuppliers,
      type_of_services:
        (jobOrderDetails && jobOrderDetails.type_of_services) || '',
    },
    validationSchema: Yup.object({
      department: Yup.string().required('Select a Department'),
      costCenter: Yup.string().required('Select a Cost Center'),
      positionNumber: Yup.string().required('Select a No. of Positions'),
      salaryBudget: Yup.string().required('Enter a Client Budget'),
      jobTitle: Yup.string().required('Enter a Job Title'),
      type_of_services: Yup.string().required('Select a Type of Services'),
    }),
    onSubmit: async (values) => {
      let updateJO = new FormData();

      if (selectedFiles.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
          updateJO.append('files', selectedFiles[i], selectedFiles[i].name);
        }
      }

      updateJO.append('joborder_id', jobOrderDetails.id);
      updateJO.append('requisition_id', jobOrderDetails.requisition_id);
      updateJO.append('job_description', jobDescription);
      updateJO.append('type_of_services', values['type_of_services']);
      updateJO.append('department', values['department']);
      updateJO.append('cost_center', values['costCenter']);
      updateJO.append('no_positions', values['positionNumber']);
      updateJO.append('client_salary_budget', values['salaryBudget']);
      updateJO.append('job_title', values['jobTitle']);
      updateJO.append('supplier_ids', JSON.stringify(values['supplier_list']));

      // save new job request
      props.actionCreator({
        type: types.POST_UPDATE_JOB_ORDER,
        payload: {
          formData: updateJO,
        },
      });
    },
  });

  const fetchClientSuppliers = async () => {
    await props.actionCreator({
      type: types.GET_CLIENT_SUPPLIERS,
      payload: {
        client_id: jobOrderDetails.client_id,
      },
    });
  };

  const suppliersHandler = async () => {
    if (jobOrderDetails.suppliers) {
      let newSuppliers = [];

      for (let supplier of jobOrderDetails.suppliers) {
        newSuppliers.push(supplier.id);
      }

      setJobOrderSuppliers(newSuppliers);
    }
  };

  const fetchDepartment = async () => {
    await props.actionCreator({
      type: types.GET_DEPARTMENT_OPTIONS,
      payload: {
        type: 'department',
        client_id: jobOrderDetails.client_id,
      },
    });
  };

  const fetchCostCenter = async () => {
    await props.actionCreator({
      type: types.GET_COST_CENTER_OPTIONS,
      payload: {
        type: 'cost center',
        client_id: jobOrderDetails.client_id,
      },
    });
  };

  const toggleAttachmentModal = () => {
    setAttachmentModal(!attachmentModal);
  };

  const handleSearchSupplier = (e) => {
    const searchValue = e.target.value.toLowerCase();

    if (searchValue) {
      //const filteredSuppliers = clientSuppliers.data.filter((item) =>
      const filteredSuppliers = suppliers.data.filter((item) =>
        item.supplier_name.toLowerCase().includes(searchValue),
      );
      setFilterClientSuppliers(filteredSuppliers);
    } else {
      setFilterClientSuppliers(suppliers.data || []);
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const { supplier_list } = validation.values;

    if (checked) {
      // Add the selected value to the array
      validation.setFieldValue('supplier_list', [
        ...supplier_list,
        parseInt(value),
      ]);
    } else {
      // Remove the deselected value from the array
      validation.setFieldValue(
        'supplier_list',
        supplier_list.filter((v) => v !== parseInt(value)),
      );
    }
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col sm={8}>
            <Card>
              <CardHeader>
                <CardTitle className="h4">Job Details</CardTitle>
                <p className="card-title-desc">
                  Detailed information about the job order.
                </p>
              </CardHeader>
              <CardBody>
                <Row>
                  {/* <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Input
                        type="select"
                        name="status"
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || "0"}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        <option value={1}>Vacant</option>
                        <option value={2}>Filled</option>
                        <option value={3}>Closed</option>
                        <option value={4}>Cancelled</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Types of Services</Label>
                      <Input
                        type="select"
                        name="type_of_services"
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type_of_services || ''}
                        invalid={
                          validation.touched.type_of_services &&
                          validation.errors.type_of_services
                            ? true
                            : false
                        }
                      >
                        <option value={''}>select</option>
                        <option value={'Staff Augmentation'}>
                          Staff Augmentation
                        </option>
                        <option value={'Statement of Work'}>
                          Statement of Work
                        </option>
                        <option value={'Freelancers'}>Freelancers</option>
                      </Input>
                      {validation.touched.type_of_services &&
                      validation.errors.type_of_services ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type_of_services}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Department</Label>
                      <Input
                        type="select"
                        name="department"
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.department || '0'}
                        invalid={
                          validation.touched.department &&
                          validation.errors.department
                            ? true
                            : false
                        }
                      >
                        <option value={''}> </option>
                        {department.data.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.department &&
                      validation.errors.department ? (
                        <FormFeedback type="invalid">
                          {validation.errors.department}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Cost Center</Label>
                      <Input
                        type="select"
                        name="costCenter"
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.costCenter || '0'}
                        invalid={
                          validation.touched.costCenter &&
                          validation.errors.costCenter
                            ? true
                            : false
                        }
                      >
                        <option value={''}> </option>
                        {costCenter.data.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.costCenter &&
                      validation.errors.costCenter ? (
                        <FormFeedback type="invalid">
                          {validation.errors.costCenter}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">No. of Positions</Label>
                      <Input
                        type="number"
                        name="positionNumber"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.positionNumber || '0'}
                        invalid={
                          validation.touched.positionNumber &&
                          validation.errors.positionNumber
                            ? true
                            : false
                        }
                        min={0}
                      />
                      {validation.touched.positionNumber &&
                      validation.errors.positionNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.positionNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Client Budget</Label>
                      <Input
                        type="text"
                        name="salaryBudget"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.salaryBudget || ''}
                        invalid={
                          validation.touched.salaryBudget &&
                          validation.errors.salaryBudget
                            ? true
                            : false
                        }
                      />
                      {validation.touched.salaryBudget &&
                      validation.errors.salaryBudget ? (
                        <FormFeedback type="invalid">
                          {validation.errors.salaryBudget}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Job Title</Label>
                      <Input
                        type="text"
                        name="jobTitle"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.jobTitle || ''}
                        invalid={
                          validation.touched.jobTitle &&
                          validation.errors.jobTitle
                            ? true
                            : false
                        }
                      />
                      {validation.touched.jobTitle &&
                      validation.errors.jobTitle ? (
                        <FormFeedback type="invalid">
                          {validation.errors.jobTitle}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label className="form-label">Job Description</Label>
                      {editor ? (
                        <CKEditor
                          editor={ClassicEditor}
                          data={jobDescription}
                          onReady={(editor) => {
                            //console.log("Editor is ready to use!", editor);
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setJobDescription(data);
                          }}
                        />
                      ) : (
                        <p>ckeditor5</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: 25,
                  }}
                >
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Supporting Documents (optional)
                      </Label>
                      <button
                        type="button"
                        className="btn btn-light"
                        style={{
                          width: 95,
                          height: 90,
                        }}
                        onClick={toggleAttachmentModal}
                      >
                        <i className="mdi mdi-upload display-6"> </i>
                        <p>Upload</p>
                      </button>
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div
                      className="dropzone-previews mt-3"
                      id="file-previews"
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            style={{
                              marginLeft: 5,
                              marginRight: 5,
                              width: '50%',
                            }}
                            key={i + '-file'}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col
                                  className="col-auto text-primary"
                                  style={{
                                    marginBottom: 10,
                                  }}
                                >
                                  <i
                                    className="mdi mdi-file-document-outline font-size-30"
                                    id="edittooltip"
                                  ></i>
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Col>
                  <Modal
                    isOpen={attachmentModal}
                    toggle={toggleAttachmentModal}
                    style={{ maxWidth: '700px', width: '80%' }}
                  >
                    <ModalHeader toggle={toggleAttachmentModal} tag="h4">
                      Attach Files
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col xs={12}>
                          <Dropzone
                            onDrop={(acceptedFiles, fileRejections) => {
                              setselectedFiles(acceptedFiles);
                              setErrorMessages(
                                fileRejections.map(
                                  ({ file, errors }) =>
                                    `${file.name}: ${errors.map((e) => e.message).join(', ')} `,
                                ),
                              );

                              if (!fileRejections.length) {
                                toggleAttachmentModal();
                              }
                            }}
                            accept={{
                              'application/pdf': ['.pdf'],
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                  </div>
                                  <h4>
                                    Drop files here or click to attach files.
                                  </h4>
                                  {errorMessages.length > 0 && (
                                    <div
                                      className="error-messages"
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      {errorMessages.map((message, index) => (
                                        <p key={index} className="text-danger">
                                          {message}
                                        </p>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </Row>
                <Row
                  style={{
                    margin: 40,
                  }}
                >
                  <Col
                    lg={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-info text-white"
                        style={{
                          backgroundColor: '#5156BE',
                          width: 175,
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <CardHeader>
                <CardTitle className="h4">Supplier(s)</CardTitle>
                <p className="card-title-desc">
                  Distribute job to the supplier(s).
                </p>
              </CardHeader>
              <CardBody>
                <Select
                  value={filters.industry.map((industry) => ({
                    value: industry,
                    label: industry,
                  }))}
                  placeholder="Filter by Industry"
                  isMulti={true}
                  options={industryOptions}
                  classNamePrefix="select2-selection"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  styles={multiSelectStyle}
                  onChange={handleSelectClick}
                />
                <Input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Search Supplier"
                  onChange={handleSearchSupplier}
                  style={{
                    fontStyle: 'italic',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 20,
                  }}
                >
                  {filterClientSuppliers.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: 10,
                      }}
                    >
                      <Input
                        type="checkbox"
                        name="supplier_list"
                        style={{
                          marginRight: 10,
                        }}
                        value={item.id}
                        checked={validation.values.supplier_list.includes(
                          parseInt(item.id),
                        )}
                        onChange={handleCheckboxChange}
                      />
                      <Link
                        to={`/suppliers/edit/${item.id}`}
                        style={{
                          fontWeight: 700,
                          fontFamily: 'IBM Plex Sans',
                        }}
                      >
                        <Label style={{ cursor: 'pointer' }}>
                          {' '}
                          {item.supplier_name}{' '}
                        </Label>
                      </Link>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = ({ joborder, clientfields, suppliers }) => ({
  joborder,
  clientfields,
  suppliers,
});

export default connect(mapStateToProps, { actionCreator })(JobOrderEdit);
