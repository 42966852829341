import { emsApi } from "..";
import { WORKERS_SERVICE } from "../constants";

class Workers {
  async fnGetWorkerListAll() {
    return await emsApi.get(WORKERS_SERVICE.GET_WORKERS_SERVICE_LIST_ALL);
  }

  async fnGetWorkerDepartmentListAll() {
    return await emsApi.get(
      WORKERS_SERVICE.GET_WORKERS__DEPARTMENT_SERVICE_LIST_ALL
    );
  }

  async fnGetWorkerList(values) {
    return await emsApi.get(
      `${WORKERS_SERVICE.GET_WORKERS_SERVICE_LIST}/${values}`
    );
  }

  async fnAddNewWorker(payload) {
    return await emsApi.post(WORKERS_SERVICE.ADD_NEW_WORKERS_SERVICE, payload);
  }

  async fnUpdateWorker(values) {
    return await emsApi.patch(
      `${WORKERS_SERVICE.UPDATE_WORKERS_SERVICE}/${values.id}`,
      values
    );
  }

  async fnDeleteWorker(id) {
    return await emsApi.delete(
      `${WORKERS_SERVICE.DELETE_WORKERS_SERVICE}/${id}`
    );
  }

  async fnGetWorkersBI(payload) {
    return await emsApi.post(WORKERS_SERVICE.GET_WORKERS_BI, payload);
  }

  async fnLogViewWorker(payload) {
    return await emsApi.post(WORKERS_SERVICE.LOG_VIEW_WORKERS, payload);
  }
}

Workers.api = new Workers();
export default Workers;
