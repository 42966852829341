import React from 'react';
import ReactApexChart from 'react-apexcharts';
import CountUp from 'react-countup';
import { actionCreator } from '../../../store';
import { USER_GROUPS } from '../../../constants/app';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';

const ApexChart = ({ user_group_id, dashboard }) => {
  const options = {
    chart: {
      height: 50,
      type: 'line',
      toolbar: { show: false },
      sparkline: {
        enabled: true,
      },
    },
    colors: ['#5156be'],
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return '';
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };
  const [widgetsData, setWidgetsData] = React.useState([]);

  React.useEffect(() => {
    dashboard.apexCharts.data?.workerActive &&
      apexChartsHandler(dashboard.apexCharts.data);
  }, [dashboard.apexCharts.data]);

  const apexChartsHandler = (chartsData) => {
    const { workerActive, clientSupplier, client } = chartsData;
    let widgetData = [];
    let randomSeries = Array.from(
      { length: 12 },
      () => Math.floor(Math.random() * 100) + 1,
    );

    widgetData.push({
      id: 1,
      title: 'Active Workers',
      totalNumber: workerActive?.total_active_worker || 0,
      series: randomSeries,
    });

    if (
      user_group_id === USER_GROUPS.PMO_SUPPORT ||
      user_group_id === USER_GROUPS.SUPER_ADMIN
    ) {
      randomSeries = Array.from(
        { length: 12 },
        () => Math.floor(Math.random() * 100) + 1,
      );

      widgetData.push({
        id: 2,
        title: 'Suppliers',
        totalNumber: clientSupplier?.total_supplier || 0,
        series: randomSeries,
      });
    }

    if (
      user_group_id !== USER_GROUPS.CLIENT_HIRING_MANAGER ||
      user_group_id !== USER_GROUPS.CLIENT_HR_PROCUREMENT
    ) {
      randomSeries = Array.from(
        { length: 12 },
        () => Math.floor(Math.random() * 100) + 1,
      );

      widgetData.push({
        id: 3,
        title: 'Clients',
        totalNumber: client?.total_client || 0,
        series: randomSeries,
      });
    }

    setWidgetsData(widgetData);
  };

  return (
    <React.Fragment>
      <Row>
        {(widgetsData || []).map((widget, key) => (
          <Col xl={4} md={6} key={key}>
            <Card className="card-h-100">
              <CardBody>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <span className="text-muted mb-3 lh-1 d-block text-truncate">
                      {widget.title}
                    </span>
                    <h4 className="mb-3">
                      <span className="counter-value">
                        <CountUp
                          start={0}
                          end={widget.totalNumber}
                          duration={2}
                          // decimals={2}
                          separator=""
                        />
                        {widget.postFix}
                      </span>
                    </h4>
                  </Col>
                  <Col xs={6}>
                    <ReactApexChart
                      options={options}
                      series={[{ data: [...widget['series']] }]}
                      type="line"
                      className="apex-charts mb-2"
                      dir="ltr"
                      height={50}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  dashboard,
});

export default connect(mapStateToProps, { actionCreator })(ApexChart);
