import { emsApi } from '..';
import { REPORTS_SERVICE } from '../constants';

class Reports {
  async fnGetReportsTypes(payload) {
    return await emsApi.post(REPORTS_SERVICE.GET_REPORTS_TYPES, payload);
  }
}
Reports.api = new Reports();
export default Reports;
