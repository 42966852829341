import { emsApi } from "..";
import { AUDIT_TRAIL_SERVICE } from "../constants";

class AuditTrail {
  async fnGetAuditTrailList(payload) {
    return await emsApi.post(AUDIT_TRAIL_SERVICE.GET_AUDIT_TRAIL_LIST, payload);
  }

  async fnAddNewAuditTrail(values) {
    return await emsApi.post(AUDIT_TRAIL_SERVICE.ADD_NEW_AUDIT_TRAIL, values);
  }

  async fnGetAuditTrailTypes(payload) {
    return await emsApi.get(AUDIT_TRAIL_SERVICE.GET_AUDIT_TRAIL_TYPES, payload);
  }
}
AuditTrail.api = new AuditTrail();
export default AuditTrail;
