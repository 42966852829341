import {
  GET_AUDIT_TRAIL_SUCCESS,
  GET_AUDIT_TRAIL_FAILED,
  ADD_NEW_AUDIT_TRAIL_SUCCESS,
  ADD_NEW_AUDIT_TRAIL_FAILED,
  GET_AUDIT_TRAIL_TYPES_SUCCESS,
  GET_AUDIT_TRAIL_TYPES_FAILED,
} from './types';

const INIT_STATE = {
  loading: true,
  data: [],
  dataTypes: [],
  error: false,
  errorMessage: null,
};

const audittrail = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUDIT_TRAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_AUDIT_TRAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case ADD_NEW_AUDIT_TRAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case ADD_NEW_AUDIT_TRAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case GET_AUDIT_TRAIL_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTypes: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_AUDIT_TRAIL_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        dataTypes: [],
        error: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default audittrail;
