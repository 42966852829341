import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { actionCreator, types } from '../../store';
import TableContainer from '../../components/Common/TableContainer';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { Name, Navigate } from './column';
import { ToastContainer, toast } from 'react-toastify';

const Reports = ({ reports, ...props }) => {
  const [reportType, setReportType] = React.useState('');

  const getReportType = async () => {
    await props.actionCreator({
      type: types.GET_REPORTS_TYPES,
      payload: {
        types: reportType,
      },
    });
  };

  const handleDropdownChange = (event) => {
    setReportType(event.target.value);
  };

  const handleExportClick = () => {
    getReportType();
  };

  // Meta title
  document.title = 'Reports';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Reports"
          />
          <Row className="mb-3">
            <Col lg="3">
              <Input
                type="select"
                className="form-select"
                value={reportType}
                onChange={handleDropdownChange}
                multiple={false}
              >
                <option value="">Select</option>
                <option value="clients">Clients</option>
                <option value="workers">Workers</option>
                <option value="suppliers">Suppliers</option>
                <option value="monthly">Monthly Operations</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                type="button"
                className="btn btn-primary save-user"
                onClick={handleExportClick} // Use onClick (not onclick)
              >
                Export
              </button>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ reports }) => ({
  reports,
});

export default connect(mapStateToProps, { actionCreator })(Reports);
