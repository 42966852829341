import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardTitle,
  CardBody,
} from 'reactstrap';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { actionCreator, types } from '../../../../store';
import Breadcrumb from '../../../../components/Common/Breadcrumb';
import { defaultCandidateStatusData } from './data';
import './style.css';
import { USER_GROUPS } from '../../../../constants/app';

// components
import ScheduleInterview from './ScheduleInterview';
import ResumePreview from './ResumePreview';
import InterviewSchedules from './InterviewSchedules';
import StatusTrail from './StatusTrail';
import StatusActions from './StatusActions';
import OfferCandidate from './OfferCandidate';

const CandidatePreview = ({ joborder, authentication, ...props }) => {
  const { user_id, user_group_id } = authentication.data.data;

  let navigate = useNavigate();
  const { id } = useParams();
  const [defaultCandidateStatus, setDefaultCandidateStatus] = React.useState(
    [],
  );
  const [candidateData, setCandidateData] = React.useState([]);
  const [scheduleInterviewModal, setScheduleInterviewModal] =
    React.useState(null);
  const [offerCandidateModal, setOfferCandidateModal] = React.useState(null);
  const [filterClientContacts, setFilterClientContacts] = React.useState([]);
  const [proposeInterviewTime, setProposeInterviewTime] = React.useState([
    {
      id: 1,
      date: moment().format('YYYY-MM-DD'),
      startTime: '',
      duration: '',
    },
  ]);
  const [openIndex, setOpenIndex] = React.useState(0);
  const [isInterviewAccepted, setIsInterviewAccepted] = React.useState(false);
  const [isNoShow, setIsNoShow] = React.useState(false);
  const [isRescheduled, setIsRescheduled] = React.useState(null);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      jo_candidate_id: (id && parseInt(id)) || null,
      interviewers: [],
      interview_schedules: proposeInterviewTime,
      subject: '',
      details: '',
    },
    validationSchema: Yup.object({
      interviewers: Yup.array()
        .of(Yup.string().required('An interviewer is required'))
        .min(1, 'Please select at least one Interviewer.'),
      subject: Yup.string().required('Please enter Subject.'),
      details: Yup.string().required('Please enter Other Details.'),
      interview_schedules: Yup.array(
        Yup.object({
          date: Yup.string().required('Date is required'),
          startTime: Yup.string().required('Start Time is required'),
          duration: Yup.string().required('Duration is required'),
        }),
      ),
    }),
    onSubmit: async (values) => {
      let newInterviewSchedule = {
        jo_candidate_id: values['jo_candidate_id'],
        interviewers: values['interviewers'],
        interview_schedules: values['interview_schedules'],
        subject: values['subject'],
        details: values['details'],
      };

      if (isRescheduled) {
        await props.actionCreator({
          type: types.POST_UPDATE_CANDIDATE_INTERVIEW,
          payload: {
            interview_schedule_id: isRescheduled,
            jo_candidate_id: parseInt(id),
            is_rescheduled: true,
          },
        });
      }

      // save new candidate interview
      props.actionCreator({
        type: types.POST_ADD_CANDIDATE_INTERVIEW,
        payload: newInterviewSchedule,
      });

      validation.resetForm();
      toggleScheduleInterviewModal();
      setProposeInterviewTime([
        {
          id: 1,
          date: moment().format('YYYY-MM-DD'),
          startTime: '',
          duration: '',
        },
      ]);
      setIsRescheduled(null);
    },
  });

  React.useEffect(() => {
    if (candidateData.length > 0) {
      candidateData[0].client_contacts && autoCheckInterviewer();

      setFilterClientContacts(candidateData[0].client_contacts);
      candidateData[0]?.interview_schedules &&
        interviewAcceptedHandler(candidateData[0].interview_schedules);

      // if status = offer or accept close all collapse
      if ([6, 7, 8, 11].includes(candidateData[0].status_id)) {
        setOpenIndex(null);
      }
    }

    return () => {
      // reset state once leave the page
      setIsInterviewAccepted(false);
      setIsNoShow(false);
      setOpenIndex(0);
    };
  }, [candidateData]);

  React.useEffect(() => {
    if (joborder.newConvertedWorkerID) {
      Swal.fire({
        title: 'The candidate has been converted into a worker.',
        text: 'Do you want to complete its details?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#7066e0',
        cancelButtonColor: '#dd3332',
      }).then(async (result) => {
        const newConvertedWorkerID = joborder.newConvertedWorkerID;
        await props.actionCreator({
          type: types.CLEAR_NEW_WORKER_ID,
        });
        if (result.isConfirmed) {
          navigate(`/workers/edit/${newConvertedWorkerID}`);
        }
      });
    }
  }, [joborder.newConvertedWorkerID]);

  React.useEffect(() => {
    setDefaultCandidateStatus(defaultCandidateStatusData);
    setCandidateData(joborder.selectedCandidateData);

    if (joborder.messages) {
      toast(joborder.messages, {
        position: 'top-right',
        hideProgressBar: true,
        className: 'bg-success text-white',
      });
      clearMessages();
    }
  }, [joborder]);

  React.useEffect(() => {
    fetchCandidateData();
    return () => {
      // reset state once leave the page
      setCandidateData([]);
    };
  }, []);

  const interviewAcceptedHandler = (interview_schedules) => {
    for (let schedule of interview_schedules[0].schedules) {
      if (schedule.accepted_by_candidate) {
        setIsInterviewAccepted(true);
      }
      if (schedule.mark_no_show) {
        setIsNoShow(true);
      }
    }
  };

  const fetchCandidateData = async () => {
    await props.actionCreator({
      type: types.GET_CANDIDATE,
      payload: {
        id,
      },
    });
  };

  const candidateStatusHandler = async (status) => {
    if (status === 2) {
      // shortlisted
      await props.actionCreator({
        type: types.POST_UPDATE_CANDIDATE_STATUS,
        payload: {
          status_id: status,
          joCandidateId: parseInt(id),
        },
      });
    } else if (status === 3) {
      // schedule for interview
      toggleScheduleInterviewModal();
    } else if (status === 6) {
      // offer
      toggleOfferModal();
    } else if (status === 7) {
      // Accept
      await props.actionCreator({
        type: types.POST_ACCEPT_CANDIDATE,
        payload: {
          status_id: status,
          jo_candidate_id: parseInt(id),
        },
      });
    } else if (status === 8) {
      // Reject
      await props.actionCreator({
        type: types.POST_UPDATE_CANDIDATE_STATUS,
        payload: {
          status_id: status,
          joCandidateId: parseInt(id),
        },
      });
    } else if (status === 11) {
      // Convert as Worker
      let timerInterval;
      Swal.fire({
        title: 'Converting into Worker',
        html: 'Estimate time: <b></b> milliseconds.',
        timer: 3000, // estimate time for process
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: async () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector('b');
          timerInterval = setInterval(() => {
            timer.textContent = `${Swal.getTimerLeft()}`;
          }, 100);

          await props.actionCreator({
            type: types.POST_CONVERT_WORKER,
            payload: {
              status_id: status,
              jo_candidate_id: parseInt(id),
            },
          });
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        // if (result.dismiss === Swal.DismissReason.timer) {
        //   Swal.fire({
        //     title: "The candidate has been converted into a worker.",
        //     text: "Do you want to complete its details?",
        //     icon: "success",
        //     showCancelButton: true,
        //     confirmButtonColor: "#7066e0",
        //     cancelButtonColor: "#dd3332",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       navigate(`/workers/edit/${joborder.newConvertedWorkerID}`);
        //     }
        //   });
        // }
      });
    }
  };

  const updateInterviewScheduleHandler = async (type, master_id) => {
    if (type === 'reject schedule') {
      await props.actionCreator({
        type: types.POST_UPDATE_CANDIDATE_INTERVIEW,
        payload: {
          interview_schedule_id: master_id,
          jo_candidate_id: parseInt(id),
          is_rejected_schedule: true,
        },
      });
    }

    if (type === 'reschedule') {
      setIsRescheduled(master_id);
      // schedule for interview
      toggleScheduleInterviewModal();
    }

    if (type === 'accept by candidate') {
      await props.actionCreator({
        type: types.POST_UPDATE_CANDIDATE_INTERVIEW,
        payload: {
          propose_schedule_id: master_id,
          jo_candidate_id: parseInt(id),
          accepted_by_candidate: true,
        },
      });
    }

    if (type === 'mark no show') {
      await props.actionCreator({
        type: types.POST_UPDATE_CANDIDATE_INTERVIEW,
        payload: {
          propose_schedule_id: master_id,
          jo_candidate_id: parseInt(id),
          mark_no_show: true,
        },
      });
    }
  };

  const autoCheckInterviewer = () => {
    const { interviewers } = validation.values;

    const contacts = candidateData[0].client_contacts.find(
      (item) => item.client_contact_id === user_id,
    );

    if (contacts) {
      validation.setFieldValue('interviewers', [
        ...interviewers,
        parseInt(contacts.client_contact_id),
      ]);
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const { interviewers } = validation.values;

    if (checked) {
      validation.setFieldValue('interviewers', [
        ...interviewers,
        parseInt(value),
      ]);
    } else {
      validation.setFieldValue(
        'interviewers',
        interviewers.filter((v) => v !== parseInt(value)),
      );
    }
  };

  const handleSearchInterviewer = (e) => {
    const searchValue = e.target.value.toLowerCase();

    if (searchValue) {
      const filteredClientContacts = candidateData[0].client_contacts.filter(
        (item) => item.client_contact_name.toLowerCase().includes(searchValue),
      );
      setFilterClientContacts(filteredClientContacts);
    } else {
      setFilterClientContacts(candidateData[0].client_contacts || []);
    }
  };

  const setProposeTimeHandler = (index, date, startTime, duration) => {
    if (date) {
      validation.setFieldValue(`interview_schedules.${index}.date`, date || '');
    }

    if (startTime) {
      validation.setFieldValue(
        `interview_schedules.${index}.startTime`,
        startTime || '',
      );
    }

    if (duration) {
      validation.setFieldValue(
        `interview_schedules.${index}.duration`,
        duration || '',
      );
    }
  };

  const removeSelectedProposeTimeHandler = (index) => {
    if (proposeInterviewTime.length > 1) {
      setProposeInterviewTime((prevProposeTime) =>
        prevProposeTime.filter((_, i) => i !== index),
      );
    }
  };

  const addMoreProposeTimeHandler = () => {
    const newID = validation.values.interview_schedules.length + 1;

    setProposeInterviewTime([
      ...validation.values.interview_schedules,
      {
        id: newID,
        date: '',
        startTime: '',
        duration: '',
      },
    ]);
  };

  const toggleCollapse = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const toggleScheduleInterviewModal = () => {
    setScheduleInterviewModal(!scheduleInterviewModal);
  };

  const toggleOfferModal = () => {
    setOfferCandidateModal(!offerCandidateModal);
  };

  const clearMessages = async () => {
    await props.actionCreator({
      type: types.CLEAR_JO_MESSAGES,
    });
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  //meta title
  document.title = `Candidate | ${candidateData.length > 0 && candidateData[0].candidate_name}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row
            style={{
              marginBottom: 15,
            }}
          >
            <Col>
              {joborder.selectedJOBData.id !== undefined ? (
                <Link
                  to={`/job-order/cv-submission/${joborder.selectedJOBData.id}`}
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Back to Candidates
                </Link>
              ) : (
                <Link
                  onClick={handleGoBack}
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Back to Candidates
                </Link>
              )}
            </Col>
          </Row>
          <Breadcrumb
            title={`Candidates`}
            breadcrumbItem={
              candidateData.length > 0 && candidateData[0].candidate_name
            }
          />
          {!joborder.loading && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <StatusActions
                        candidateData={candidateData}
                        candidateStatusHandler={candidateStatusHandler}
                        isSupplierOps={
                          user_group_id === USER_GROUPS.SUPPLIER_OPS
                        }
                        isClientHiringManager={
                          user_group_id === USER_GROUPS.CLIENT_HIRING_MANAGER
                        }
                        isPMOSupport={user_group_id === USER_GROUPS.PMO_SUPPORT}
                      />
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <StatusTrail
                      candidateData={candidateData}
                      defaultCandidateStatus={defaultCandidateStatus}
                    />

                    <InterviewSchedules
                      candidateData={candidateData}
                      isNoShow={isNoShow}
                      isInterviewAccepted={isInterviewAccepted}
                      openIndex={openIndex}
                      toggleCollapse={toggleCollapse}
                      updateInterviewScheduleHandler={
                        updateInterviewScheduleHandler
                      }
                      isClientHiringManager={
                        user_group_id === USER_GROUPS.CLIENT_HIRING_MANAGER
                      }
                      isSupplierOps={user_group_id === USER_GROUPS.SUPPLIER_OPS}
                    />

                    <ResumePreview candidateData={candidateData} />
                  </CardBody>

                  <ScheduleInterview
                    addMoreProposeTimeHandler={addMoreProposeTimeHandler}
                    candidateData={candidateData}
                    filterClientContacts={filterClientContacts}
                    handleSearchInterviewer={handleSearchInterviewer}
                    handleCheckboxChange={handleCheckboxChange}
                    proposeInterviewTime={proposeInterviewTime}
                    removeSelectedProposeTimeHandler={
                      removeSelectedProposeTimeHandler
                    }
                    scheduleInterviewModal={scheduleInterviewModal}
                    setProposeTimeHandler={setProposeTimeHandler}
                    toggleScheduleInterviewModal={toggleScheduleInterviewModal}
                    validation={validation}
                  />

                  <OfferCandidate
                    candidateData={candidateData}
                    jo_candidate_id={id}
                    offerCandidateModal={offerCandidateModal}
                    toggleOfferModal={toggleOfferModal}
                  />
                </Card>
              </Col>
            </Row>
          )}

          {joborder.loading && <div>Loading...</div>}
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ joborder, authentication }) => ({
  joborder,
  authentication,
});

export default connect(mapStateToProps, { actionCreator })(CandidatePreview);
