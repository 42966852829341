import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_AUDIT_TRAIL,
  GET_AUDIT_TRAIL_SUCCESS,
  GET_AUDIT_TRAIL_FAILED,
  ADD_NEW_AUDIT_TRAIL,
  ADD_NEW_AUDIT_TRAIL_SUCCESS,
  ADD_NEW_AUDIT_TRAIL_FAILED,
  GET_AUDIT_TRAIL_TYPES,
  GET_AUDIT_TRAIL_TYPES_SUCCESS,
  GET_AUDIT_TRAIL_TYPES_FAILED,
} from "./types";

import audittrailServices from "../../api/services/audit-trail";

function* fnGetAuditTrailList({ payload }) {
  try {
    const data = yield call(
      audittrailServices.api.fnGetAuditTrailList,
      payload
    );
    yield put({
      type: GET_AUDIT_TRAIL_SUCCESS,
      payload: data.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_AUDIT_TRAIL_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnAddNewAuditTrail({ payload }) {
  try {
    yield call(audittrailServices.api.fnAddNewAuditTrail, payload);

    yield put({
      type: ADD_NEW_AUDIT_TRAIL_SUCCESS,
    });
    yield put({
      type: GET_AUDIT_TRAIL,
    });
  } catch (error) {
    yield put({
      type: ADD_NEW_AUDIT_TRAIL_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetAuditTrailTypes({ payload }) {
  try {
    const data = yield call(
      audittrailServices.api.fnGetAuditTrailTypes,
      payload
    );
    yield put({
      type: GET_AUDIT_TRAIL_TYPES_SUCCESS,
      payload: data.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_AUDIT_TRAIL_TYPES_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_AUDIT_TRAIL, fnGetAuditTrailList);
  yield takeLatest(ADD_NEW_AUDIT_TRAIL, fnAddNewAuditTrail);
  yield takeLatest(GET_AUDIT_TRAIL_TYPES, fnGetAuditTrailTypes);
}
