import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actionCreator, types } from "../../store";
import addLogo from "../../assets/images/add.png";

import {
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { useFormik } from "formik";

const Configuration = ({ clients, clientfields, configuration, ...props }) => {
  const [operationFields, setOperationFields] = React.useState({});
  const [selectedClient, setSelectedClient] = React.useState();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      client: selectedClient || "", //selected client
      department: operationFields?.department,
      cost_center: operationFields?.cost_center,
      currency: operationFields?.currency,
      cost_tracking: operationFields?.cost_tracking,
    },
    validationSchema: Yup.object({
      client: Yup.string().required("Client is required"),
      department: Yup.array(
        Yup.object({
          item: Yup.string().required("Item is required"),
        })
      ),
      cost_center: Yup.array(
        Yup.object({
          item: Yup.string().required("Item is required"),
        })
      ),
      currency: Yup.array(
        Yup.object({
          item: Yup.string().required("Item is required"),
        })
      ),
      cost_tracking: Yup.array(
        Yup.object({
          item: Yup.string().required("Item is required"),
        })
      ),
    }),
    onSubmit: (values, { setSubmitting }, event) => {
      if (values.department.length == 0) {
        toast(
          "Cannot save. Please add an item for Department, Cost Center, Currency and Department",
          {
            position: "top-right",
            hideProgressBar: true,
            className: "bg-danger text-white",
          }
        );
      } else {
        props.actionCreator({
          type: types.SAVE_CONFIGURATION,
          payload: {
            client_id: selectedClient,
            values,
          },
        });
      }
    },
  });

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_CLIENT_LIST_ALL,
    });
  }, []);

  const addDepartmentFields = () => {
    setOperationFields({
      department: [
        ...validation.values.department,
        {
          id: "",
          item: "",
        },
      ],
      cost_center: [...validation.values.cost_center],
      currency: [...validation.values.currency],
      cost_tracking: [...validation.values.cost_tracking],
    });
  };

  const addCostCenterFields = () => {
    setOperationFields({
      cost_center: [
        ...validation.values.cost_center,
        {
          id: "",
          item: "",
        },
      ],
      department: [...validation.values.department],
      currency: [...validation.values.currency],
      cost_tracking: [...validation.values.cost_tracking],
    });
  };

  const addCurrencyFields = () => {
    setOperationFields({
      currency: [
        ...validation.values.currency,
        {
          id: "",
          item: "",
        },
      ],
      department: [...validation.values.department],
      cost_center: [...validation.values.cost_center],
      cost_tracking: [...validation.values.cost_tracking],
    });
  };

  const addCostTrackingFields = () => {
    setOperationFields({
      cost_tracking: [
        ...validation.values.cost_tracking,
        {
          id: "",
          item: "",
        },
      ],
      department: [...validation.values.department],
      currency: [...validation.values.currency],
      cost_center: [...validation.values.cost_center],
    });
  };

  const handleNotification = async () => {
    toast(configuration.messages, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });
  };

  const handleErrorNotification = async () => {
    toast(configuration.errorMessage, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
    });
  };

  React.useEffect(() => {
    configuration.isSubmitted && handleNotification();
    configuration.error && configuration.isSubmitted === false &&
      configuration.errorMessage &&
      handleErrorNotification();
  }, [configuration]);

  React.useEffect(() => {
    setOperationFields({
      department: clientfields.department.data.map((item, index) => ({
        id: item.id,
        item: item.label,
      })),
      cost_center: clientfields.costCenter.data.map((item, index) => ({
        id: item.id,
        item: item.label,
      })),
      currency: clientfields.currency.data.map((item, index) => ({
        id: item.id,
        item: item.label,
      })),
      cost_tracking: clientfields.costTracking.data.map((item, index) => ({
        id: item.id,
        item: item.label,
      })),
    });
  }, [clientfields]);

  const selectClientHandler = async (id) => {
    setSelectedClient(id);
    await props.actionCreator({
      type: types.GET_DEPARTMENT_OPTIONS,
      payload: {
        type: "department",
        client_id: id,
      },
    });

    await props.actionCreator({
      type: types.GET_COST_CENTER_OPTIONS,
      payload: {
        type: "cost center",
        client_id: id,
      },
    });

    await props.actionCreator({
      type: types.GET_CURRENCY_OPTIONS,
      payload: {
        type: "currency",
        client_id: id,
      },
    });

    await props.actionCreator({
      type: types.GET_COST_TRACKING_OPTIONS,
      payload: {
        type: "cost tracking",
        client_id: id,
      },
    });
  };

  return (
    <>
      <Card>
        <CardHeader style={{ padding: "15px 15px 7px" }}>
          <Row>
            <Col md={12}>
              <div className="">
                <h5 className="card-title">Configuration</h5>
                <p className="card-title-desc">
                  Configuration contains system-wide settings and customization
                  options.
                </p>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-4">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="mb-4">
              <Col xs={4}>
                <Label className="form-label">Client</Label>
                <Input
                  type="select"
                  name="client"
                  className="form-select"
                  multiple={false}
                  onChange={(e) => {
                    validation.handleChange(e);
                    selectClientHandler(e.target.value);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.client || ""}
                  invalid={
                    validation.touched.client && validation.errors.client
                      ? true
                      : false
                  }
                >
                  <option value={""}>Select Client</option>
                  {clients &&
                    clients?.listData
                      // .filter((list) => list.status === 1)
                      .map((item, index) => (
                        <option key={index} value={item.client_id}>
                          {item.client_name}
                        </option>
                      ))}
                </Input>
                {validation.touched.client && validation.errors.client ? (
                  <FormFeedback type="invalid">
                    {validation.errors.client}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs={4}></Col>
              <Col xs={4}></Col>
            </Row>
            <Row>
              <Col xs={4}>
                <Card>
                  <CardHeader style={{ padding: "15px 15px 7px" }}>
                    <Row>
                      <Col md={6}>
                        <div className="">
                          <h5 className="card-title">Department</h5>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
                          <div>
                            <Link to="#" onClick={addDepartmentFields}>
                              <img
                                src={addLogo}
                                alt="Add"
                                style={{ height: "40px" }}
                              />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Row>
                      <Col xs={12}>
                        {operationFields?.department &&
                          operationFields?.department.length > 0 && (
                            <Row>
                              <Col xs={12}>
                                <Label className="form-label">Item</Label>
                              </Col>
                            </Row>
                          )}
                        {operationFields?.department &&
                          operationFields?.department.map((field, index1) => (
                            <Row key={index1} className="mb-2">
                              <Col xs={10}>
                                <Input
                                  type="text"
                                  name={`department[${index1}].item`}
                                  value={
                                    (validation?.values?.department &&
                                      validation?.values?.department[index1]
                                        ?.item) ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    validation.handleChange(e);
                                  }}
                                  invalid={
                                    validation.touched.department &&
                                    validation.touched.department[index1] &&
                                    validation.errors.department &&
                                    validation.errors.department[index1] &&
                                    validation.errors.department[index1].item
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.department &&
                                validation.errors.department[`${index1}`] &&
                                validation.errors.department[`${index1}`]
                                  .item ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validation.errors.department[`${index1}`]
                                        .item
                                    }
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col xs={2}>
                                <Link
                                  className="text-danger"
                                  to="#"
                                  onClick={() => {
                                    const newFields = [
                                      ...validation.values.department,
                                    ];
                                    newFields.splice(index1, 1);
                                    setOperationFields({
                                      department: newFields,
                                      cost_center: [
                                        ...validation.values.cost_center,
                                      ],
                                      currency: [...validation.values.currency],
                                      cost_tracking: [
                                        ...validation.values.cost_tracking,
                                      ],
                                    });
                                  }}
                                >
                                  <i
                                    className="mdi mdi-delete font-size-24"
                                    id="deletetooltip"
                                  ></i>
                                </Link>
                              </Col>
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={4}>
                <Card>
                  <CardHeader style={{ padding: "15px 15px 7px" }}>
                    <Row>
                      <Col md={6}>
                        <div className="">
                          <h5 className="card-title">Cost Center</h5>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
                          <div>
                            <Link to="#" onClick={addCostCenterFields}>
                              <img
                                src={addLogo}
                                alt="Add"
                                style={{ height: "40px" }}
                              />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Row>
                      <Col xs={12}>
                        {operationFields?.cost_center &&
                          operationFields?.cost_center.length > 0 && (
                            <Row>
                              <Col xs={12}>
                                <Label className="form-label">Item</Label>
                              </Col>
                            </Row>
                          )}
                        {operationFields?.cost_center &&
                          operationFields?.cost_center.map((field, index1) => (
                            <Row key={index1} className="mb-2">
                              <Col xs={10}>
                                <Input
                                  type="text"
                                  name={`cost_center[${index1}].item`}
                                  value={
                                    (validation?.values?.cost_center &&
                                      validation?.values?.cost_center[index1]
                                        ?.item) ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    validation.handleChange(e);
                                  }}
                                  invalid={
                                    validation.touched.cost_center &&
                                    validation.touched.cost_center[index1] &&
                                    validation.errors.cost_center &&
                                    validation.errors.cost_center[index1] &&
                                    validation.errors.cost_center[index1].item
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.cost_center &&
                                validation.errors.cost_center[`${index1}`] &&
                                validation.errors.cost_center[`${index1}`]
                                  .item ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validation.errors.cost_center[`${index1}`]
                                        .item
                                    }
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col xs={2}>
                                <Link
                                  className="text-danger"
                                  to="#"
                                  onClick={() => {
                                    const newFields = [
                                      ...validation.values.cost_center,
                                    ];
                                    newFields.splice(index1, 1);
                                    setOperationFields({
                                      cost_center: newFields,
                                      department: [
                                        ...validation.values.department,
                                      ],
                                      currency: [...validation.values.currency],
                                      cost_tracking: [
                                        ...validation.values.cost_tracking,
                                      ],
                                    });
                                  }}
                                >
                                  <i
                                    className="mdi mdi-delete font-size-24"
                                    id="deletetooltip"
                                  ></i>
                                </Link>
                              </Col>
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={4}>
                <Card>
                  <CardHeader style={{ padding: "15px 15px 7px" }}>
                    <Row>
                      <Col md={6}>
                        <div className="">
                          <h5 className="card-title">Currency</h5>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
                          <div>
                            <Link to="#" onClick={addCurrencyFields}>
                              <img
                                src={addLogo}
                                alt="Add"
                                style={{ height: "40px" }}
                              />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Row>
                      <Col xs={12}>
                        {operationFields?.currency &&
                          operationFields?.currency.length > 0 && (
                            <Row>
                              <Col xs={12}>
                                <Label className="form-label">Item</Label>
                              </Col>
                            </Row>
                          )}
                        {operationFields?.currency &&
                          operationFields?.currency.map((field, index1) => (
                            <Row key={index1} className="mb-2">
                              <Col xs={10}>
                                <Input
                                  type="text"
                                  name={`currency[${index1}].item`}
                                  value={
                                    (validation?.values?.currency &&
                                      validation?.values?.currency[index1]
                                        ?.item) ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    validation.handleChange(e);
                                  }}
                                  invalid={
                                    validation.touched.currency &&
                                    validation.touched.currency[index1] &&
                                    validation.errors.currency &&
                                    validation.errors.currency[index1] &&
                                    validation.errors.currency[index1].item
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.currency &&
                                validation.errors.currency[`${index1}`] &&
                                validation.errors.currency[`${index1}`].item ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validation.errors.currency[`${index1}`]
                                        .item
                                    }
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col xs={2}>
                                <Link
                                  className="text-danger"
                                  to="#"
                                  onClick={() => {
                                    const newFields = [
                                      ...validation.values.currency,
                                    ];
                                    newFields.splice(index1, 1);
                                    setOperationFields({
                                      currency: newFields,
                                      department: [
                                        ...validation.values.department,
                                      ],
                                      cost_center: [
                                        ...validation.values.cost_center,
                                      ],
                                      cost_tracking: [
                                        ...validation.values.cost_tracking,
                                      ],
                                    });
                                  }}
                                >
                                  <i
                                    className="mdi mdi-delete font-size-24"
                                    id="deletetooltip"
                                  ></i>
                                </Link>
                              </Col>
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <Card>
                  <CardHeader style={{ padding: "15px 15px 7px" }}>
                    <Row>
                      <Col md={6}>
                        <div className="">
                          <h5 className="card-title">Cost Tracking</h5>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
                          <div>
                            <Link to="#" onClick={addCostTrackingFields}>
                              <img
                                src={addLogo}
                                alt="Add"
                                style={{ height: "40px" }}
                              />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Row>
                      <Col xs={12}>
                        {operationFields?.cost_tracking &&
                          operationFields?.cost_tracking.length > 0 && (
                            <Row>
                              <Col xs={12}>
                                <Label className="form-label">Item</Label>
                              </Col>
                            </Row>
                          )}
                        {operationFields?.cost_tracking &&
                          operationFields?.cost_tracking.map(
                            (field, index1) => (
                              <Row key={index1} className="mb-2">
                                <Col xs={10}>
                                  <Input
                                    type="text"
                                    name={`cost_tracking[${index1}].item`}
                                    value={
                                      (validation?.values?.cost_tracking &&
                                        validation?.values?.cost_tracking[
                                          index1
                                        ]?.item) ||
                                      ""
                                    }
                                    onChange={(e) => {
                                      validation.handleChange(e);
                                    }}
                                    invalid={
                                      validation.touched.cost_tracking &&
                                      validation.touched.cost_tracking[
                                        index1
                                      ] &&
                                      validation.errors.cost_tracking &&
                                      validation.errors.cost_tracking[index1] &&
                                      validation.errors.cost_tracking[index1]
                                        .item
                                        ? true
                                        : false
                                    }
                                  />

                                  {validation.errors.cost_tracking &&
                                  validation.errors.cost_tracking[
                                    `${index1}`
                                  ] &&
                                  validation.errors.cost_tracking[`${index1}`]
                                    .item ? (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors.cost_tracking[
                                          `${index1}`
                                        ].item
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                                <Col xs={2}>
                                  <Link
                                    className="text-danger"
                                    to="#"
                                    onClick={() => {
                                      const newFields = [
                                        ...validation.values.cost_tracking,
                                      ];
                                      newFields.splice(index1, 1);
                                      setOperationFields({
                                        cost_tracking: newFields,
                                        department: [
                                          ...validation.values.department,
                                        ],
                                        cost_center: [
                                          ...validation.values.cost_center,
                                        ],
                                        currency: [
                                          ...validation.values.currency,
                                        ],
                                      });
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-delete font-size-24"
                                      id="deletetooltip"
                                    ></i>
                                  </Link>
                                </Col>
                              </Row>
                            )
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={4}></Col>
              <Col xs={4}></Col>
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-info text-white"
                    style={{
                      backgroundColor: "#5156BE",
                      width: 175,
                    }}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = ({ clients, clientfields, configuration }) => ({
  clients,
  clientfields,
  configuration,
});
export default connect(mapStateToProps, { actionCreator })(Configuration);
