import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  TabPane,
  CardHeader,
  Card,
  CardTitle,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  CardText,
} from 'reactstrap';
import classnames from 'classnames';
import { actionCreator, types } from '../../../store';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import bxFile from '../../../assets/images/bx-file.png';
// components
import JobOrderEdit from '../Edit';
import JobOrderCandidates from '../Candidate';

import { USER_GROUPS } from '../../../constants/app';

const JobOrderOverview = ({ joborder, authentication, ...props }) => {
  const { user_rules, user_group_id } = authentication.data.data;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const location = useLocation();
  const [screenData, setScreenData] = React.useState({
    currentUrl: '',
    breadCrumb: '',
    jobOrderID: parseInt(id),
  });
  const [jobOrderDetails, setJobOrderDetails] = React.useState(null);
  const [customActiveTab, setcustomActiveTab] = React.useState('1');
  const [candidateCount, setCandidateCount] = React.useState(null);

  React.useEffect(() => {
    if (joborder.messages) {
      toast(joborder.messages, {
        position: 'top-right',
        hideProgressBar: true,
        className: 'bg-success text-white',
      });
      clearMessages();
    }
  }, [joborder]);

  React.useEffect(() => {
    customTabHandler();
  }, [screenData]);

  React.useEffect(() => {
    screenDataHandler();
    !joborder.data.length && fetchJobOrder();
  }, []);

  React.useEffect(() => {
    joborder.data.length > 0 && jobOrderDetailsHandler();
  }, [joborder.data]);

  const jobOrderDetailsHandler = async () => {
    const jobOrderData = joborder.data.find(
      (item) => item.id === screenData.jobOrderID,
    );
    setJobOrderDetails(jobOrderData);

    await props.actionCreator({
      type: types.SAVE_SELECTED_JOB_DATA,
      payload: jobOrderData,
    });
  };

  const screenDataHandler = () => {
    const currentUrl = location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/'),
    );
    const breadCrumb =
      currentUrl === '/job-order/overview' ? 'Overview' : 'CV Submission';

    setScreenData((prevData) => {
      const newData = {
        ...prevData,
        currentUrl,
        breadCrumb,
      };

      return newData;
    });
  };

  const customTabHandler = () => {
    if (screenData.currentUrl === '/job-order/overview') {
      setcustomActiveTab('1');
    }

    if (screenData.currentUrl === '/job-order/cv-submission') {
      setcustomActiveTab('3');
    }
  };

  const fetchJobOrder = async () => {
    await props.actionCreator({
      type: types.GET_JOB_ORDER_LIST,
      payload: {
        joborder_id: screenData.jobOrderID,
      },
    });
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const clearMessages = async () => {
    await props.actionCreator({
      type: types.CLEAR_JO_MESSAGES,
    });
  };

  //meta title
  document.title = `Job Order | ${screenData.breadCrumb}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {jobOrderDetails && (
            <Breadcrumb
              title={`Job Orders`}
              titleLink="/job-order"
              breadcrumbItem={`${jobOrderDetails.id} | ${jobOrderDetails.job_title}`}
              addedStyle={{
                height: 20,
              }}
            />
          )}
          <Row
            style={{
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <Col>
              <Link
                to={`/job-order/`}
                style={{
                  fontWeight: 700,
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                Back to Job Listing
              </Link>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <CardTitle className="h4">ID</CardTitle>
                  {jobOrderDetails && (
                    <p className="card-title-desc">{jobOrderDetails.id}</p>
                  )}
                </CardHeader>
                <CardBody>
                  <Nav
                    tabs
                    className="nav-tabs-custom nav-justified"
                    style={{
                      width: 400,
                    }}
                  >
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customActiveTab === '1',
                        })}
                        onClick={() => {
                          toggleCustom('1');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Overview</span>
                      </NavLink>
                    </NavItem>
                    {/* {//4} */}
                    {user_group_id !== USER_GROUPS.SUPPLIER_OPS &&
                      user_group_id !== USER_GROUPS.CLIENT_HIRING_MANAGER && (
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: customActiveTab === '2',
                            })}
                            onClick={() => {
                              toggleCustom('2');
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">Edit</span>
                          </NavLink>
                        </NavItem>
                      )}
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customActiveTab === '3',
                        })}
                        onClick={() => {
                          toggleCustom('3');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">Candidates</span>
                        {candidateCount > 0 && (
                          <span
                            style={{
                              position: 'relative',
                              top: -30,
                              left: 60,
                              color: '#FFFFFF',
                              backgroundColor: '#2AB57D',
                              paddingLeft: 4,
                              paddingRight: 4,
                              paddingTop: 2,
                              paddingBottom: 2,
                              borderRadius: 4,
                            }}
                          >
                            {candidateCount}
                          </span>
                        )}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm={6} md={6} lg={6}>
                          <Card>
                            <CardHeader>
                              <CardTitle className="h4">
                                Job Description
                              </CardTitle>
                              <p className="card-title-desc">
                                Detailed description of worker role.
                              </p>
                            </CardHeader>
                            <CardBody>
                              {jobOrderDetails && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: jobOrderDetails.job_description,
                                  }}
                                />
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col sm={6} md={6} lg={6}>
                          <Card>
                            <CardHeader>
                              <CardTitle className="h4">Details</CardTitle>
                              <p className="card-title-desc">
                                Specific details about a job order.
                              </p>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col sm={3}>
                                  <label>Date Added</label>
                                </Col>
                                <Col sm={5}>
                                  <CardText className="mb-0">
                                    {jobOrderDetails &&
                                      jobOrderDetails.date_added_str}
                                  </CardText>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={3}>
                                  <label>Type of Services</label>
                                </Col>
                                <Col sm={5}>
                                  <CardText className="mb-0">
                                    {jobOrderDetails &&
                                      jobOrderDetails.type_of_services}
                                  </CardText>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={3}>
                                  <label>Client Name</label>
                                </Col>
                                <Col sm={5}>
                                  <CardText className="mb-0">
                                    {jobOrderDetails &&
                                      jobOrderDetails.client_name}
                                  </CardText>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={3}>
                                  <label>Client Contact</label>
                                </Col>
                                <Col sm={5}>
                                  <CardText className="mb-0">
                                    {(jobOrderDetails &&
                                      jobOrderDetails?.client_contact_name) ||
                                      '--'}
                                  </CardText>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={3}>
                                  <label>Client Budget</label>
                                </Col>
                                <Col sm={5}>
                                  <CardText className="mb-0">
                                    {jobOrderDetails &&
                                      jobOrderDetails.client_salary_budget}
                                  </CardText>
                                </Col>
                              </Row>
                              {/* {//4} */}
                              {(user_group_id === USER_GROUPS.PMO_SUPPORT ||
                                user_group_id === USER_GROUPS.SUPER_ADMIN) && (
                                <Row>
                                  <Col sm={3}>
                                    <label>Suppliers</label>
                                  </Col>
                                  <Col sm={5}>
                                    <CardText className="mb-0">
                                      {jobOrderDetails &&
                                        jobOrderDetails?.supplierNames &&
                                        jobOrderDetails.supplierNames.replace(
                                          /,\s*$/,
                                          '',
                                        )}
                                    </CardText>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Card>
                            <CardHeader>
                              <CardTitle className="h4">Attachments</CardTitle>
                              <p className="card-title-desc">
                                Uploaded resume.
                              </p>
                            </CardHeader>
                            <CardBody>
                              {jobOrderDetails &&
                                jobOrderDetails.documents.map((f, i) => {
                                  return (
                                    <Card
                                      style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                        width: '50%',
                                      }}
                                      key={i + '-file'}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col
                                            className="col-auto"
                                            style={{
                                              marginBottom: 10,
                                            }}
                                          >
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded"
                                              alt={f.orig_file}
                                              src={bxFile}
                                            />
                                          </Col>
                                          <Col>
                                            <a
                                              href={`${baseUrl}${f.new_file}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.orig_file}
                                            </a>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    {user_group_id !== USER_GROUPS.SUPPLIER_OPS &&
                      user_group_id !== USER_GROUPS.CLIENT_HIRING_MANAGER && (
                        <TabPane tabId="2">
                          <JobOrderEdit jobOrderDetails={jobOrderDetails} />
                        </TabPane>
                      )}
                    <TabPane tabId="3">
                      <JobOrderCandidates
                        jobOrderDetails={jobOrderDetails}
                        setCandidateCount={setCandidateCount}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ joborder, authentication }) => ({
  joborder,
  authentication,
});

export default connect(mapStateToProps, { actionCreator })(JobOrderOverview);
